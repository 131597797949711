import React from 'react'
import AdminNavbar from './AdminNavbar'

function Users() {
  return (
    <AdminNavbar>
      Users
    </AdminNavbar>
  )
}

export default Users
