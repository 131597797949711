import { createTheme } from "@material-ui/core";

export const darkTheme = createTheme({
  palette: {
    type: "dark",
    common: {
      black: "#000",
      white: "#fff",
    },
    primary: {
      main: "#4167B2",
    },
    secondary: {
      main: "#F71515",
    },
    text: {
      primary: "#fff",
      secondary: "rgba(255, 255, 255, 0.7)",
      disabled: "rgba(255, 255, 255, 0.5)",
      hint: "rgba(255, 255, 255, 0.5)",
      icon: "rgba(255, 255, 255, 0.5)",
    },
    indicator: {
      default: "#fff"
    },
    divider: "rgba(255, 255, 255, 0.12)",
    background: {
      paper: "#232323",
      default: "#232323",
      black: "#000",
      darkGrey: "#0e0e0e"
    },
    tab: {
      selected: "#ffffff",
      hover: "#919090",
      focus: "#ffffff"
    },
    toolbar: {
      default: "#212121"
    },
    filterIcon: {
      default: "#919090",
      selected: "#ffffff"
    },
    chartSearch: {
      background: "#5e5c5c"
    }
  }
});