import * as MaterialUI from "@material-ui/core";
import React, { useState } from "react";
import { MuiThemeProvider, createTheme } from "@material-ui/core";
import { getThemeByName } from "./base";
import { useMemo } from "react";

export const ThemeContext = React.createContext((themeName) => {});

const lightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#1554F6",
    },
    secondary: {
      main: "#F71515",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(255, 255, 255, 0.5)",
    },
    divider: "rgba(0, 0, 0, 0.12)",    
    background: {
      paper: "#fff",
      default: "#fafafa",
      black: "#fff",
      darkGrey: "#fff"
    },
    indicator: {
      default: "#1e1f1f"
    },
    tab: {
      selected: "#1e1f1f",
      hover: "#1f1e1e",
      focus: "#1f1e1e"
    },
    toolbar: {
      default: "#f5f5f5"
    },
    filterIcon: {
      default: "rgba(0, 0, 0, 0.2)",
      selected: "#323232"
    },
    chartSearch: {
      background: "#EFEFEF"
    }
  },
});

const darkTheme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#4167B2",
    },
    secondary: {
      main: "#F71515",
    },
    text: {
      primary: "#fff",
      secondary: "rgba(255, 255, 255, 0.7)",
      disabled: "rgba(255, 255, 255, 0.5)",
      hint: "rgba(255, 255, 255, 0.5)",
      icon: "rgba(255, 255, 255, 0.5)",
    },
    indicator: {
      default: "#fff"
    },
    divider: "rgba(255, 255, 255, 0.12)",
    background: {
      paper: "#232323",
      default: "#232323",
      black: "#000",
      darkGrey: "#0e0e0e"
    },
    tab: {
      selected: "#ffffff",
      hover: "#919090",
      focus: "#ffffff"
    },
    toolbar: {
      default: "#212121"
    },
    filterIcon: {
      default: "#919090",
      selected: "#ffffff"
    },
    chartSearch: {
      background: "#5e5c5c"
    }
  }
});

const ThemeProvider = (props) => {
  // Read current theme from localStorage or maybe from an api
  const curThemeName = localStorage.getItem("ritualAppTheme") || "lightTheme";

  // State to hold the selected theme name
  const [themeName, _setThemeName] = useState(curThemeName);

  // Retrieve the theme object by theme name
  const theme = getThemeByName(themeName);

  const setThemeName = (themeName) => {
    localStorage.setItem("ritualAppTheme", themeName);
    _setThemeName(themeName);
  }

  const themeNew = useMemo(() => ((themeName === 'darkTheme') ? darkTheme : lightTheme), [themeName]);

  return (
    <ThemeContext.Provider value={{ themeName, setThemeName }}>
      <MuiThemeProvider theme={themeNew}>
        <MaterialUI.CssBaseline />
        {props.children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
