import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  TextField,
  Button,
  Grid,
  Typography,
  CssBaseline,
  Box,
  Container,
  ThemeProvider,
} from "@material-ui/core";
import LogoSVG from "../assets/svg/logo.svg";
import moment from "moment";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import { deepOrange, deepPurple, grey } from "@material-ui/core/colors";
import { LockOutlined } from "@material-ui/icons";
import { registerIntoFirebase } from "../firebase/firebaseAuthService";
import GoogleMaps from "./GoogleLocation";
import history from "../history";
import { useFirebase } from "react-redux-firebase";
import { getLocationByPlaceId, updateUserProfile } from "services/dataService";
import useApp from "hooks/useApp";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    "& > svg": {
      marginTop: "0 !important",
    },
  },
  // purple: {
  //   color: theme.palette.getContrastText(deepPurple[500]),
  //   backgroundColor: deepPurple[500],
  // },
  grey: {
    color: theme.palette.getContrastText(grey[500]),
    backgroundColor: grey[500],
  },
}));
const theme = createTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#1554F6",
      dark: "#002884",
      contrastText: "#fff",
    },
    secondary: {
      light: "#757575",
      main: "#757575",
      dark: "#757575",
      contrastText: "#757575",
    },
  },
});

const Register = () => {
  const classes = useStyles();
  const { handleSlideMenuOpen } = useApp();

  const [formValue, setFormValue] = useState({
    address: "",
    birthDay: "",//moment().format("YYYY-MM-DDTHH:mm"),
  });
  const [selectedPlaceId, setSelectedPlaceId] = useState(null);
  const [loading, setLoading] = useState(false);
  const firebase = useFirebase();

  const handleChange = ({ target }) => {
    setFormValue({ ...formValue, [target.name]: target.value });
  };

  const handleBlur = ({ target }) => {
    setFormValue({ ...formValue, [target.name]: target.value });
  };

  const handleAutocompleteChange = (option) => {
    console.log(option);
    if (option) {
      setSelectedPlaceId(option.place_id);
    }
  };

  const handleSubmit = async (e) => {
    handleSlideMenuOpen(false);
    e.preventDefault();
    let lat,
      lng = 0;
    setLoading(true);
    try {
      const loc = await getLocationByPlaceId(selectedPlaceId);
      console.log(loc.data.results[0].geometry.location.lat);
      lat = loc.data.results[0].geometry.location.lat;
      lng = loc.data.results[0].geometry.location.lng;
    } catch (error) {
      // setLoading(false);
    }
    registerIntoFirebase(formValue)
      .then((user) => {
        const { password, ...result } = formValue;
        const newFormValue = {
          latitude: lat,
          longitude: lng,
          ...result,
        };
        const userData = {
          email: user.email,
          emailVerified: user.emailVerified,
          isAnonymous: user.isAnonymous,
          metadata: user.metadata,
          nameErr: "",
          providerData: user.providerData,
          providerId: user.providerId,
          refreshToken: user.refreshToken,
          uid: user.uid,
          birthLocation: {
            latitude: lat,
            longitude: lng,
            name: formValue.address,
          },
          userType: "normal",
        };

        updateUserProfile(newFormValue, userData).then((res) => {
          console.log(res);
          setLoading(false);
          history.push("/");
        });
      })
      .catch((err) => {
        console.log(err);
        alert(err.message);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {/* <ThemeProvider theme={theme}> */}
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "20vh",
              alignItems: "center",
            }}
          >
            <Box sx={{marginBottom : "30px"}}>
              <img alt="logo" src={LogoSVG} />
            </Box>
            {/* <Typography component="h1" variant="h5">
              Sign up
            </Typography> */}
            <div className="mb-16"></div>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 3 }}
            >
              <TextField
                className="mb-16"
                name="name"
                label="Name"
                variant="outlined"
                placeholder="John Smith"
                fullWidth
                required
                color="secondary"
                onChange={handleChange}
              />
              {/* <Grid container spacing={2} columns="16">
                <Grid item xs>
                  <TextField
                    className="mb-16"
                    name="name"
                    label="First Name"
                    variant="outlined"
                    placeholder="John"
                    fullWidth
                    required
                    color="secondary"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    className="mb-16"
                    name="name"
                    label="Last Name"
                    variant="outlined"
                    placeholder="Smith"
                    fullWidth
                    required
                    color="secondary"
                    onChange={handleChange}
                  />
                </Grid>
              </Grid> */}

              {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  className="mb-16"
                  name="birthDay"
                  margin="none"
                  label="Birthday"
                  inputVariant="outlined"
                  type="text"
                  fullWidth
                  required
                  value={formValue.birthDay ? formValue.birthDay : Date.now()}
                  format="MMMM dd, yyyy :-: HH:mm:ss aa"
                  onChange={(date) => {
                    handleChange({ target: { name: "birthDay", value: date } });
                  }}
                />
              </MuiPickersUtilsProvider> */}
              <TextField
                type="datetime-local"
                name="birthDay"
                variant="outlined"
                className="mb-16"
                fullWidth
                required
                placeholder="*Birth Date and Time"
                value={formValue.birthDay ? formValue.birthDay : ""}
                color="secondary"
                onChange={({ target }) => {
                  console.log(target);
                  handleChange({
                    target: { name: "birthDay", value: target.value },
                  });
                }}
              />
              <GoogleMaps
                label="Birth Location"
                className="mb-16"
                onAddressChange={handleBlur}
                address={formValue.address}
                required={true}
                outlined
                onAutocompleteChange={handleAutocompleteChange}
              />
              <TextField
                type="email"
                label="Email"
                variant="outlined"
                placeholder="abc@xmail.com"
                className="mb-16"
                name="email"
                fullWidth
                required
                color="secondary"
                onChange={handleChange}
              />
              <TextField
                type="password"
                label="Password"
                variant="outlined"
                placeholder="abc@xmail.com"
                className="mb-16"
                name="password"
                fullWidth
                required
                color="secondary"
                onChange={handleChange}
              />
              <div className="mb-16 pb-16"></div>
              <Button
                type="submit"
                color="primary"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign up
              </Button>
              <div className="mb-16"></div>
              <Grid container justifyContent="center">
                <Grid item>
                  Already have an account?&nbsp;
                  <Link to="/login" style={{ textDecoration: "underline" }}>
                    Sign in
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <div className="mb-16"></div>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      {/* </ThemeProvider> */}
    </>
  );
};

export default Register;

function nonNullValues(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => value !== null)
  );
}

export function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      className="pb-16"
      {...props}
    >
      {"Copyright © "}
      <Link to="/">RitualUp</Link> {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
