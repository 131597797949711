// import node modules
import React from "react";

import ChartContext from "./chart";

const objProviders = [
  ChartContext.Provider
];

const Provider = ({ children }) => {
  for (let i = 0; i < objProviders.length; i++) {
    const ChildProvider = objProviders[i];
    children = (<ChildProvider>{children}</ChildProvider>);
  }
  return children;
}

const Contexts = {
  Chart: ChartContext.Context,
  Provider
}

export default Contexts;
