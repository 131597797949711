import React from 'react';

import {
  Box,
  Card,
  CardHeader,
  IconButton,
  CardMedia,
  Typography,
  makeStyles,
  CardContent,
} from '@material-ui/core';
import Dialog from './Dialog';

import {
  signsArt,
  planetsArt,
  elementsArt,
  housesTitleObj,
  housesObj,
} from 'services/constants';

import defaultImage from 'assets/image.png';

const useStyles = makeStyles({
  base: {
    width: 350,
    minHeight: 400,
    marginBottom: 24,
  },
  media: {
    height: 200,
  },
  icons: {
    fontSize: 28,
  },
  description: {
    height: 90,
  },
});

const truncate = (str) => {
  return str.length > 200 ? str.substring(0, 199) + '...' : str;
};

function AstroPartsCard({ infoObject }) {
  const [open, setOpen] = React.useState(false);

  const { planet, sign, element, house, meaning, imgMeaning } = infoObject;

  const handleDialogOpen = (value) => () => {
    setOpen(true);
  };

  const handleDialogClose = (value) => {
    setOpen(false);
  };

  const classes = useStyles();

  let cardTitle = '';
  if (planet) cardTitle += planet;
  if (sign) cardTitle += ' ' + sign;
  if (house) cardTitle += ' ' + housesTitleObj[house];

  return (
    <>
      <Box className={classes.base} m={3}>
        <Card>
          <CardHeader
            avatar={
              <div>
                {planet && <i className={planetsArt[planet]}></i>}{' '}
                {sign && (
                  <i className={signsArt[sign]} style={{ color: 'grey' }}></i>
                )}{' '}
                {element && <i className={elementsArt[element]}></i>}{' '}
                {house && (
                  <span className="mb-4 fontSize24">{housesObj[house]}</span>
                )}{' '}
              </div>
            }
            action={
              <IconButton aria-label="Edit" onClick={handleDialogOpen('test')}>
                <Typography variant="button">Edit</Typography>
              </IconButton>
            }
          />
          <CardMedia
            className={classes.media}
            title="image"
            image={imgMeaning || defaultImage}
          />
          <Box p={2}>
            <CardContent className={classes.description}>
              <Typography className="mb-8" variant="h5" color="textPrimary">
                {cardTitle}
              </Typography>
              <Typography variant="caption" color="textPrimary">
                {truncate(meaning)}
              </Typography>
            </CardContent>
          </Box>
        </Card>
      </Box>
      <Dialog infoObject={infoObject} open={open} onClose={handleDialogClose} />
    </>
  );
}

export default AstroPartsCard;
