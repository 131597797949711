import React, { useContext } from "react";
import Headroom from "react-headroom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import LogoSVG from "../../assets/svg/logo.svg";
import DarkLogoSVG from "../../assets/svg/logo-dark.svg";
import { Box, Button } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Hidden from "@material-ui/core/Hidden";
import SlideMenu from "views/SlideMenu";
import Drawer from "@material-ui/core/Drawer";
import { logout } from "firebase/firebaseAuthService";
import useApp from "hooks/useApp";
import { PublicOutlined, AccountCircle } from "@material-ui/icons";
import { ThemeContext } from "themes/ThemeProvider";

// import base modules
import Contexts from "contexts";

// styles
const drawerWidth = 256;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginRight: drawerWidth,
    },
  },
  containerShift: {
    [theme.breakpoints.up("sm")]: {
      width: `100%`,
      marginRight: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  // drawer: {
  //   [theme.breakpoints.up("sm")]: {
  //     width: drawerWidth,
  //     flexShrink: 0,
  //   },
  // },
  appBar: {
    flexDirection: "row",
    height: 54,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginRight: drawerWidth,
    },
  },
  appBarShift: {
    flexDirection: "row",
    height: 54,
    [theme.breakpoints.up("sm")]: {
      width: `100%`,
      marginRight: 0,
    },
  },
  menuButton: {
    marginRight: theme.spacing(0),
    marginLeft: theme.spacing(0),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  menuButtonDesktop: {
    display: "block",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    [theme.breakpoints.only("xs")]: {
      display: "block",
    },
  },
  hidden: {
    display: "none",
  },

  tabRoot: {
    textTransform: "none",
  },
  wrapper: {
    flexDirection: "row",
    gap: "8px",
  },
  labelIcon: {
    minHeight: 54,
  },
  seletedNav: {
    fontWeight: "bold",
  },
}));

//

const NavHeader = ({
  history: {
    location: { pathname },
  },
  ...props
}) => {

  const {
    valMode,
    setMode
  } = React.useContext(Contexts.Chart);

  const {
    user,
    tabState,
    handleTabChange,
    handleSlideMenuOpen,
    handleSlideDesktopMenuOpen,
  } = useApp();
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");
  const { themeName } = useContext(ThemeContext);
  const { window } = props;
  if (
    pathname === "/login" ||
    pathname === "/register" ||
    pathname === "/forgot-password"
  ) {
    return <></>;
  }

  // actions

  const toggleSlideMenu = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    handleSlideMenuOpen(open);
  };

  const handleOpenSlideMenu = () => {
    if (isMobile) {
      handleSlideMenuOpen(!tabState.slideMenuOpen);
    } else {
      handleSlideDesktopMenuOpen(true);
    }
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={"right"}
            open={tabState.slideMenuOpen && isMobile}
            onClose={toggleSlideMenu("right", false)}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <SlideMenu />
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="right"
            variant="persistent"
            open={!isMobile && tabState.slideDesktopMenuOpen}
          >
            <SlideMenu />
          </Drawer>
        </Hidden>
      </nav>
      <Headroom
        pin
        style={{
          WebkitTransition: "all .5s ease-in-out",
          MozTransition: "all .5s ease-in-out",
          OTransition: "all .5s ease-in-out",
          transition: "all .5s ease-in-out",
          position: "fixed",
          zIndex: 5,
        }}
      >
        <div className={classes.root}>
          <AppBar
            position="static"
            className={
              isMobile
                ? classes.appBar
                : tabState.slideDesktopMenuOpen
                ? classes.appBar
                : classes.appBarShift
            }
            color="default"
          >
            <IconButton edge="end" color="inherit" aria-label="logo">
              <img alt="logo" src={themeName === "lightTheme" ? LogoSVG : DarkLogoSVG} />
            </IconButton>
            <Box display="flex" justifyContent={`space-between`} flexGrow={1}>
              <Box display={`flex`}>
                <Box p={1} borderBottom={valMode === 0 ? 1 : 0}>
                  <Link to={`/`} replace>
                    <Button
                      variant="text"
                      startIcon={<PublicOutlined />}
                      color={"default"}
                      className="capitalize"
                      classes={{
                        root: (valMode === 0 ? classes.seletedNav : '')
                      }}
                    >
                      Global/Now
                    </Button>
                  </Link>
                </Box>
                <Box p={1} borderBottom={valMode === 1 ? 1 : 0}>
                  <Link to="/me">
                    <Button
                      variant="text"
                      startIcon={<AccountCircle />}
                      color={"default"}
                      className="capitalize"
                      classes={{
                        root: (valMode === 1 ? classes.seletedNav : '')
                      }}
                    >
                      Me
                    </Button>
                  </Link>
                </Box>
                {/* <Box p={1}>
                  {user && user.isAdmin && (
                    <Link to="/admin/info-objects/astro-parts">
                      <Button
                        color={
                          pathname.includes("admin") ? "primary" : "default"
                        }
                        className="capitalize"
                      >
                        Admin
                      </Button>
                    </Link>
                  )}
                </Box> */}
              </Box>
            </Box>
            <IconButton
              edge="start"
              className={
                isMobile
                  ? classes.menuButton
                  : !tabState.slideDesktopMenuOpen
                  ? classes.menuButtonDesktop
                  : classes.hidden
              }
              color="inherit"
              aria-label="menu"
              onClick={handleOpenSlideMenu}
            >
              <MenuIcon />
            </IconButton>
          </AppBar>
          {/* <TabPanel value={value} index={0}>
        <Home />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Home />
      </TabPanel> */}
          {/* <Drawer
          anchor={"right"}
          open={tabState.slideMenuOpen}
          onClose={toggleSlideMenu("right", false)}
        >
          <SlideMenu />
        </Drawer> */}
        </div>
        {/* <div style={{ width: "100%" }}>
          <Box
            display="flex"
            borderBottom={1}
            justifyContent={`space-between`}
            bgcolor="background.paper"
          >
            <Box display={`flex`}>
              <Box p={1}>
                <Link to={`/`} replace>
                  <Button
                    color={pathname === "/" ? "primary" : "default"}
                    className="capitalize"
                  >
                    Transits
                  </Button>
                </Link>
              </Box>
              <Box p={1}>
                {user && user.isAdmin && (
                  <Link to="/transit-import">
                    <Button
                      color={
                        pathname.includes("transit-import")
                          ? "primary"
                          : "default"
                      }
                      className="capitalize"
                    >
                      Import transit
                    </Button>
                  </Link>
                )}
              </Box>
              <Box p={1}>
                {user && user.isAdmin && (
                  <Link to="/admin/info-objects/astro-parts">
                    <Button
                      color={pathname.includes("admin") ? "primary" : "default"}
                      className="capitalize"
                    >
                      Admin
                    </Button>
                  </Link>
                )}
              </Box>
            </Box>
            <Box p={1}>
              {user ? (
                <Button
                  className="capitalize ml-auto"
                  onClick={logout}
                  color={pathname.includes("logout") ? "primary" : "default"}
                >
                  logout
                </Button>
              ) : (
                <Link to={`/login`} replace>
                  <Button
                    className="capitalize ml-auto"
                    color={pathname.includes("login") ? "primary" : "default"}
                  >
                    Login
                  </Button>
                </Link>
              )}
            </Box>
          </Box>
        </div> */}
      </Headroom>
    </>
  );
};

export default withRouter(NavHeader);
