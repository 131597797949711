import React from 'react'
import AdminNavbar from './AdminNavbar'

function Values() {
  return (
    <AdminNavbar>
      Values
    </AdminNavbar>
  )
}

export default Values
