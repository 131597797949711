import React, { useState, useEffect, useReducer } from "react";
import { getUserData } from "./firebase/firebaseAuthService";
import history from "./history";
import Utils from "utils";

export const ACTION_TYPE = {
  TAB_CHANGED: "TAB_CHANGED",
  TAB_DESELECTED: "TAB_DESELECTED",
  NEW_CHART_DIALOG_OPEN: "NEW_CHART_DIALOG_OPEN",
  NEW_CHART_DIALOG_CLOSE: "NEW_CHART_DIALOG_CLOSE",
  SAVE_CHART_DIALOG_OPEN: "SAVE_CHART_DIALOG_OPEN",
  SAVE_CHART_DIALOG_CLOSE: "SAVE_CHART_DIALOG_CLOSE",
  SLIDE_MENU_OPEN: "SLIDE_MENU_OPEN",
  SLIDE_MENU_CLOSE: "SLIDE_MENU_CLOSE",
  FILTERED_USER_CHART_CHANGE: "FILTERED_USER_CHART_CHANGE",
  ORIGINAL_USER_CHART_CHANGE: "ORIGINAL_USER_CHART_CHANGE",
  ACTIVE_CHART_CHANGE: "ACTIVE_CHART_CHANGE",
  IS_NEW_CHART_DIALOG: "IS_NEW_CHART_DIALOG",
  CHART_EDITED: "CHART_EDITED",
  SLIDE_DESKTOP_MENU_OPEN: "SLIDE_DESKTOP_MENU_OPEN",
  SLIDE_DESKTOP_MENU_CLOSE: "SLIDE_DESKTOP_MENU_CLOSE",
  PRO_USER_MODAL_SHOW: "PRO_USER_MODAL_SHOW",
  EVENT_MODAL_SHOW: "EVENT_MODAL_SHOW",

};

export const AppContext = React.createContext();

const initialState = {
  selectedRoute: "",
  selectedTabIndex: 0,
  newChartDialogOpen: false,
  saveChartDialogOpen: false,
  slideMenuOpen: false,
  slideDesktopMenuOpen: true,
  filteredUserCharts: [],
  originalUserCharts: [],
  selectedChart: null,
  isNewChartDialog: true,
  chartEdited: false,
  proUserModalShow: false,
  selectedEventModalShow: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE.TAB_CHANGED: {
      return {
        ...state,
        selectedTabIndex: action.newIndex,
      };
    }
    case ACTION_TYPE.TAB_DESELECTED: {
      return {
        ...state,
        selectedTabIndex: false,
      };
    }
    case ACTION_TYPE.NEW_CHART_DIALOG_OPEN: {
      return {
        ...state,
        newChartDialogOpen: true,
      };
    }
    case ACTION_TYPE.NEW_CHART_DIALOG_CLOSE: {
      return {
        ...state,
        newChartDialogOpen: false,
      };
    }
    case ACTION_TYPE.SAVE_CHART_DIALOG_OPEN: {
      return {
        ...state,
        saveChartDialogOpen: true,
      };
    }
    case ACTION_TYPE.SAVE_CHART_DIALOG_CLOSE: {
      return {
        ...state,
        saveChartDialogOpen: true,
      };
    }
    case ACTION_TYPE.SLIDE_MENU_OPEN: {
      return {
        ...state,
        slideMenuOpen: true,
      };
    }
    case ACTION_TYPE.SLIDE_MENU_CLOSE: {
      return {
        ...state,
        slideMenuOpen: false,
      };
    }
    case ACTION_TYPE.ORIGINAL_USER_CHART_CHANGE: {
      return {
        ...state,
        originalUserCharts: [...action.payload]
      }
    }
    case ACTION_TYPE.FILTERED_USER_CHART_CHANGE: {
      return {
        ...state,
        filteredUserCharts: [...action.payload]
      }
    }
    case ACTION_TYPE.ACTIVE_CHART_CHANGE: {
      return {
        ...state,
        selectedChart: action.payload
      }
    }
    case ACTION_TYPE.IS_NEW_CHART_DIALOG: {
      return {
        ...state,
        isNewChartDialog: action.payload
      }
    }
    case ACTION_TYPE.CHART_EDITED: {
      return {
        ...state,
        chartEdited: action.payload
      }
    }
    case ACTION_TYPE.SLIDE_DESKTOP_MENU_OPEN: {
      return {
        ...state,
        slideDesktopMenuOpen: true
      }
    }
    case ACTION_TYPE.SLIDE_DESKTOP_MENU_CLOSE: {
      return {
        ...state,
        slideDesktopMenuOpen: false
      }
    }
    case ACTION_TYPE.PRO_USER_MODAL_SHOW: {
      return {
        ...state,
        proUserModalShow: action.payload
      }
    }
    case ACTION_TYPE.EVENT_MODAL_SHOW: {
      return {
        ...state,
        selectedEventModalShow: action.payload
      }
    }
    default:
      return state;
  }
};

const AppContextProvider = ({ children }) => {
  const [tabState, dispatch] = useReducer(reducer, initialState);
  const [user, setUser] = useState(null);

  const [objSettings, setSettings] = React.useState({
    strHouseSystem: "P",
    isShowMinutes: true,
    isShowRetrograde: false,
    planetsSun: true,
    planetsMoon: true,
    planetsMercury: true,
    planetsVenus: true,
    planetsMars: true,
    planetsJupiter: true,
    planetsSaturn: true,
    planetsUranus: true,
    planetsNeptune: true,
    planetsPluto: true,
    nodesNorthNode: true,
    nodesNorthNodeMedian: false,
    nodesSouthNode: true,
    nodesSouthNodeMedian: false,
    pointsAscendant: true,
    pointsMidheaven: true,
    pointsDecendant: false,
    pointsIlliumcolli: false,
    orbsConjunction: 5,
    orbsOpposition: 5,
    orbsSquare: 5,
    orbsTrine: 5,
    orbsSextile: 5
  });


  useEffect(() => {
    getUserData((userData) => {
      let _user = userData.val();
      if (!_user) history.push("/");
      setUser(_user);
    });
  }, []);

  const handleTabChange = (newIndex) => {
    dispatch({ type: ACTION_TYPE.TAB_CHANGED, newIndex });
  };
  const handleTabDeselect = () => {
    dispatch({ type: ACTION_TYPE.TAB_DESELECTED });
  };
  const handleNewChartDialog = (openStatus) => {
    if (openStatus) {
      dispatch({ type: ACTION_TYPE.NEW_CHART_DIALOG_OPEN, openStatus });
    } else {
      dispatch({ type: ACTION_TYPE.NEW_CHART_DIALOG_CLOSE, openStatus });
    }
  };

  const handleSlideMenuOpen = (openStatus) => {
    if (openStatus) {
      dispatch({ type: ACTION_TYPE.SLIDE_MENU_OPEN, openStatus });
    } else {
      dispatch({ type: ACTION_TYPE.SLIDE_MENU_CLOSE, openStatus });
    }
  };
  const handleSlideDesktopMenuOpen = (openStatus) => {
    if (openStatus) {
      dispatch({ type: ACTION_TYPE.SLIDE_DESKTOP_MENU_OPEN, openStatus });
    } else {
      dispatch({ type: ACTION_TYPE.SLIDE_DESKTOP_MENU_CLOSE, openStatus });
    }
  };

  const handleOriginalUserChartChange = (payload) => {
    dispatch({
      type: ACTION_TYPE.ORIGINAL_USER_CHART_CHANGE,
      payload
    });
  }

  const handleFilteredUserChartChange = (payload) => {
    dispatch({
      type: ACTION_TYPE.FILTERED_USER_CHART_CHANGE,
      payload
    });
  }

  const handleActiveChartChange = (payload) => {
    dispatch({
      type: ACTION_TYPE.ACTIVE_CHART_CHANGE,
      payload
    });
  }

  const handleSwitchNewChartAndEditProfile = (payload) => {
    dispatch({
      type: ACTION_TYPE.IS_NEW_CHART_DIALOG,
      payload
    })
  }
  const handleChartEdited = (payload) => {
    dispatch({
      type: ACTION_TYPE.CHART_EDITED,
      payload
    })
  }
  const handleProModalShow = (payload) => {
    dispatch({
      type: ACTION_TYPE.PRO_USER_MODAL_SHOW,
      payload
    })
  }
  const handleEventModalShow = (payload) => {
    console.log(payload);
    dispatch({
      type: ACTION_TYPE.EVENT_MODAL_SHOW,
      payload
    })
  }

  return (
    <AppContext.Provider
      value={{
        user,
        tabState,
        objSettings,
        setSettings,
        handleTabChange,
        handleTabDeselect,
        handleNewChartDialog,
        handleSlideMenuOpen,
        handleOriginalUserChartChange,
        handleFilteredUserChartChange,
        handleActiveChartChange,
        handleSwitchNewChartAndEditProfile,
        handleChartEdited,
        handleSlideDesktopMenuOpen,
        handleEventModalShow,
        handleProModalShow
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
