import { /* differenceInMinutes, */ isAfter /* , isFuture, isPast */ } from "date-fns";
import moment from "moment-timezone";

export function sortByAlphabetical(arr) {
  return arr.sort((a, b) => a.name.localeCompare(b.name));
}

export function sortByNewest(arr) {
  return arr.sort(function (a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return new Date(b.dateTime) - new Date(a.dateTime);
  });
}

export function filterByKeyword(arr, keyword) {
  return arr.filter((obj) =>
    obj.name.toLowerCase().includes(keyword.toLowerCase())
  );
}

export function sortForUpcoming(arr) {
  // console.log("I should be not called from loadprevious action.");
  const newArr = [...arr];
  let count = 0;
  newArr.forEach((e, index) => {
    if (count === 0 && isAfter(new Date(e.eventTime), new Date())) {
      // console.log(e);
      count += 1;
      e.top = true;
    }
  });

  return newArr;
}

//Production safe logging

/**
 * DLog
 *
 * * This allows logging during dev and prevents any logs from stage or production builds
 *
 * Utilize built-in environment variable `NODE_ENV.` You can read it from process.env.NODE_ENV.
 * When you run npm start, it is always equal to 'development', when you run npm test it is always
 * equal to 'test', and when you run npm run build to make a production bundle, it is always equal
 * to 'production'. You cannot override NODE_ENV manually.
 *
 * @param {*} obj optional message object param, e.g., 'DLog("> testing: ", someObj.prop);'
 * @returns
 */

export const DLog = (message, obj = null) => {
  if (process.env.NODE_ENV !== "development") return;
  // console.log(message, obj !== null ? obj : "");
};

export const currentTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getTimezones = () => {
  const timezones = moment.tz.names().map((tz) => ({ value: tz, label: tz }));
  return timezones;
};

export const getOffset = (timezone) => {
  // const timeZoneName = Intl.DateTimeFormat("ia", {
  //   timeZoneName: "short",
  //   timeZone,
  // })
  //   .formatToParts()
  //   .find((i) => i.type === "timeZoneName").value;
  // const offset = timeZoneName.slice(3);
  // if (!offset) return 0;

  // const matchData = offset.match(/([+-])(\d+)(?::(\d+))?/);
  // if (!matchData) throw `cannot parse timezone name: ${timeZoneName}`;

  // const [, sign, hour, minute] = matchData;
  // let result = parseInt(hour) * 60;
  // if (sign === "+") result *= -1;
  // if (minute) result += parseInt(minute);

  // return result;

  return moment.tz(timezone).utcOffset() / 60;
};

export const getTimezoneOffset = (timeZone, date = new Date()) => {
  const tz = date
    .toLocaleString("en", { timeZone, timeStyle: "long" })
    .split(" ")
    .slice(-1)[0];
  const dateString = date.toString();
  const offset =
    Date.parse(`${dateString} UTC`) - Date.parse(`${dateString} ${tz}`);

  // return UTC offset in millis
  return offset;
};

export const getUTCDateFromZoneName = (date, zone) => {
  const utcDate = moment.tz(date, zone).utc().format();
  return utcDate;
};

export const getTimezoneOffsetFromZoneName = (date, zone) => {
  const currentTimezoneDate = moment.tz(date, zone);
  // console.log(currentTimezoneDate.format());
  const offset = moment.parseZone(currentTimezoneDate.format()).utcOffset() / 60;
  return offset;
};
