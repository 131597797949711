export const IPGEOLOCATION_API_KEY = '307352210abf41d29ac8c0a2425f4ecd';
export const GOOGLEGEOCODE_API_KEY = 'AIzaSyCfC1I-h9CHPL4N5TzLRqgzreIaNu7Gxs0';
export const IPGEOLOCATION_API_BASE_URL = 'https://api.ipgeolocation.io/';
export const URL = 'https://ritualup.net/?';
// export const URL = 'http://localhost:8080/?';

export const signs = [
  'Aries',
  'Taurus',
  'Gemini',
  'Cancer',
  'Leo',
  'Virgo',
  'Libra',
  'Scorpio',
  'Sagittarius',
  'Capricorn',
  'Aquarius',
  'Pisces',
];

export const signNumberedObject = {
  1: 'Aries',
  2: 'Taurus',
  3: 'Gemini',
  4: 'Cancer',
  5: 'Leo',
  6: 'Virgo',
  7: 'Libra',
  8: 'Scorpio',
  9: 'Sagittarius',
  10: 'Capricorn',
  11: 'Aquarius',
  12: 'Pisces',
};

export const planets = [
  'Sun',
  'Moon',
  'Mercury',
  'Venus',
  'Mars',
  'Jupiter',
  'Saturn',
  'Uranus',
  'Neptune',
  'Pluto',
];

export const planetsArt = {
  Sun: 'hermetica-B000-sun',
  Moon: 'hermetica-B001-moon',
  Mercury: 'hermetica-B002-mercury',
  Venus: 'hermetica-B003-venus',
  Mars: 'hermetica-B005-mars',
  Jupiter: 'hermetica-B006-jupiter',
  Saturn: 'hermetica-B007-saturn',
  Uranus: 'hermetica-B008-uranus',
  Neptune: 'hermetica-B010-neptune',
  Pluto: 'hermetica-B011-pluto',
  Ascendant: 'hermetica-D000-fire',
  Midheaven: 'hermetica-D003-earth',
  Descendant: 'hermetica-D002-air',
  Imumcoeli: 'hermetica-D001-water',
};

export const signsArt = {
  Aries: 'hermetica-A000-aries',
  Taurus: 'hermetica-A001-taurus',
  Gemini: 'hermetica-A002-gemini',
  Cancer: 'hermetica-A003-cancer',
  Leo: 'hermetica-A004-leo',
  Virgo: 'hermetica-A005-virgo',
  Libra: 'hermetica-A006-libra',
  Scorpio: 'hermetica-A007-scorpio',
  Sagittarius: 'hermetica-A008-sagittarius',
  Capricorn: 'hermetica-A009-capricorn',
  Aquarius: 'hermetica-A010-aquarius',
  Pisces: 'hermetica-A011-pisces',
};

export const aspects = [
  'Conjunction',
  'Sextile',
  'Square',
  'Trine',
  'Opposition',
];

export const aspectsArt = {
  Conjunction: 'hermetica-A015-conjunction',
  Sextile: 'hermetica-A020-semisextile',
  Square: 'hermetica-A018-square',
  Trine: 'hermetica-A017-trine',
  Opposition: 'hermetica-A016-opposition',
};

export const elements = ['Fire', 'Earth', 'Air', 'Water'];

export const elementsArt = {
  Fire: 'hermetica-D000-fire',
  Earth: 'hermetica-D003-earth',
  Air: 'hermetica-D002-air',
  Water: 'hermetica-D001-water',
};

export const houses = [
  '1st',
  '2nd',
  '3rd',
  '4th',
  '5th',
  '6th',
  '7th',
  '8th',
  '9th',
  '10th',
  '11th',
  '12th',
];

export const housesObj = {
  1: '1st',
  2: '2nd',
  3: '3rd',
  4: '4th',
  5: '5th',
  6: '6th',
  7: '7th',
  8: '8th',
  9: '9th',
  10: '10th',
  11: '11th',
  12: '12th',
};

export const housesTitleObj = {
  1: '1st House',
  2: '2nd House',
  3: '3rd House',
  4: '4th House',
  5: '5th House',
  6: '6th House',
  7: '7th House',
  8: '8th House',
  9: '9th House',
  10: '10th House',
  11: '11th House',
  12: '12th House',
};

export const sign2Element = {
  Aries: 'Fire',
  Taurus: 'Earth',
  Gemini: 'Air',
  Cancer: 'Water',
  Leo: 'Fire',
  Virgo: 'Earth',
  Libra: 'Air',
  Scorpio: 'Water',
  Sagittarius: 'Fire',
  Capricorn: 'Earth',
  Aquarius: 'Air',
  Pisces: 'Water',
};
