import useApp from "hooks/useApp";
import React from "react";
import { Redirect } from "react-router-dom";
import Main from "./main";

const UserTransitWrapper = (children) => {
  const { user, tabState } = useApp();
  return (
    <>
      {user && tabState.selectedChart ? (
        <Main tabIndex={2} />
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
};

export default UserTransitWrapper;
