import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  TextField,
  makeStyles,
} from '@material-ui/core';

import {
  signsArt,
  planetsArt,
  housesObj,
  elementsArt,
} from 'services/constants';

import firebase from 'firebase/firebaseConfig';

import defaultImage from 'assets/image.png';
import { useState } from 'react';

const useStyles = makeStyles({
  imgContainer: {
    position: 'relative',
    marginBottom: 16,
  },
  imgRef: {
    top: '70%',
    left: 40,
    width: '50%',
    position: 'absolute',
    backgroundColor: 'white',
    borderRadius: 4,
    padding: 16,
  },
  input: {
    display: 'none',
  },
});

export default function ScrollDialog({ open, infoObject, onClose }) {
  const classes = useStyles();

  const {
    id,
    planet,
    sign,
    element,
    house,
    meaning,
    imgMeaning,
    ritual,
    imgRitual,
  } = infoObject;

  const [newMeaning, setNewMeaning] = useState(meaning);
  const [newRitual, setNewRitual] = useState(ritual);
  const [newImgRitual, setNewImgRitual] = useState(imgRitual);
  const [newImgMeaning, setNewImgMeaning] = useState(imgMeaning);

  const handleUpdate = (id, newInfoObject) => {
    firebase
      .firestore()
      .collection('info-objects')
      .doc(id)
      .set(
        {
          ...newInfoObject,
        },
        { merge: true }
      )
      .then(() => {
        // Toaster can be implemented here; not necessary
        console.log('Document successfully written!');
      })
      .catch((error) => {
        console.error('Error writing document: ', error);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      scroll="body"
      className="p-24"
    >
      <form>
        <div className={classes.imgContainer}>
          <img
            src={imgMeaning || defaultImage}
            className="mb-24 "
            alt="img"
            width="600"
          />
          <div className={classes.imgRef}>
            <TextField
              id="imgMeaningId"
              label="Meaning Image"
              value={newImgMeaning}
              onChange={(event) => setNewImgMeaning(event.target.value)}
              fullWidth
            />
          </div>
        </div>
        <div className="text-center">
          <div>
            {planet && <i className={planetsArt[planet]}></i>}{' '}
            {sign && (
              <i className={signsArt[sign]} style={{ color: 'grey' }}></i>
            )}{' '}
            {element && <i className={elementsArt[element]}></i>}{' '}
            {house && <span className="fontSize24">{housesObj[house]}</span>}{' '}
          </div>
          <DialogTitle id="scroll-dialog-title">{`${planet} ${sign} ${house}`}</DialogTitle>
        </div>
        <DialogContent>
          <DialogContentText component="div" tabIndex={1}>
            <Typography
              className="mb-16"
              component="span"
              variant="h5"
              color="textPrimary"
            >
              Meaning
            </Typography>
            <TextField
              id="meaning-field"
              label="Meaning"
              multiline
              component="span"
              value={newMeaning}
              onChange={(event) => setNewMeaning(event.target.value)}
              variant="outlined"
              color="primary"
              fullWidth
            />
          </DialogContentText>
          <div className={classes.imgContainer}>
            {/* <input
              accept="image/*"
              className={classes.input}
              id="imgMeaningId"
              multiple
              type="file"
            />
            <label htmlFor="imgMeaningId">
              <Button variant="contained" component="span">
                Upload Meaning Image
              </Button>
            </label> */}
            <img
              src={imgRitual || defaultImage}
              alt="img"
              width="500"
              className="mx-24"
              style={{
                borderRadius: 4,
              }}
            />
            <div className={classes.imgRef}>
              {/* <input
                accept="image/*"
                className={classes.input}
                id="ritualMeaningId"
                multiple
                type="file"
              />
              <label htmlFor="ritualMeaningId">
                <Button variant="contained" component="span">
                  Upload Ritual Image
                </Button>
              </label> */}
              <TextField
                id="ritualMeaningId"
                label="Ritual Image"
                fullWidth
                value={newImgRitual}
                onChange={(event) => setNewImgRitual(event.target.value)}
              />
            </div>
          </div>
          <DialogContentText component="div" className="mb-16" tabIndex={2}>
            <Typography
              className="mb-16"
              component="span"
              variant="h5"
              color="textPrimary"
            >
              Ritual
            </Typography>
            <TextField
              id="ritual-field"
              label="Ritual"
              multiline
              value={newRitual}
              onChange={(event) => setNewRitual(event.target.value)}
              variant="outlined"
              component="span"
              color="primary"
              fullWidth
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions className="p-24">
          <Button onClick={onClose} variant="contained" color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() =>
              handleUpdate(id, {
                meaning: newMeaning,
                ritual: newRitual,
                imgRitual: newImgRitual,
                imgMeaning: newImgMeaning,
              })
            }
            variant="contained"
            color="primary"
          >
            {infoObject ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
