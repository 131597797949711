import firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';

export const firebaseConfig = {
  apiKey: 'AIzaSyCfC1I-h9CHPL4N5TzLRqgzreIaNu7Gxs0',
  authDomain: 'ritualup-fc620.firebaseapp.com',
  databaseURL: 'https://ritualup-fc620.firebaseio.com',
  projectId: 'ritualup-fc620',
  storageBucket: 'ritualup-fc620.appspot.com',
  messagingSenderId: '1008656342008',
  appId: '1:1008656342008:web:a84aa3939cd1736289d40d',
  measurementId: 'G-8MZGQ0F3Z9',
};

// firebase.initializeApp(firebaseConfig);

// firebase.auth();
// firebase.database();

export default firebase;

// KuBLmpVLIZaTokCQ3gE2vpoY6fG3 test id
