import GEO from "./geo";
import Time from "./time";

const Log = (...args) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(...args);
  }
}

const Utils = {
  GEO,
  Log,
  Time
};

export default Utils;