import React from 'react'
import TransitImporter from 'views/TransitImporter'
import AdminNavbar from './AdminNavbar'

function ImportTransits() {
  return (
    <AdminNavbar>
      <TransitImporter />
    </AdminNavbar>
  )
}

export default ImportTransits
