import React from "react";
import moment from "moment";
// Random component
// const Completionist = () => <span>You are good to go!</span>;

// Renderer callback with condition
const CountdownRenderer = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
  total,
  props,
  className
}) => {
  if (completed) {
    // Render a completed state
    const a = moment(new Date());
    const b = moment(new Date(props.date));
    const diff_s = a.diff(b, "seconds");
    const duration = moment.duration(diff_s, "seconds");
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    if (days > 0) {
      return (
        <span>
          {days}days, {hours}hrs {minutes}min, since transit{" "}
        </span>
      );
    }
    if (hours > 0)
      return (
        <span>
          {hours}hrs {minutes}min, since transit
        </span>
      );
    return <span>{minutes}min, since transit</span>;
  } else {
    // Render a countdown
    if (days > 0) {
      return (
        <span>
          {days}
          {days > 1 ? "days" : "day"}, {hours}hrs {minutes}min, until
        </span>
      );
    } else {
      return (
        <span>
          {hours + 24 * days}hrs {minutes}min {seconds}sec, until
        </span>
      );
    }
  }
};

export default CountdownRenderer;
