// import node modules
import axios from "axios";
import moment from "moment";

// import react modules
import * as MaterialUI from "@material-ui/core";
import React from "react";

// import base modules
import CONSTS from "consts";
import Utils from "utils";

import { AppContext } from "AppContext";
import { ThemeContext } from "themes/ThemeProvider";

const getChartURL = args => {
  // console.log('getChartURL');

  const {
    objLocation,
    strDate
  } = args;

  let strURLChart = CONSTS.URLS.SERVER + '?';
  strURLChart += `n=${moment(strDate).utc().format('D/M/YYYY/H/m')}`;
  args.objLocation && (strURLChart += `&lx=${objLocation.valLatitude}&ly=${objLocation.valLongitude}`)
  strURLChart += `&svgstring=true`;
  
  const { objSettings, themeName } = args;
  var objPlanetIDs = [];
  if (objSettings.planetsSun)
    objPlanetIDs.push(0);
  if (objSettings.planetsMoon)
    objPlanetIDs.push(1);
  if (objSettings.planetsMercury)
    objPlanetIDs.push(2);
  if (objSettings.planetsVenus)
    objPlanetIDs.push(3);
  if (objSettings.planetsMars)
    objPlanetIDs.push(4);
  if (objSettings.planetsJupiter)
    objPlanetIDs.push(5);
  if (objSettings.planetsSaturn)
    objPlanetIDs.push(6);
  if (objSettings.planetsUranus)
    objPlanetIDs.push(7);
  if (objSettings.planetsNeptune)
    objPlanetIDs.push(8);
  if (objSettings.planetsPluto)
    objPlanetIDs.push(9);
  if (objSettings.nodesNorthNode)
    objPlanetIDs.push(10);
  if (objSettings.nodesSouthNode)
    objPlanetIDs.push(11);
  if (objSettings.nodesNorthNodeMedian)
    objPlanetIDs.push(30);
  if (objSettings.nodesSouthNodeMedian)
    objPlanetIDs.push(31);
  if (objSettings.pointsAscendant)
    objPlanetIDs.push('ac');
  if (objSettings.pointsMidheaven)
    objPlanetIDs.push('mc');
  if (objSettings.pointsDecendant)
    objPlanetIDs.push('dc');
  if (objSettings.pointsIlliumcolli)
    objPlanetIDs.push('ic');
  if (objPlanetIDs.length)
    strURLChart += '&planets=' + objPlanetIDs.join(',');
  strURLChart += '&houses=' + objSettings.strHouseSystem;    
  var strOrbs = [];
  if (objSettings.orbsConjunction)
    strOrbs.push(`conjunction/${objSettings.orbsConjunction}`);
  if (objSettings.orbsOpposition)
    strOrbs.push(`opposition/${objSettings.orbsOpposition}`);
  if (objSettings.orbsSquare)
    strOrbs.push(`square/${objSettings.orbsSquare}`);
  if (objSettings.orbsTrine)
    strOrbs.push(`trine/${objSettings.orbsTrine}`);
  if (objSettings.orbsSextile)
    strOrbs.push(`sextile/${objSettings.orbsSextile}`);
  if (strOrbs.length)
    strURLChart += '&chartorbs=' + strOrbs.join(',');
  strURLChart += '&showminutes=' + objSettings.isShowMinutes;
  strURLChart += '&showretrograde=' + objSettings.isShowRetrograde;
  strURLChart += '&svgstyle=' + (themeName === 'darkTheme' ? 'dark' : 'default');

  Utils.Log(strURLChart);

  return strURLChart;
//     if (objLocation) {
//       strURLChart += `&lx=${objLocation.valLatitude}&ly=${objLocation.valLongitude}`;
//     }
//     strURLChart += '&svgstring=true';
//     strURLChart += getURLChart();
//     axios.get(strURLChart).then(response => {
//       console.log('V', 'Chart', 5, strURLChart);
//       setChartImage(response.data);
//     });
//     /*let chartUrl = "";
//       let dateTime = getUtcDateTime(
//         formValue.dateTime,
//         - new Date().getTimezoneOffset() / 60
//       );
//       let dt = `${dateTime.day}/${dateTime.month}/${dateTime.year}/${dateTime.hour}/${dateTime.minute}/${dateTime.second}`;
//       var strUTCTime = moment(formValue.dateTime).utc().format("D/M/YYYY/H/m/s");
//       if (formValue.address) {
//         const results = await geocodeByAddress(formValue.address);
//         const position = await getLatLng(results[0]);
//         const { lat, lng } = position;
//         const { timezone } = await getDstTimezoneOffset(lat, lng);

//         const offset = getTimezoneOffsetFromZoneName(
//           formValue.dateTime,
//           timezone
//         );
//         dateTime = getUtcDateTime(formValue.dateTime, offset);
//         dt = `${dateTime.day}/${dateTime.month}/${dateTime.year}/${dateTime.hour}/${dateTime.minute}/${dateTime.second}`;
//         chartUrl = `${URL}n=${strUTCTime}&lx=${lat}&ly=${lng}&svgstring=true`;
//       } else {
//         chartUrl = `${URL}n=${strUTCTime}&svgstring=true`;
//       }
//       chartUrl += getURLChart();
//       axios.get(chartUrl).then((res) => {
//         console.log('V', 'Chart', 4, chartUrl, formValue);
//         setShowChartButtons(true);
//         setChartImage(res.data);
//       });
//     }*/
}

const ChartSVG = props => {  
  Utils.Log('[ChartSVG]');

  const {
    date: strDate,
    location: objLocation,
    ...objTags
  } = props;  

  const [objState] = React.useState({
    objChart: null
  })
  const [objChart, setChart] = React.useState(null);

  const { objSettings } = React.useContext(AppContext);
  const { themeName } = React.useContext(ThemeContext);
  const handleUpdateChart = () => {
    if (!objLocation || !strDate) return;
    axios.get(getChartURL({ objLocation, strDate, objSettings, themeName })).then(res => {
      setChart(res.data);
    });
  }

  React.useEffect(handleUpdateChart, [objLocation, strDate, objSettings, themeName]);
  
  return (
    <MaterialUI.Box dangerouslySetInnerHTML={{ __html: objChart }} {...objTags} />
  );
};

export default ChartSVG