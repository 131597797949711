import React from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  makeStyles,
  FormHelperText,
} from '@material-ui/core';

import {
  planets,
  planetsArt,
  signs,
  signsArt,
  elements,
  houses,
  elementsArt,
} from 'services/constants';
import { useState } from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(4),
    minWidth: 160,
    height: 45,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: 40,
  },
}));

function Selection({ label, items, arts, value, setValue, disabled }) {
  const classes = useStyles();

  const labelId = label + 'Selector';

  return (
    <FormControl disabled={disabled} className={classes.formControl}>
      <InputLabel id={labelId}>{label.toUpperCase()}</InputLabel>
      <Select
        labelId={labelId}
        id={label}
        value={value}
        MenuProps={MenuProps}
        onChange={(event) => setValue(event.target.value)}
      >
        <MenuItem value="all">All</MenuItem>
        <MenuItem value="">None</MenuItem>
        {items.map((item) => (
          <MenuItem key={item} value={item}>
            {arts && <i className={arts[item] + ' mr-12'}></i>} {item}
          </MenuItem>
        ))}
      </Select>
      {disabled && (
        <FormHelperText>
          You already choose {label === 'sign' ? 'an element' : 'a sign'}
        </FormHelperText>
      )}{' '}
    </FormControl>
  );
}

function SelectionMenu({ onClick }) {
  const classes = useStyles();
  const [planet, setPlanet] = useState('');
  const [sign, setSign] = useState('');
  const [element, setElement] = useState('');
  const [house, setHouse] = useState('');

  const onlyHouse = Boolean(!sign && !planet && !element && house)
  return (
    <>
      <Box display="flex" flexWrap="wrap">
        <Selection
          value={planet}
          setValue={setPlanet}
          label="planet"
          items={planets}
          arts={planetsArt}
        />
        <Selection
          value={sign}
          setValue={setSign}
          label="sign"
          items={signs}
          arts={signsArt}
          disabled={!!element}
        />
        <Selection
          value={element}
          setValue={setElement}
          label="element"
          items={elements}
          arts={elementsArt}
          disabled={!!sign}
        />
        <Selection
          value={house}
          setValue={setHouse}
          label="house"
          items={houses}
        />
        <Box flex="1">
        <Button
          type="submit"
          className={classes.button}
          color="primary"
          variant="contained"
          onClick={onClick({ sign, planet, element, house, back: 0, next: 0 })}
          disabled={onlyHouse || (!sign && !planet && !element && !house)}
        >
          Search
        </Button>
        {onlyHouse && (
        <FormHelperText>
          There are not info objects with only a House. Please add another field.
        </FormHelperText>
      )}
      </Box>
      </Box>
    </>
  );
}

export default SelectionMenu;
