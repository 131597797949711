import React, { useState, Fragment/* , memo */ } from "react";
// import { planetsArt, signs, signsArt } from "services/constants";
import { updateFirebaseEvent } from "services/dataService";
import { Grid, TextField, Button, Box } from "@material-ui/core";
import TransitEvent from "./TransitEvent";
import useApp from "hooks/useApp";
import { DLog } from "services/utils";
import { useEffect } from "react";

import * as Components from "components";

const TransitEditor = ({ event: e, index, selectedEvent, handleSelectedEventChange }) => {
  const { user } = useApp(); 

  const [formValue, setFormValue] = useState(null);
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    let isSubscribed = true;

    const updateFormValue = () => {
      if (isSubscribed) {
        setFormValue({...e});
      }
    };

    updateFormValue();

    return () => {
      isSubscribed = false;
    };
  }, [e]);

  const handleChange = (name, value) => {
    console.log('Change', name, value);
    formValue[name] = value;
  };

  const handClickEdit = () => {
    DLog("event => ", e);
    setEditable(!editable);
  }

  const handleSubmit = async () => {
    
    updateFirebaseEvent(formValue).then(() => {
      setEditable(!editable);
    });
  };

  return (
    <div className="p-8 max-w-450 mx-auto">
      {formValue && (
        <Grid container className="" spacing={3}>
          <Grid item xs={12}>
            <div className="mx-auto position-relative">
              <TransitEvent event={e} index={index} selectedEvent={selectedEvent} handleSelectedEventChange={handleSelectedEventChange}></TransitEvent>
              {/* {e.ritual && <p className="m-0 mb-4">{e.ritual}</p>}
              {e.meaning && <p className="m-0 mb-4">{e.meaning}</p>}
              {e.description3 && <p className="m-0 mb-4">{e.description3}</p>}
              {e.description4 && <p className="m-0 mb-4">{e.description4}</p>}
              {e.artwork && (
                <div className="artwork position-relative">
                  <img src={e.artwork} alt="artwork" className="mb-4 h-100" />
                </div>
              )}
              {e.yogaImg && (
                <div className="position-relative">
                  <img src={e.yogaImg} alt="artwork" className="mb-4 h-100" />
                </div>
              )}
              {e.yogaDescription && (
                <p className="m-0 mb-8">{e.yogaDescription}</p>
              )} */}
              {user && user.isAdmin && (
                <Box className="edit-btn">
                  <Button
                    className="mt-6"
                    variant="contained"
                    color="primary"
                    onClick={handClickEdit}
                  >
                    edit
                  </Button>
                </Box>
              )}
            </div>
          </Grid>
          {editable && (
            <Fragment>
              <Grid item xs={12}>
                <Components.Editor
                  className="mb-16"
                  value={formValue.ritual}
                  onChange={value => handleChange('ritual', value)}
                />
                <Components.Editor
                  className="mb-16"
                  value={formValue.meaning}
                  onChange={value => handleChange('meaning', value)}
                />
                <Components.Editor
                  className="mb-16"
                  value={formValue.description3}
                  onChange={value => handleChange('description3', value)}
                />
                <Components.Editor
                  className="mb-16"
                  value={formValue.description4}
                  onChange={value => handleChange('description4', value)}
                />
                <TextField
                  label="Artwork Url"
                  variant="outlined"
                  className="mb-16"
                  name="artwork"
                  fullWidth
                  placeholder="http://ssweb.com"
                  defaultValue={formValue.artwork}
                  onChange={event => handleChange('artwork', event.target.value)}
                />
                <TextField
                  label="Yoga Image Url"
                  variant="outlined"
                  className="mb-16"
                  name="yogaImg"
                  fullWidth
                  placeholder="http://ssweb.com"
                  defaultValue={formValue.yogaImg}
                  onChange={event => handleChange('yogaImg', event.target.value)}
                />
                <Components.Editor
                  className="mb-16"
                  value={formValue.yogaDescription}
                  onChange={value => handleChange('yogaDescription', value)}
                />
                <div>
                  <Button
                    className="mr-12"
                    variant="contained"
                    color="secondary"
                    onClick={() => setEditable(!editable)}
                  >
                    cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    save
                  </Button>
                </div>
              </Grid>
            </Fragment>
          )}
        </Grid>
      )}
    </div>
  );
};

export default TransitEditor;
// export default memo(TransitEditor);
