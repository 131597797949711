// import node modules
import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.core.css";

/*const CustomButton = () => <span className="octicon octicon-star" />

function insertStar () {
  const cursorPosition = this.quill.getSelection().index
  this.quill.insertText(cursorPosition, "★")
  this.quill.setSelection(cursorPosition + 1)
}

function insertCustomTags (args) {
  
  console.log("insertCustomTags", args);
  
  
  const value = args[0];
  
  const cursorPosition = this.quill.getSelection().index
  this.quill.insertText(cursorPosition, value)
  this.quill.setSelection(cursorPosition + value.length)
}

const CustomToolbar = () => (
  <div id="toolbar">
    <select className="ql-header">
      <option value="1"></option>
      <option value="2"></option>
      <option selected></option>
    </select>
    <button className="ql-bold"></button>
    <button className="ql-italic"></button>
    <select className="ql-color">
      <option value="red"></option>
      <option value="green"></option>
      <option value="blue"></option>
      <option value="orange"></option>
      <option value="violet"></option>
      <option value="#d0d1d2"></option>
      <option selected></option>
    </select>    
    <button className="ql-insertStar">
      <CustomButton />
    </button>
    
    <select className="ql-insertCustomTags">
      <option value="1">One</option>
      <option value="2">Two</option>
    </select>
  </div>
)
*/
const Editor = props => {

  const objModules = {
    clipboard: { matchVisual: false },
    toolbar: [
      [{ 'font': [] }],
      [{ 'size': [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      [{ 'color': [] }, { 'background': [] }, { 'script': 'sub' }, { 'script': 'super' }],
      ['link', 'image', 'video']
    ]
  }

  return (
    <div className={props.className}>
      {/*<CustomToolbar />*/}
      <ReactQuill modules={objModules} value={props.value} onChange={props.onChange} />
    </div>
  )
}

export default Editor;