import "./app.scss";
import React from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import history from "./history";
import Login from "./views/Login";
import TransitImporter from "./views/TransitImporter";
import TransitViewer from "./views/TransitViewer";
import AppContextProvider from "./AppContext";
import TransitEditor from "./views/TransitEditor";
import ProfileEditor from "./views/ProfileEditor";
import UserTransit from "./views/UserTransit";
import ChartUser from "./views/ChartUser";
import AstroParts from "./views/Admin/AstroParts";
import Aspects from "./views/Admin/Aspects";
import Compatibility from "./views/Admin/Compatibility";
import Values from "./views/Admin/Values";
import ImportTransits from "./views/Admin/ImportTransits";
import Users from "./views/Admin/Users";
import Register from "views/Register";
// import { createTheme } from "@material-ui/core/styles";
import ThemeProvider from "themes/ThemeProvider";
// import Auth from "./auth/Auth";
// import ChartNow from './views/ChartNow';
// import ChartNew from './views/ChartNew';

//
import { Provider } from "react-redux";
import firebase from "firebase/app";
import "firebase/auth";
import { createStore, combineReducers } from "redux";
// import { configureStore } from "@reduxjs/toolkit";
import {
  ReactReduxFirebaseProvider,
  firebaseReducer,
} from "react-redux-firebase";
import { firebaseConfig } from "firebase/firebaseConfig";
import NavHeader from "components/Header/NavHeader";
// import NavigationBar from "components/Header/NavigationBar";
import ForgotPassword from "views/ForgotPassword";
import UserTransitWrapper from "views/UserTransitWrapper";
// import { createFirestoreInstance, firestoreReducer } from 'redux-firestore' // <- needed if using firestore

// import base modules
import Contexts from "contexts";
import Utils from "utils";
import Views from "views";

// react-redux-firebase config
const rrfConfig = {
  userProfile: "users",
  // useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
};

// Initialize firebase instance
firebase.initializeApp(firebaseConfig);
firebase.auth();
firebase.database();
// Initialize other services on firebase instance
// firebase.firestore() // <- needed if using firestore
// firebase.functions() // <- needed if using httpsCallable

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  // firestore: firestoreReducer // <- needed if using firestore
});

// Create store with reducers and initial state
const initialState = {
  
};
const store = createStore(rootReducer, initialState);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  // createFirestoreInstance // <- needed if using firestore
};
//
function loadScript(src, position, id) {
  if (!position) {
    return;
  }
  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const App = () => {
  Utils.Log('[App]');

  const loaded = React.useRef(false);
  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyCfC1I-h9CHPL4N5TzLRqgzreIaNu7Gxs0&libraries=places",
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  return (
    <Provider store={store}>
      <Contexts.Provider>
        <ThemeProvider>
          <ReactReduxFirebaseProvider {...rrfProps}>
            <AppContextProvider>
              {/* <Auth> */}
              <Router history={history}>
                {/* <Topbar></Topbar> */}
                {/* <NavigationBar /> */}
                <NavHeader />
                <Switch>
                  <Route path="/" exact component={Views.Main} />
                  <Route path="/me" exact component={Views.Main} />
                  {/*<Route path="/" exact component={() => <Home tabIndex={0} mode={0} />} />*/}
                  {/*<Route path="/me" exact component={() => <Home tabIndex={1} mode={1} />} />*/}
                  <Route path="/user-chart" exact component={() => <UserTransitWrapper />} />
                  <Route path="/login" exact component={Login} />
                  <Route path="/register" exact component={Register} />
                  <Route path="/forgot-password" exact component={ForgotPassword} />
                  <Route path="/user-profile" exact component={ProfileEditor} />
                  <Route path="/transit-import" exact component={TransitImporter} />
                  <Route path="/global-transit" exact component={TransitViewer} />
                  <Route path="/my-transit" exact component={UserTransit} />
                  <Route path="/transit-edit/:id" exact component={TransitEditor} />
                  <Route path="/admin/info-objects/astro-parts" exact component={AstroParts} />
                  <Route path="/admin/info-objects/aspects" exact component={Aspects} />
                  <Route path="/admin/info-objects/Compatibility" exact component={Compatibility} />
                  <Route path="/admin/values" exact component={Values} />
                  <Route path="/admin/import-transits" exact component={ImportTransits} />
                  <Route path="/admin/users" exact component={Users} />
                  <Route path="/chart/user" exact component={ChartUser} />
                  <Route path="/chart/draw" exact component={Views.Chart} />
                  {/* <Route path="/chart/now" exact component={ChartNow} /> */}
                  {/* <Route path="/chart/new" exact component={ChartNew} /> */}
                  <Route path="**" exact render={ _ => (<Redirect to="/" />) } />
                </Switch>
              </Router>
              {/* </Auth> */}
            </AppContextProvider>
          </ReactReduxFirebaseProvider>
        </ThemeProvider>
      </Contexts.Provider>
    </Provider>
  );
}

export default App;
