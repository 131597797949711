// import node modules
import moment from "moment";

// import React modules
import * as MaterialUI from "@material-ui/core";
import * as MaterialUILab from "@material-ui/lab";
import React from "react";
import { useGeolocated } from "react-geolocated";

// import base modules
import Utils from "utils";

// import view modules
import ChartSVG from "./chart.svg";

// import { useGeolocated } from "react-geolocated";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
// // import { AppContext } from "AppContext";
// // import { useTheme } from "@material-ui/core/styles";
// import useMediaQuery from "@material-ui/core/useMediaQuery";
// import {
//   Box,
//   Button,
//   Grid,
//   TextField,
//   ButtonGroup,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
//   Collapse,
//   IconButton,
// } from "@material-ui/core";
// import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
// import { ArrowBack, ZoomIn, ZoomOut, ExpandLess } from "@material-ui/icons";
// import GoogleMaps from "./GoogleLocation";
// import axios from "axios";
// // import moment from "moment";
// import moment from "moment-timezone";
// import { URL } from "../services/constants";
import {
  // getUtcDateTime,
  // getDstTimezoneOffset,
  saveUserChart,
  // getFirebaseUserCharts,
  // updateUserProfile,
  // getTimezoneByLocation,
} from "services/dataService";
// import { GOOGLEGEOCODE_API_KEY } from "../services/constants";
import { EventChangeContext } from "./main";
// import ToggleButton from "@material-ui/lab/ToggleButton";
// import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
// import Fade from "@material-ui/core/Fade";
import { grey } from "@material-ui/core/colors";
// import {
//   filterByKeyword,
//   getTimezoneOffsetFromZoneName,
//   sortByAlphabetical,
//   sortByNewest,
// } from "services/utils";
import useApp from "hooks/useApp";
import BlankChartSVG from "../assets/svg/blankChart.svg";
import BackSVG from "../assets/svg/back.svg";
import DarkBackSVG from "../assets/svg/back-dark.svg";
import ForwardSVG from "../assets/svg/forward.svg";
import DarkForwardSVG from "../assets/svg/forward-dark.svg";
import history from "../history";
import { ThemeContext } from "themes/ThemeProvider";
// import { AppContext } from "AppContext";

// import react modules
import * as MaterialUIIcons from "@material-ui/icons";

// import base modules
import Contexts from "contexts";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: "14px !important",
    width: "100%",
    "& div": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px",
      },
      fontSize: "14px",
    },
  },
  zIndex: {
    zIndex: 0,
  },
  zoomIcon: {
    marginLeft: "14px",
    width: 20,
  },
  toggleContainer: {
    margin: theme.spacing(2, 0),
    marginTop: 0,
    display: "flex",
    justifyContent: "end",
    // paddingRight: 10,
  },
  toggleButton: {
    height: 30,
    fontSize: "0.6rem",
    textTransform: "capitalize",
    paddingLeft: 7,
    paddingRight: 7,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  toolbar: {
    maxHeight: 54,
    height: 54,
  },
  incrementButton: {
    [theme.breakpoints.down("xs")]: {
      height: 30,
      width: 40,
    },
    background: theme.palette.background.darkGrey
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: grey["300"],
    "&:hover": {
      backgroundColor: grey["400"],
    },
    // marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(0),
      width: "auto",
    },
    display: "flex",
    alignItems: "center",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    // position: "absolute",
    // pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    // paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  zoomButton: {
    fontSize: 12,
  },
  paperScrollPaper: {
    marginTop: -300,
  },
  divider: {
    backgroundColor: "#9c9797",
  },
  backButton: {
    fontSize: 12,
    [theme.breakpoints.up("md")]: {
      width: "200px",
   },
  }
}));

// const DrawChart = ({ tabIndex, mode }) => {objCurrentLocation

const Chart = () => {
  Utils.Log('[Chart]');
  
  const {
    user,
    tabState,
    handleNewChartDialog,
    handleSlideMenuOpen,
    handleOriginalUserChartChange,
    handleFilteredUserChartChange,
    handleEventModalShow,
  } = useApp();

  const {
    valMode
  } = React.useContext(Contexts.Chart);
  
  const isMobile = MaterialUI.useMediaQuery("(max-width:600px)");
  
  const [objState, setState] = React.useState({
    objCurrentLocation: null,
    objLocation: null,
    strAddress: '',
    strDate: null,
    strIncrementer: 'h',
    strTimeZone: null,
    strTitle: ''
  });

  const [isZoom, setZoom] = React.useState(false);
  const [isRender, setRender] = React.useState(false);

//   // Ask browser location allow
//   const { coords, isGeolocationAvailable, isGeolocationEnabled } =
//     useGeolocated({
//       positionOptions: {
//         enableHighAccuracy: false,
//       },
//       userDecisionTimeout: 5000,
//       onSuccess: (position) => {
//         // console.log(position.coords.latitude, position.coords.longitude);
//       },
//       onError: (error) => {
//         // User denied Geolocation
//         axios.get(`${URL}svgstring=true`).then((res) => {
//           console.log('V', 'Chart', 1);
//           setChartImage(res.data);
//         });
//       },
//     });

  const { coords: objCoords } = useGeolocated({ 
    positionOptions: { enableHighAccuracy: false },
    userDecisionTimeout: 5000
  });

  const handleUpdateCoords = () => {
    if (!objCoords) return;
    const objLocation =  {
      valLatitude: objCoords.latitude,
      valLongitude: objCoords.longitude
    }
    objState.objCurrentLocation = objLocation;
    objState.objLocation = objLocation;
  };

  const handleUpdateLocation = () => {
    if (!objState.objLocation) return;
    if (valMode === 0) {
      Utils.GEO.getAddressByLocation(objState.objLocation).then(res => {
        objState.strAddress = res;
        trigRender();
      });
    }
  };

  React.useEffect(handleUpdateCoords, [objCoords]);
  React.useEffect(handleUpdateLocation, [objState.objLocation]);

  React.useEffect(() => {
    if (valMode === 0) {
      objState.objLocation = objState.objCurrentLocation;
      objState.strTimeZone = moment.tz.guess(); 
      objState.strTitle = "NOW";
      objState.strDate = new Date().toISOString();
      trigRender();
    } else if (valMode === 1) {
      if (!user)
        return;
      console.log('User', user);

      const objLocation = { valLatitude: user.birthLocation.latitude, valLongitude: user.birthLocation.longitude };      
      objState.objLocation = objLocation;
      objState.strTitle = user.name;
      objState.strAddress = user.birthLocation.name;

      Utils.Time.getTimezoneByLocation(objLocation).then(res => {
        const strTimeZone = res;
        objState.strDate = moment.tz(user.birthDay, strTimeZone).toISOString();
        objState.strTimeZone = strTimeZone;
        trigRender();
      });
    }
  }, [user, valMode]);

  const handleChangeIncrementer = (event, value) => {
    objState.strIncrementer = value;
    trigRender();
  }

  const handleClickIncrement = value => {
    Utils.Log('Old', objState.strDate);
    objState.strDate = moment(objState.strDate).add(value, objState.strIncrementer).toISOString();
    Utils.Log('New', objState.strDate);
    
    let { dateTime } = formValue;
    setDateValueChanged(true);
    // setIncrementType(type > 0 ? "+1" : "-1");
    setFormValue((prev) => {
      return {
        ...prev,
        dateTime,
      };
    });
    handleShowIncrementLabelTimeOut();
  };
  
  const handleClickZoom = () => {
    setZoom(!isZoom);
  };

  const trigRender = () => {
    setRender(!isRender);
  };

//   const isModeUser = (mode === 1);

//   const { objSettings } = useContext(AppContext);

//   const [objDate, setDate] = useState(null);
//   const [objLocation, setLocation] = useState(null);
//   const [strTimeZone, setTimeZone] = useState('');

  const classes = useStyles();
  // const [incrementType, setIncrementType] = useState("-1");
  // const [showIncrementLabel, setShowIncrementLabel] = useState(false);
//   const [openFilterModal, setOpenFilterModal] = useState(false);
//   const [selectedFilterCriteria, setSelectedFilterCriteria] = useState(1); // 0: alphabetical,  1: newest
//   // console.log(user)
//   const [isZoomed, setIsZoomed] = useState(tabIndex === 2 ? true : false);
  const { eventState, handleEventChange, handleDateChange } = React.useContext(EventChangeContext);

//   Geocode.setApiKey(`${GOOGLEGEOCODE_API_KEY}`);
//   Geocode.setLanguage("en");
//   // set location_type filter . Its optional.
//   // google geocoder returns more that one address for given lat/lng.
//   Geocode.setLocationType("ROOFTOP");

//   // Enable or disable logs. Its optional.
//   Geocode.enableDebug();

  const [formValue, setFormValue] = React.useState({
    objDate: moment(),
    dateTime: moment().format("YYYY-MM-DDTHH:mm:ss"), //new Date(),
    address: "",
    name: "Now",
  });

//   const [userFormValue, setUserFormValue] = useState({});
  const { themeName } = React.useContext(ThemeContext);
//   const [newChartFormValue, setNewChartFormValue] = useState({
//     dateTime: moment().format("YYYY-MM-DDTHH:mm:ss"), //new Date(),
//     address: "",
//     name: "",
//   });
//   const [chartImage, setChartImage] = useState(null);
//   // chart buttons hide or show
//   const [showChartButtons, setShowChartButtons] = useState(false);
//   // show | hide chart image
//   const [showChartImage, setShowChartImage] = useState(true);
//   // check if datetime value is changed
  const [dateValueChanged, setDateValueChanged] = React.useState(false);
//   //save chart name modal
  const [open, setOpen] = React.useState(false);
  const [chartName, setChartName] = React.useState("");
//   const [userCharts, setUserCharts] = useState([]);
//   const [originalUserCharts, setOriginalUserCharts] = useState([]);
//   // save chart result alert
  // const [alertOpen, setAlertOpen] = useState(false);
  // const [alertContent, setAlertContent] = useState("");
  // const [severity, setSeverity] = useState("success");
//   const n = moment.tz(
//     new Date().toUTCString(),
//     Intl.DateTimeFormat().resolvedOptions().timeZone
//   );

//   useEffect(() => {
//     if (!objLocation)
//       return;
//     console.log('V', 'useEffect Location');
//     getTimezoneByLocation(objLocation).then(response => {
//       setDate(moment.tz(user.birthDay, response).utc().toDate());
//       setTimeZone(response);
//     });
//   }, [objLocation]);

React.useEffect(() => {  
    if (!objState.strDate)
      return;
    
    handleDateChange(objState.strDate);

//     const strUTCTime = moment(objDate).utc().format('D/M/YYYY/H/m/s');
//     let strURLChart = `${URL}n=${strUTCTime}`;
//     if (objLocation) {
//       strURLChart += `&lx=${objLocation.valLatitude}&ly=${objLocation.valLongitude}`;
//     }
//     strURLChart += '&svgstring=true';
//     strURLChart += getURLChart();
//     axios.get(strURLChart).then(response => {
//       console.log('V', 'Chart', 5, strURLChart);
//       setChartImage(response.data);
//     });
//     /*let chartUrl = "";
//       let dateTime = getUtcDateTime(
//         formValue.dateTime,
//         - new Date().getTimezoneOffset() / 60
//       );
//       let dt = `${dateTime.day}/${dateTime.month}/${dateTime.year}/${dateTime.hour}/${dateTime.minute}/${dateTime.second}`;
//       var strUTCTime = moment(formValue.dateTime).utc().format("D/M/YYYY/H/m/s");
//       if (formValue.address) {
//         const results = await geocodeByAddress(formValue.address);
//         const position = await getLatLng(results[0]);
//         const { lat, lng } = position;
//         const { timezone } = await getDstTimezoneOffset(lat, lng);

//         const offset = getTimezoneOffsetFromZoneName(
//           formValue.dateTime,
//           timezone
//         );
//         dateTime = getUtcDateTime(formValue.dateTime, offset);
//         dt = `${dateTime.day}/${dateTime.month}/${dateTime.year}/${dateTime.hour}/${dateTime.minute}/${dateTime.second}`;
//         chartUrl = `${URL}n=${strUTCTime}&lx=${lat}&ly=${lng}&svgstring=true`;
//       } else {
//         chartUrl = `${URL}n=${strUTCTime}&svgstring=true`;
//       }
//       chartUrl += getURLChart();
//       axios.get(chartUrl).then((res) => {
//         console.log('V', 'Chart', 4, chartUrl, formValue);
//         setShowChartButtons(true);
//         setChartImage(res.data);
//       });
//     }*/
   }, [objState.strDate]);

//   useEffect(() => {
//     let isApiSubscribed = true;
//     if (user && isApiSubscribed) setUserFormValue(user);

//     return () => {
//       isApiSubscribed = false;
//     };
//   }, [user]);

//   useEffect(() => {
//     let isApiSubscribed = true;
//     const geoLocation = async () => {
//       if (
//         isApiSubscribed &&
//         isGeolocationEnabled &&
//         isGeolocationAvailable &&
//         coords
//       ) {
//         // User allowed Geolocation
//         // Get formatted address, city, state, country from latitude & longitude when
//         const response = await Geocode.fromLatLng(
//           coords.latitude,
//           coords.longitude
//         );
//         const address = response.results[0].formatted_address;
//         if (address) {
//           let city, state, country;
//           for (let i = 0; i < response.results[0].address_components.length; i++) {
//             for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
//               switch (response.results[0].address_components[i].types[j]) {
//                 case "locality":
//                   city = response.results[0].address_components[i].long_name;
//                   break;
//                 case "administrative_area_level_1":
//                   state = response.results[0].address_components[i].short_name;
//                   break;
//                 case "country":
//                   country = response.results[0].address_components[i].short_name;
//                   break;
//               }
//             }
//           }
//           setFormValue((previousValue) => {
//             return {
//               ...previousValue,
//               strLocation: `${city}, ${state}, ${country}`,
//               address: address
//             };
//           });
//           const { latitude, longitude } = coords;
//           const dateTime = getUtcDateTime(
//             formValue.dateTime,
//             (await getDstTimezoneOffset(latitude, longitude)).offset
//           );
//           let dt = `${dateTime.day}/${dateTime.month}/${dateTime.year}/${dateTime.hour}/${dateTime.minute}/${dateTime.second}`;
//           let chartUrl = `${URL}n=${dt}&lx=${latitude}&ly=${longitude}&svgstring=true`;
//           axios.get(chartUrl).then((res) => {            
//             console.log('V', 'Chart', 2);
//             setShowChartButtons(true);
//             setChartImage(res.data);
//           });
//         }
//       }
//     };
//     if (tabIndex === 0) geoLocation();

//     return () => {
//       isApiSubscribed = false;
//     };
//   }, [coords]);

//   useEffect(() => {
//     // event changed on global transit or my transit view
//     const displayEventChart = async () => {
//       if (eventState.selectedEvent) {
//         console.log(`event changed: ${eventState.selectedEvent}`);
//         const { date, offset } = eventState.selectedEvent;
//         const eventDate = new Date(
//           date.year,
//           date.month - 1,
//           date.day,
//           offset ? date.hour + offset : date.hour,
//           date.minute,
//           date.second
//         );
//         console.log(`event date : ${eventDate}`);
//         setFormValue((prev) => {
//           return {
//             ...prev,
//             dateTime: moment(eventDate).format("YYYY-MM-DDTHH:mm:ss"),
//           };
//         });
//         let dt = `${date.day}/${date.month}/${date.year}/${date.hour}/${date.minute}/${date.second}`;
//         let chartUrl = "";
//         if (formValue.address) {
//           const results = await geocodeByAddress(formValue.address);
//           const position = await getLatLng(results[0]);
//           const { lat, lng } = position;
//           // const offset = await getDstTimezoneOffset(lat, lng);
//           // dateTime = getUtcDateTime(formValue.dateTime, offset);
//           // dt = `${dateTime.day}/${dateTime.month}/${dateTime.year}/${dateTime.hour}/${dateTime.minute}/${dateTime.second}`;
//           chartUrl = `${URL}n=${dt}&lx=${lat}&ly=${lng}&svgstring=true`;
//         } else {
//           chartUrl = `${URL}n=${dt}&svgstring=true`;
//         }

//         axios.get(chartUrl).then((res) => {          
//           console.log('V', 'Chart', 3);
//           setShowChartButtons(true);
//           setChartImage(res.data);
//         });
//       } else {
//         setShowChartImage(true);
//         setFormValue((prev) => {
//           return {
//             ...prev,
//             dateTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
//           };
//         });
//       }
//     };
//     displayEventChart().catch(console.error);
//   }, [eventState.selectedEvent]);

//   useEffect(() => {
//     handleSubmit();
//   }, [formValue, objSettings]);

//   // re-render when active chart changed

//   useEffect(() => {
//     if (tabIndex === 2) {
//       handleLoadSelectedChart(tabState.selectedChart);
//     }
//   }, [tabState.selectedChart]);

  React.useEffect(() => {
    if (valMode === 2) {
      objState.strTitle = tabState.selectedChart.name;
      objState.strAddress = tabState.selectedChart.address;

      geocodeByAddress(tabState.selectedChart.address).then(res => {
        getLatLng(res[0]).then(res => {
          const objLocation = { valLatitude: res.lat, valLongitude: res.lng };
          
          objState.objLocation = objLocation;
          Utils.Time.getTimezoneByLocation(objLocation).then(res => {
            const strTimeZone = res;
            objState.strDate = moment.tz(tabState.selectedChart.dateTime, strTimeZone).toISOString();
            objState.strTimeZone = strTimeZone;
            trigRender();
          });          
        });
      });
      

      /*const objLocation = { valLatitude: user.birthLocation.latitude, valLongitude: user.birthLocation.longitude };      
      objState.objLocation = objLocation;
      objState.strTitle = user.name;
      objState.strAddress = user.birthLocation.name;

      Utils.Time.getTimezoneByLocation(objLocation).then(res => {
        const strTimeZone = res;
        objState.strDate = moment.tz(user.birthDay, strTimeZone).toISOString();
        objState.strTimeZone = strTimeZone;
        trigRender();
      });*/
      
      //trigRender();
    }
  }, [valMode, tabState.selectedChart]);

//   // re-render tab changed
//   useEffect(() => {
//     const loadChartByTabIndex = async () => {
//       if (tabIndex === 1) {
//         if (user && user.birthDay && user.birthLocation && user.name) {
//           await setLocation({ valLatitude: user.birthLocation.latitude, valLongitude: user.birthLocation.longitude });
//           getAddressByLocation(user.birthLocation.latitude, user.birthLocation.longitude).then(result => {
//             setFormValue((prev) => ({
//               ...prev,
//               dateTime: user.birthDay,
//               name: user.name,
//               address: result
//             }));
//           });
//         }
//       } else if (tabIndex === 0) {
//         setFormValue((prev) => ({
//           ...prev,
//           dateTime: moment().format("YYYY-MM-DDTHH:mm:ss"), //new Date(),
//           address: "",
//           name: "Now",
//         }));
//       } else if (tabIndex === 2 && tabState.selectedChart) {
//         setFormValue((prev) => ({
//           ...prev,
//           dateTime: tabState.selectedChart.dateTime,
//           name: tabState.selectedChart.name,
//           address: tabState.selectedChart.address,
//         }));
//       }
//     };
//     loadChartByTabIndex();
//   }, [tabIndex, user]);

//   // useEffect(() => {
//   //   const loadUserCharts = async () => {
//   //     const _userCharts = await getFirebaseUserCharts(user);
//   //     console.log(_userCharts);
//   //     if (Array.isArray(_userCharts)) {
//   //       // TODO: To Delete
//   //       setUserCharts(_userCharts);
//   //       setOriginalUserCharts(_userCharts);
//   //       // --
//   //       handleFilteredUserChartChange(_userCharts);
//   //       handleOriginalUserChartChange(_userCharts);
//   //     }
//   //   };

//   //   loadUserCharts();
//   // }, [user, tabState.chartEdited]);

//   //

  const handleClickEnterBirthInfo = () => {
    console.log("signup");
    history.push("/register");
  };

//   //
//   const handleBackTodayChart = () => {
//     if (eventState.selectedEvent) {
//       handleEventChange(null);
//       handleEventModalShow(false);
//     } else if (dateValueChanged) {
//       setDateValueChanged(false);
//       setFormValue((prev) => {
//         return {
//           ...prev,
//           dateTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
//         };
//       });
//     }
//   };
//   // show/hide alert

  const handleAlertOpen = (show, content, _severity) => {
    // setAlertContent(content);
    // setAlertOpen(show);
    // setSeverity(_severity);
    // setTimeout(() => {
    //   setAlertOpen(!show);
    // }, 2000);
  };

//   // filter my charts
//   const handleSortCharts = (sort) => {
//     setSelectedFilterCriteria(sort);
//     const _userCharts = [...userCharts];
//     if (sort === 0) {
//       // TODO: to delete
//       setUserCharts(sortByAlphabetical(_userCharts));
//       // --
//       handleFilteredUserChartChange(sortByAlphabetical(_userCharts));
//     } else {
//       // TODO: to delete
//       setUserCharts(sortByNewest(_userCharts));
//       // --
//       handleFilteredUserChartChange(sortByNewest(_userCharts));
//     }
//     handleCloseFilterModal();
//     // handleDrawerClose();
//   };
//   const handleCloseFilterModal = () => {
//     setOpenFilterModal(false);
//   };

//   // new chart
//   const handleCloseNewChartDialog = () => {
//     handleNewChartDialog(false);
//   };

//   const handleSaveNewChart = async () => {
//     if (tabState.isNewChartDialog) {
//       if (!newChartFormValue.name.length) return;
//       console.log(newChartFormValue);
//       try {
//         await saveUserChart(user, { ...newChartFormValue });
//         handleCloseNewChartDialog();
//         handleDrawerClose();
//         handleSlideMenuOpen(false);
//         handleAlertOpen(true, "Successfully saved the chart.", "success");
//       } catch (error) {
//         console.log(error);
//         handleAlertOpen(true, "Error occurred in saving chart.", "error");
//       }
//     } else {
//       if (!userFormValue.name.length) return;
//       await updateUserProfile(userFormValue, user);
//       handleCloseNewChartDialog();
//       handleDrawerClose();
//       handleSlideMenuOpen(false);
//       handleAlertOpen(true, "Successfully updated your profile.", "success");
//     }
//   };
//   // save chart
  const handleChangeChartName = (e) => {
    const value = e.target.value;
    setChartName(value);
  };
  const handleClickOpen = async () => {
    /*if (
      formValue.name &&
      formValue.name.length &&
      formValue.name.toLowerCase() !== "now"
    ) {*/
    if (objState.strTitle.length && objState.strTitle.toUpperCase() !== 'NOW') {
      /* const saved = */ await handleSaveChart();
    } else setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveChart = async () => {
    if (!chartName.length) return;
    try {
      await saveUserChart(user, {
        // ...formValue,
        // name: chartName.length ? chartName : formValue.name,
        address: objState.strAddress,
        dateTime: moment(objState.strDate).format("YYYY-MM-DDTHH:mm:ss"),
        name: chartName
      });
      handleClose();
      handleAlertOpen(true, "Successfully saved the chart.", "success");
    } catch (error) {
      console.log(error);
      handleAlertOpen(true, "Error occurred in saving chart.", "error");
    }
  };
//   /**
//    *
//    * @param {*} chart selected chart
//    */
//   const handleLoadSelectedChart = (chart) => {
//     if (chart) {
//       setFormValue((prev) => ({
//         ...prev,
//         ...chart,
//       }));
//     }
//     handleDrawerClose();
//   };
//   // --- show my charts sidebar
//   const handleSearchInputChange = (e) => {
//     const val = e.target.value;
//     if (!val.length) {
//       setUserCharts(originalUserCharts);
//       handleFilteredUserChartChange(originalUserCharts);
//       return;
//     }
//     const _userCharts = [...userCharts];
//     setUserCharts(filterByKeyword(_userCharts, e.target.value));
//     handleFilteredUserChartChange(filterByKeyword(_userCharts, e.target.value));
//   };
//   const handleDrawerClose = () => {};

//   // show my charts sidebar ---

  const handleShowIncrementLabelTimeOut = () => {
    // setShowIncrementLabel(true);
    // setTimeout(() => {
    //   setShowIncrementLabel(false);
    // }, 2000);
  };

//   // increment time ---
//   const handleChange = ({ target }) => {
//     if (target.name === "name" || target.name === "dateTime") {
//       setFormValue({ ...formValue, [target.name]: target.value });
//     }
//   };

//   const handleBlur = ({ target }) => {
//     setFormValue({ ...formValue, [target.name]: target.value });
//   };

//   const handleChangeNewChartFormValue = ({ target }) => {
//     setNewChartFormValue({ ...newChartFormValue, [target.name]: target.value });
//   };

//   const handleChangeUserFormValue = ({ target }) => {
//     setUserFormValue({ ...userFormValue, [target.name]: target.value });
//   };

//   const getURLChart = () => {
//     var strURLChart = '';
//     var objPlanetIDs = [];
//     if (objSettings.planetsSun)
//       objPlanetIDs.push(0);
//     if (objSettings.planetsMoon)
//       objPlanetIDs.push(1);
//     if (objSettings.planetsMercury)
//       objPlanetIDs.push(2);
//     if (objSettings.planetsVenus)
//       objPlanetIDs.push(3);
//     if (objSettings.planetsMars)
//       objPlanetIDs.push(4);
//     if (objSettings.planetsJupiter)
//       objPlanetIDs.push(5);
//     if (objSettings.planetsSaturn)
//       objPlanetIDs.push(6);
//     if (objSettings.planetsUranus)
//       objPlanetIDs.push(7);
//     if (objSettings.planetsNeptune)
//       objPlanetIDs.push(8);
//     if (objSettings.planetsPluto)
//       objPlanetIDs.push(9);
//     if (objSettings.nodesNorthNode)
//       objPlanetIDs.push(10);
//     if (objSettings.nodesSouthNode)
//       objPlanetIDs.push(11);
//     if (objSettings.nodesNorthNodeMedian)
//       objPlanetIDs.push(30);
//     if (objSettings.nodesSouthNodeMedian)
//       objPlanetIDs.push(31);
//     if (objSettings.pointsAscendant)
//       objPlanetIDs.push('ac');
//     if (objSettings.pointsMidheaven)
//       objPlanetIDs.push('mc');
//     if (objSettings.pointsDecendant)
//       objPlanetIDs.push('dc');
//     if (objSettings.pointsIlliumcolli)
//       objPlanetIDs.push('ic');
//     if (objPlanetIDs.length)
//       strURLChart += '&planets=' + objPlanetIDs.join(',');
//     strURLChart += '&houses=' + objSettings.strHouseSystem;    
//     var strOrbs = [];
//     if (objSettings.orbsConjunction)
//       strOrbs.push(`conjunction/${objSettings.orbsConjunction}`);
//     if (objSettings.orbsOpposition)
//       strOrbs.push(`opposition/${objSettings.orbsOpposition}`);
//     if (objSettings.orbsSquare)
//       strOrbs.push(`square/${objSettings.orbsSquare}`);
//     if (objSettings.orbsTrine)
//       strOrbs.push(`trine/${objSettings.orbsTrine}`);
//     if (objSettings.orbsSextile)
//       strOrbs.push(`sextile/${objSettings.orbsSextile}`);
//     if (strOrbs.length)
//       strURLChart += '&chartorbs=' + strOrbs.join(',');
//     strURLChart += '&showminutes=' + objSettings.isShowMinutes;
//     strURLChart += '&showretrograde=' + objSettings.isShowRetrograde;
//     strURLChart += '&svgstyle=' + (themeName === 'darkTheme' ? 'dark' : 'default');
//     return strURLChart;    
//   }

//   const handleSubmit = async () => {
//     if (isModeUser && !user)
//       return;
//     if (objDate !== null) {
//       console.log('V', 'New Chart');
//     } else {
//       let chartUrl = "";
//       let dateTime = getUtcDateTime(
//         formValue.dateTime,
//         - new Date().getTimezoneOffset() / 60
//       );
//       let dt = `${dateTime.day}/${dateTime.month}/${dateTime.year}/${dateTime.hour}/${dateTime.minute}/${dateTime.second}`;
//       var strUTCTime = moment(formValue.dateTime).utc().format("D/M/YYYY/H/m/s");
//       if (formValue.address) {
//         const results = await geocodeByAddress(formValue.address);
//         const position = await getLatLng(results[0]);
//         const { lat, lng } = position;
//         const { timezone } = await getDstTimezoneOffset(lat, lng);

//         const offset = getTimezoneOffsetFromZoneName(
//           formValue.dateTime,
//           timezone
//         );
//         dateTime = getUtcDateTime(formValue.dateTime, offset);
//         dt = `${dateTime.day}/${dateTime.month}/${dateTime.year}/${dateTime.hour}/${dateTime.minute}/${dateTime.second}`;
//         chartUrl = `${URL}n=${strUTCTime}&lx=${lat}&ly=${lng}&svgstring=true`;
//       } else {
//         chartUrl = `${URL}n=${strUTCTime}&svgstring=true`;
//       }
//       chartUrl += getURLChart();
//       axios.get(chartUrl).then((res) => {
//         console.log('V', 'Chart 4', chartUrl, formValue);
//         setShowChartButtons(true);
//         setChartImage(res.data);
//       });
//     }
//   };

  // console.log('[Chart] Render', objState);

  if (valMode === 1 && !user) {
    return (
      <MaterialUI.Box order={1} className="chart-container">
                <img
                  src={BlankChartSVG}
                  alt="blank chart"
                  style={{ width: "100%", height: "100%" }}
                />
                <div className="btn-enter-birth">
                  <MaterialUI.Button
                    color={"primary"}
                    variant="contained"
                    // className="capitalize"
                    onClick={handleClickEnterBirthInfo}
                  >
                    Enter Your Birth Info
                  </MaterialUI.Button>
                </div>
              </MaterialUI.Box>
    )
  }

  //if (isMobile && !isZoomed) {

  const { 
    strAddress
  } = objState;
  
  return (<>
  {isMobile ? (
        <MaterialUI.Box component="div" style={{ font: 'Inter', fontSize: '12px' }} onClick={ isZoom ? undefined : handleClickZoom}>
          <MaterialUI.Grid container direction="column" spacing={1} alignItems="stretch" style={{ height: '100%', padding: '5px' }}>
            <MaterialUI.Grid item>
              <MaterialUI.Grid container justifyContent="space-between">
                <MaterialUI.Grid item>
                  <MaterialUI.Grid container direction="column">
                    <MaterialUI.Grid item>
                      <MaterialUI.Box component="span" style={{ fontWeight: '700', textDecorationLine: 'underline' }}>{objState.strTitle}</MaterialUI.Box>
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item>
                      <MaterialUI.Box component="span">{objState.strDate && objState.strTimeZone && (moment(objState.strDate).tz(objState.strTimeZone).format('MMM D'))}</MaterialUI.Box>
                      <MaterialUI.Box component="span" style={{ fontSize: '9px' }}>{objState.strDate && objState.strTimeZone && (moment(objState.strDate).tz(objState.strTimeZone).format(' YYYY'))}</MaterialUI.Box>
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item>
                      <MaterialUI.Box component="span" style={{ fontSize: '15px' }}>{objState.strDate && objState.strTimeZone && (moment(objState.strDate).tz(objState.strTimeZone).format('h:ma'))}</MaterialUI.Box>
                    </MaterialUI.Grid>
                  </MaterialUI.Grid>
                </MaterialUI.Grid>
                <MaterialUI.Grid item>
                  <MaterialUI.Box component="span" style={{ textDecorationLine: 'underline' }}>{strAddress}</MaterialUI.Box>
                </MaterialUI.Grid>
              </MaterialUI.Grid>
            </MaterialUI.Grid>
            <MaterialUI.Grid item container justifyContent="center" alignItems="center" style={{ marginTop: '-40px' }}>
              <MaterialUI.Grid item style={{ height: ( isZoom ? '380px' : '200px') }}>
                <ChartSVG width={ isZoom ? '380px' : '200px' } date={objState.strDate} location={objState.objLocation} />
              </MaterialUI.Grid>
            </MaterialUI.Grid>
            { isZoom && (
              <>
                <MaterialUI.Grid item>
                  <MaterialUI.Button variant="outlined" onClick={handleClickOpen}>Save</MaterialUI.Button>
                </MaterialUI.Grid>
                <MaterialUI.Grid item style={{ width: "100%" }}>
                  <MaterialUI.Grid container justifyContent="space-between">
                    <MaterialUI.Grid item>
                      <MaterialUILab.ToggleButtonGroup value={objState.strIncrementer} exclusive onChange={handleChangeIncrementer}>
                        <MaterialUILab.ToggleButton value="m" classes={{ root: classes.toggleButton }}>Min</MaterialUILab.ToggleButton>
                        <MaterialUILab.ToggleButton value="h" classes={{ root: classes.toggleButton }}>Hour</MaterialUILab.ToggleButton>
                        <MaterialUILab.ToggleButton value="d" classes={{ root: classes.toggleButton }}>Day</MaterialUILab.ToggleButton>
                        <MaterialUILab.ToggleButton value="w" classes={{ root: classes.toggleButton }}>Week</MaterialUILab.ToggleButton>
                      </MaterialUILab.ToggleButtonGroup>
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item>
                      <MaterialUI.ButtonGroup variant="contained" color="default">
                        <MaterialUI.Button className={classes.incrementButton} onClick={() => handleClickIncrement(-1)}>
                          <MaterialUIIcons.ArrowBackIos />
                        </MaterialUI.Button>
                        <MaterialUI.Button className={classes.incrementButton} onClick={() => handleClickIncrement(1)}>
                          <MaterialUIIcons.ArrowForwardIos />
                        </MaterialUI.Button>
                      </MaterialUI.ButtonGroup>
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item>
                      <MaterialUI.Button onClick={handleClickZoom}>
                        <MaterialUIIcons.ExpandLess />
                      </MaterialUI.Button>
                    </MaterialUI.Grid>
                  </MaterialUI.Grid>
                </MaterialUI.Grid>
              </>
            )}
          </MaterialUI.Grid>
        </MaterialUI.Box>
      /*(
      <MaterialUI.Box component="div" height="400px" style={{ font: 'Inter', fontSize: '12px' }} onClick={handleClickZoom}>
        <MaterialUI.Grid container direction="column" spacing={1} alignItems="stretch" justifyContent="space-between" style={{ height: '100%', padding: '5px' }}>
          <MaterialUI.Grid item>
            <MaterialUI.Grid container justifyContent="space-between">
              <MaterialUI.Grid item>
                <MaterialUI.Grid container direction="column">
                  <MaterialUI.Grid item>
                    <MaterialUI.Box component="span" style={{ textDecorationLine: 'underline' }}>{objState.strTitle}</MaterialUI.Box>
                  </MaterialUI.Grid>
                  <MaterialUI.Grid item>
                    <MaterialUI.Box component="span">{objState.strDate && objState.strTimeZone && (moment(objState.strDate).tz(objState.strTimeZone).format('MMM D'))}</MaterialUI.Box>
                    <MaterialUI.Box component="span" style={{ fontSize: '9px' }}>{objState.strDate && objState.strTimeZone && (moment(objState.strDate).tz(objState.strTimeZone).format(' YYYY'))}</MaterialUI.Box>
                  </MaterialUI.Grid>
                  <MaterialUI.Grid item>
                    <MaterialUI.Box component="span" style={{ fontSize: '15px' }}>{objState.strDate && objState.strTimeZone && (moment(objState.strDate).tz(objState.strTimeZone).format('h:ma'))}</MaterialUI.Box>
                  </MaterialUI.Grid>
                </MaterialUI.Grid>
              </MaterialUI.Grid>
              <MaterialUI.Grid item>
                <MaterialUI.Box component="span" style={{ textDecorationLine: 'underline' }}>{strAddress}</MaterialUI.Box>
              </MaterialUI.Grid>
            </MaterialUI.Grid>
          </MaterialUI.Grid>
          <MaterialUI.Grid item container justifyContent="center" alignItems="center" style={{ flexGrow: 1, marginTop: '-50px' }}>
            <MaterialUI.Grid item xs={6}>
              <ChartSVG date={objState.strDate} location={objState.objLocation} />
            </MaterialUI.Grid>
          </MaterialUI.Grid>
        </MaterialUI.Grid>
      </MaterialUI.Box>
    )*/
  ) : (
    <MaterialUI.Box component="div" height="100%" style={{ font: 'Inter', fontSize: '12px' }}>
      <MaterialUI.Grid container direction="column" spacing={1} alignItems="stretch" justifyContent="space-between" style={{ height: '100%', padding: '10px' }} >
        <MaterialUI.Grid item>
          <MaterialUI.Grid container justifyContent="space-between">
            <MaterialUI.Grid item>
              <MaterialUI.Grid container direction="column">
                <MaterialUI.Grid item>
                  <MaterialUI.Box component="span" style={{ fontWeight: '700', textDecorationLine: 'underline' }}>{objState.strTitle}</MaterialUI.Box>
                </MaterialUI.Grid>
                <MaterialUI.Grid item>
                  <MaterialUI.Box component="span">{objState.strDate && objState.strTimeZone && (moment(objState.strDate).tz(objState.strTimeZone).format('MMM D'))}</MaterialUI.Box>
                  <MaterialUI.Box component="span" style={{ fontSize: '9px' }}>{objState.strDate && objState.strTimeZone && (moment(objState.strDate).tz(objState.strTimeZone).format(' YYYY'))}</MaterialUI.Box>
                </MaterialUI.Grid>
                <MaterialUI.Grid item>
                  <MaterialUI.Box component="span" style={{ fontSize: '15px' }}>{objState.strDate && objState.strTimeZone && (moment(objState.strDate).tz(objState.strTimeZone).format('h:ma'))}</MaterialUI.Box>
                </MaterialUI.Grid>
              </MaterialUI.Grid>
            </MaterialUI.Grid>
            <MaterialUI.Grid item>
              <MaterialUI.Box component="span" style={{ textDecorationLine: 'underline' }}>{strAddress}</MaterialUI.Box>
            </MaterialUI.Grid>
          </MaterialUI.Grid>
        </MaterialUI.Grid>
        <MaterialUI.Grid item container justifyContent="center" alignItems="center" style={{ flexGrow: 1 }}>
          <MaterialUI.Grid item lg={10} xl={8}>
            <ChartSVG date={objState.strDate} location={objState.objLocation} />
          </MaterialUI.Grid>
        </MaterialUI.Grid>
        <MaterialUI.Grid item>
          <MaterialUI.Button variant="outlined" onClick={handleClickOpen}>Save</MaterialUI.Button>
        </MaterialUI.Grid>
        <MaterialUI.Grid item>
          <MaterialUI.Grid container justifyContent="space-between">
            <MaterialUI.Grid item>
              <MaterialUILab.ToggleButtonGroup exclusive value={objState.strIncrementer} onChange={handleChangeIncrementer}>
                <MaterialUILab.ToggleButton value="m" classes={{ root: classes.toggleButton }}>Min</MaterialUILab.ToggleButton>
                <MaterialUILab.ToggleButton value="h" classes={{ root: classes.toggleButton }}>Hour</MaterialUILab.ToggleButton>
                <MaterialUILab.ToggleButton value="d" classes={{ root: classes.toggleButton }}>Day</MaterialUILab.ToggleButton>
                <MaterialUILab.ToggleButton value="w" classes={{ root: classes.toggleButton }}>Week</MaterialUILab.ToggleButton>
              </MaterialUILab.ToggleButtonGroup>
            </MaterialUI.Grid>
            <MaterialUI.Grid item>
              <MaterialUI.ButtonGroup variant="contained" color="default">
                <MaterialUI.Button className={classes.incrementButton} onClick={() => handleClickIncrement(-1)}>
                  <MaterialUIIcons.ArrowBackIos />
                </MaterialUI.Button>
                <MaterialUI.Button className={classes.incrementButton} onClick={() => handleClickIncrement(1)}>
                  <MaterialUIIcons.ArrowForwardIos />
                </MaterialUI.Button>
              </MaterialUI.ButtonGroup>
            </MaterialUI.Grid>
          </MaterialUI.Grid>
        </MaterialUI.Grid>
      </MaterialUI.Grid>
    </MaterialUI.Box>
  )}
  
      <MaterialUI.Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth={"sm"}
        fullWidth
        classes={{
          paper: isMobile ? classes.paperScrollPaper : "",
        }}
      >
        <MaterialUI.DialogTitle id="form-dialog-title">Save Chart</MaterialUI.DialogTitle>
        <MaterialUI.DialogContent>
          <MaterialUI.TextField
            autoFocus
            margin="dense"
            id="name"
            label="Chart Name"
            type="text"
            fullWidth
            value={chartName}
            onChange={handleChangeChartName}
          />
        </MaterialUI.DialogContent>
        <MaterialUI.DialogActions>
          <MaterialUI.Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </MaterialUI.Button>
          <MaterialUI.Button variant="contained" onClick={handleSaveChart} color="primary">
            Save
          </MaterialUI.Button>
        </MaterialUI.DialogActions>
      </MaterialUI.Dialog>
      </>
    );
  
//   return (
//     <>
//       <Collapse in={alertOpen}>
//         <Alert variant="filled" severity={severity}>
//           {alertContent}
//         </Alert>
//       </Collapse>

//       {/* <Box>
//         <Grid
//           container
//           justifyContent="space-between"
//           direction="row"
//           alignItems="center"
//           className="p-1 b-b-1 h-54"
//         >
//           <Grid item className="b-1">
//             <Button onClick={toggleDrawer("right", true)}>
//               My Charts &nbsp;
//               <MenuIcon />
//             </Button>
//           </Grid>
//           <Grid item className=""></Grid>
//         </Grid>
//         <Drawer
//           anchor={"right"}
//           open={showMyChartsSidebar}
//           onClose={toggleDrawer("right", false)}
//         >
//           {list("right")}
//         </Drawer>
//       </Box> */}
//       <Box
//         className={
//           isZoomed ? "draw-form p-16" : "draw-form draw-chart-form p-16"
//         }
//         onClick={() => { isZoomed === false && setIsZoomed(true) }}
//       >
//         {tabIndex === 1 && !user && isMobile ? (
//           <></>
//         ) : ((!isMobile || isZoomed) ? (
//           <Box order={2} className={isZoomed ? "" : `draw-chart-input-group`}>
//             <Grid
//               container
//               justifyContent="space-between"
//               direction="row"
//               alignItems="baseline"
//               spacing={2}
//             >
//               {eventState.selectedEvent || dateValueChanged ? (
//                 <Grid item xs={!isZoomed ? 12 : 5} sm={4} lg={2}>
//                   <Button
//                     classes={{ root: classes.backButton }}
//                     onClick={handleBackTodayChart}
//                   >
//                     <ArrowBack color="secondary" />
//                     Back to chart of Now
//                   </Button>
//                 </Grid>
//               ) : (
//                 <Grid item xs={!isZoomed ? 12 : 2} sm={4} lg={2}>
//                   <TextField
//                     name="name"
//                     label=""
//                     inputProps={{
//                       min: 0,
//                       style: {
//                         textAlign: isMobile && !isZoomed ? "right" : "left",
//                       },
//                     }}
//                     className={`${classes.root}`}
//                     fullWidth={false}
//                     size="small"
//                     value={formValue.name}
//                     onChange={handleChange}
//                   />
//                 </Grid>
//               )}
//               <Grid item xs={!isZoomed ? 12 : 10} sm={8}>
//                 <Grid
//                   container
//                   justifyContent="space-between"
//                   alignItems="center"
//                   spacing={1}
//                   wrap={isMobile ? (isZoomed ? "nowrap" : "wrap") : "nowrap"}
//                 >
//                   <Grid item xs={12} style={{ paddingBottom: 0 }}>
//                     <TextField
//                       type="datetime-local"
//                       size="small"
//                       name="dateTime"
//                       className={`${classes.root} ${classes.zIndex}`}
//                       value={formValue.dateTime}
//                       onChange={({ target }) => {
//                         console.log(target);
//                         handleChange({
//                           target: { name: target.name, value: target.value },
//                         });
//                         handleDateChange(target.value);
//                       }}
//                     />
//                   </Grid>
//                   <Grid item xs={12}>
//                     <GoogleMaps
//                       onAddressChange={handleBlur}
//                       address={formValue.address}
//                       className={`${classes.root}`}
//                       label=""
//                       onAutocompleteChange={() => {}}
//                     />
//                   </Grid>

//                   {/* {!isZoomed && (
//                     <Grid className="zoom-btn-container">
//                       <Button
//                         onClick={handleZoom}
//                         classes={{
//                           root: classes.zoomButton,
//                         }}
//                       >
//                         Zoom
//                         <img
//                           src={SvgZoom}
//                           alt="zoom"
//                           className={`${classes.zoomIcon}`}
//                         />
//                       </Button>
//                     </Grid>
//                   )} */}
//                   {isMobile && !isZoomed && (
//                     <Grid item xs={12} className="">
//                       <div className={classes.toggleContainer}>
//                         <ToggleButtonGroup
//                           value={objState.strIncrementer}
//                           exclusive
//                           onChange={handleAlignment}
//                           aria-label="text alignment"
//                         >
//                           <ToggleButton
//                             value="min"
//                             aria-label="Minutes"
//                             classes={{
//                               root: classes.toggleButton,
//                             }}
//                           >
//                             Min
//                           </ToggleButton>
//                           <ToggleButton
//                             value="hour"
//                             aria-label="Hour"
//                             classes={{
//                               root: classes.toggleButton,
//                             }}
//                           >
//                             Hr
//                           </ToggleButton>
//                           <ToggleButton
//                             value="day"
//                             aria-label="Day"
//                             classes={{
//                               root: classes.toggleButton,
//                             }}
//                           >
//                             Day
//                           </ToggleButton>
//                           <ToggleButton
//                             value="week"
//                             aria-label="Week"
//                             classes={{
//                               root: classes.toggleButton,
//                             }}
//                           >
//                             Wk
//                           </ToggleButton>
//                         </ToggleButtonGroup>
//                       </div>
//                       <div className={classes.toggleContainer}>
//                         <ButtonGroup variant="contained" color="default">
//                           <Button
//                             className={classes.incrementButton}
//                             onClick={() => handleClickIncrement(-1)}
//                           >
//                             <img
//                               src={themeName === "lightTheme" ? BackSVG : DarkBackSVG}
//                               alt="back button"
//                               style={{ width: "12px", height: "20px" }}
//                             />
//                           </Button>
//                           <Button
//                             className={classes.incrementButton}
//                             onClick={() => handleClickIncrement(1)}
//                           >
//                             <img
//                               src={themeName === "lightTheme" ? ForwardSVG : DarkForwardSVG}
//                               alt="forward button"
//                               style={{ width: "12px", height: "20px" }}
//                             />
//                           </Button>
//                         </ButtonGroup>
//                       </div>
//                     </Grid>
//                   )}
//                 </Grid>
//               </Grid>
//             </Grid>
//           </Box>
//         ) : (
//           <>
//             <Box order={1} style={{fontFamily: 'Inter', fontSize: '12px', width: '20%'}}>
//               <Grid container direction="column">
//                 <Grid item>
//                   <Box component="span" style={{textDecorationLine: 'underline'}}>NOW</Box>
//                 </Grid>
//                 <Grid item>
//                   <Box component="span">{ formValue.objDate.format('MMM D ') }</Box>
//                   <Box component="span" style={{fontSize: '9px'}}>{ formValue.objDate.format('YYYY') }</Box>
//                 </Grid>
//                 <Grid item>
//                   <Box component="span" style={{fontSize: '15px'}}>{ formValue.objDate.format('H:ma') }</Box>
//                 </Grid>
//               </Grid>
//             </Box>
//             <Box order={3} style={{fontFamily: 'Inter', fontSize: '12px', width: '20%'}}>
//               <Grid container>
//                 <Grid item>
//                   <Box component="span" style={{textDecorationLine: 'underline'}}>{ formValue.strLocation }</Box>
//                 </Grid>
//               </Grid>
//             </Box>
//           </>
//         ))}

//         {showChartImage &&
//           (tabIndex === 1 ? (
//             user ? (
//               <Box order={1} className="chart-container">
//                 <div className="chart-wrapper" style={{ position: "relative" }}>
//                   <div dangerouslySetInnerHTML={{ __html: chartImage }}></div>
//                 </div>
//                 <Fade
//                   in={showIncrementLabel}
//                   timeout={{
//                     appear: 30,
//                     enter: 0,
//                     exit: 1000,
//                   }}
//                 >
//                   <span className="increment-led-label">
//                     {incrementType} {objState.strIncrementer}
//                   </span>
//                 </Fade>
//               </Box>
//             ) : (
//               <Box order={1} className="chart-container">
//                 <img
//                   src={BlankChartSVG}
//                   alt="blank chart"
//                   style={{ width: "100%", height: "100%" }}
//                 />
//                 <div className="btn-enter-birth">
//                   <Button
//                     color={"primary"}
//                     variant="contained"
//                     // className="capitalize"
//                     onClick={handleClickEnterBirthInfo}
//                   >
//                     Enter Your Birth Info
//                   </Button>
//                 </div>
//               </Box>
//             )
//           ) : (
//             <Box order={1} className="chart-container">
//               <div className="chart-wrapper" style={{ position: "relative" }}>
//                 <div dangerouslySetInnerHTML={{ __html: chartImage }}></div>
//               </div>

//               <Fade
//                 in={showIncrementLabel}
//                 timeout={{
//                   appear: 30,
//                   enter: 0,
//                   exit: 1000,
//                 }}
//               >
//                 <span className="increment-led-label">
//                   {incrementType} {objState.strIncrementer}
//                 </span>
//               </Fade>
//             </Box>
//           ))}
//       </Box>
//       <Grid
//         container
//         direction="row"
//         justifyContent="space-between"
//         alignItems="flex-start"
//         spacing={1}
//         className={isMobile ? "p-8" : "p-8 p-t-10vh"}
//       >
//         {isMobile ? (
//           <>
//             {isZoomed && (
//               <>
//                 <Grid
//                   item
//                   xs={6}
//                   style={{ display: "flex", justifyContent: "start" }}
//                 >
//                   <Button variant="outlined" onClick={handleClickOpen}>
//                     Save
//                   </Button>
//                 </Grid>
//                 <Grid item style={{ width: "100%" }}>
//                   <Grid
//                     container
//                     direction="row"
//                     wrap="nowrap"
//                     justifyContent="space-between"
//                     alignItems="center"
//                   >
//                     <div>
//                       <ToggleButtonGroup
//                         value={objState.strIncrementer}
//                         exclusive
//                         onChange={handleAlignment}
//                         aria-label="text alignment"
//                       >
//                         <ToggleButton
//                           value="min"
//                           aria-label="Minute"
//                           classes={{
//                             root: classes.toggleButton,
//                           }}
//                         >
//                           Min
//                         </ToggleButton>
//                         <ToggleButton
//                           value="hour"
//                           aria-label="Hour"
//                           classes={{
//                             root: classes.toggleButton,
//                           }}
//                         >
//                           Hour
//                         </ToggleButton>
//                         <ToggleButton
//                           value="day"
//                           aria-label="right aligned"
//                           classes={{
//                             root: classes.toggleButton,
//                           }}
//                         >
//                           Day
//                         </ToggleButton>
//                         <ToggleButton
//                           value="week"
//                           aria-label="justified"
//                           classes={{
//                             root: classes.toggleButton,
//                           }}
//                         >
//                           Week
//                         </ToggleButton>
//                       </ToggleButtonGroup>
//                     </div>
//                     <div>
//                       <ButtonGroup variant="contained" color="default">
//                         <Button
//                           className={classes.incrementButton}
//                           onClick={() => handleClickIncrement(-1)}
//                         >
//                           <img
//                             src={themeName === "lightTheme" ? BackSVG : DarkBackSVG}
//                             alt="back button"
//                             style={{ width: "12px", height: "20px" }}
//                           />
//                         </Button>
//                         <Button
//                           className={classes.incrementButton}
//                           onClick={() => handleClickIncrement(1)}
//                         >
//                           <img
//                             src={themeName === "lightTheme" ? ForwardSVG : DarkForwardSVG}
//                             alt="back button"
//                             style={{ width: "12px", height: "20px" }}
//                           />
//                         </Button>
//                       </ButtonGroup>
//                     </div>
//                     <IconButton onClick={handleZoom}>
//                       <ExpandLess />
//                     </IconButton>
//                   </Grid>
//                 </Grid>
//               </>
//             )}
//           </>
//         ) : (
//           <>
//             <Grid
//               item
//               xs={6}
//               style={{
//                 display: "flex",
//                 justifyContent: "flex-start",
//                 alignItems: "flex-start",
//               }}
//             >
//               <Button variant="outlined" onClick={handleClickOpen}>
//                 Save
//               </Button>
//             </Grid>
//             <Grid
//               item
//               xs={6}
//               style={{ display: "flex", justifyContent: "end" }}
//             >
//               <Grid item style={{ marginRight: "16px" }}>
//                 <div className={classes.toggleContainer}>
//                   <ToggleButtonGroup
//                     value={objState.strIncrementer}
//                     exclusive
//                     onChange={handleAlignment}
//                     aria-label="text alignment"
//                   >
//                     <ToggleButton
//                       value="min"
//                       aria-label="Minute"
//                       classes={{
//                         root: classes.toggleButton,
//                       }}
//                     >
//                       Min
//                     </ToggleButton>
//                     <ToggleButton
//                       value="hour"
//                       aria-label="Hour"
//                       classes={{
//                         root: classes.toggleButton,
//                       }}
//                     >
//                       Hour
//                     </ToggleButton>
//                     <ToggleButton
//                       value="day"
//                       aria-label="right aligned"
//                       classes={{
//                         root: classes.toggleButton,
//                       }}
//                     >
//                       Day
//                     </ToggleButton>
//                     <ToggleButton
//                       value="week"
//                       aria-label="justified"
//                       classes={{
//                         root: classes.toggleButton,
//                       }}
//                     >
//                       Week
//                     </ToggleButton>
//                   </ToggleButtonGroup>
//                 </div>
//                 <div className={classes.toggleContainer}>
//                   <ButtonGroup variant="contained" color="default">
//                     <Button
//                       className={classes.incrementButton}
//                       onClick={() => handleClickIncrement(-1)}
//                     >
//                       <img
//                         src={themeName === "lightTheme" ? BackSVG : DarkBackSVG}
//                         alt="back button"
//                         style={{ width: "12px", height: "20px" }}
//                       />
//                     </Button>
//                     <Button
//                       className={classes.incrementButton}
//                       onClick={() => handleClickIncrement(1)}
//                     >
//                       <img
//                         src={themeName === "lightTheme" ? ForwardSVG : DarkForwardSVG}
//                         alt="back button"
//                         style={{ width: "12px", height: "20px" }}
//                       />
//                     </Button>
//                   </ButtonGroup>
//                 </div>
//               </Grid>
//             </Grid>
//           </>
//         )}
//       </Grid>
//       {/* <Dialog
//         open={tabState.newChartDialogOpen}
//         onClose={handleCloseNewChartDialog}
//         aria-labelledby="form-dialog-title"
//         maxWidth={"sm"}
//         fullWidth
//         classes={{
//           paper: isMobile ? classes.paperScrollPaper : "",
//         }}
//       >
//         <DialogTitle id="form-dialog-title">
//           {tabState.isNewChartDialog ? "New Chart" : "Edit Your Profile"}
//         </DialogTitle>
//         <DialogContent>
//           <DialogContentText></DialogContentText>
//           <TextField
//             autoFocus
//             margin="dense"
//             id="name"
//             name="name"
//             label=""
//             placeholder="Chart name"
//             type="text"
//             fullWidth
//             value={
//               tabState.isNewChartDialog
//                 ? newChartFormValue.name
//                 : userFormValue.name
//             }
//             className={`mb-16 ${classes.root}`}
//             onChange={
//               tabState.isNewChartDialog
//                 ? handleChangeNewChartFormValue
//                 : handleChangeUserFormValue
//             }
//           />
//           <GoogleMaps
//             onAddressChange={
//               tabState.isNewChartDialog
//                 ? handleChangeNewChartFormValue
//                 : handleChangeUserFormValue
//             }
//             address={
//               tabState.isNewChartDialog
//                 ? newChartFormValue.address
//                 : userFormValue.birthLocation
//                 ? userFormValue.birthLocation.name
//                 : ""
//             }
//             className={`mb-16 ${classes.root}`}
//             label=""
//             onAutocompleteChange={() => {}}
//           />
//           <TextField
//             type="datetime-local"
//             size="small"
//             name="dateTime"
//             className={`mb-16 ${classes.root} input-datetime`}
//             value={
//               tabState.isNewChartDialog
//                 ? newChartFormValue.dateTime
//                 : moment(userFormValue.birthDay).format("YYYY-MM-DDTHH:mm:ss")
//             }
//             onChange={({ target }) => {
//               console.log(target);
//               if (tabState.isNewChartDialog) {
//                 handleChangeNewChartFormValue({
//                   target: { name: target.name, value: target.value },
//                 });
//               } else {
//                 handleChangeUserFormValue({
//                   target: { name: "birthDay", value: target.value },
//                 });
//               }
//             }}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button
//             variant="contained"
//             onClick={handleCloseNewChartDialog}
//             color="primary"
//           >
//             Cancel
//           </Button>
//           <Button
//             variant="contained"
//             onClick={handleSaveNewChart}
//             color="primary"
//           >
//             Save
//           </Button>
//         </DialogActions>
//       </Dialog> */}
//     </>
//   );
};

export default Chart;
