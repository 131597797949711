/* eslint-disable array-callback-return */
import axios from "axios";
import firebase from "../firebase/firebaseConfig";
import { DateTime } from "luxon";
import {
  IPGEOLOCATION_API_BASE_URL,
  IPGEOLOCATION_API_KEY,
  GOOGLEGEOCODE_API_KEY,
} from "./constants";
import { URL } from "./constants";
import moment from "moment";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";

// import base modules
import Utils from "utils";

const refEvent = "/global/events";
let updatedEventList = [];

const queryString = (params) => {
  return Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
};

const cleanApiData = (s) => {
  let cleanData1 = s
    .replace(/\n/g, "")
    .replace(/\r/g, "")
    .replace(/\t/g, "")
    .replace(/'/g, '"')
    .replace(/\s\s+/g, "");

  let jsonStr = cleanData1.replace(/(\w+:)|(\w+ :)/g, function (matchedStr) {
    return '"' + matchedStr.substring(0, matchedStr.length - 1) + '":';
  });

  return JSON.parse(jsonStr);
};

const cleanSvgData = (svgData) => {
  const key = "<svg ";
  let split = svgData.split(key);
  split.shift();
  return split.map((x) => key + x);
};

const buildSvgDict = (svgList) => {
  const pattern = new RegExp('id=".*?transit-(.*?)"');
  return svgList.reduce(function (obj, x) {
    try {
      let id = x.match(pattern)[1];
      obj[id] = x;
      return obj;
    } catch (e) {
      // console.log(e);
    }
  }, {});
};

export const getGlobalEvents = async (data) => {
  let { ts, te } = data;
  ts = new Date(ts).toLocaleDateString("en-GB");
  te = new Date(te).toLocaleDateString("en-GB");

  let params = {
    ts: ts,
    te: te,
    json: true,
    svgstring: false,
  };

  return axios.get(`${URL}${queryString(params)}`);
};

export const getUserEvents = async (user, svgStringOption = false) => {
  let {
    birthLocation,
    date: { utc },
  } = user;

  let bday = `${utc.day}/${utc.month}/${utc.year}/${utc.hour}/${utc.minute}/${utc.second}`;
  let ts = new Date();
  let te = new Date();

  ts.setDate(ts.getDate() - 30);
  te.setDate(te.getDate() + 30);

  ts = ts.toLocaleDateString("en-GB");
  te = te.toLocaleDateString("en-GB");

  let params = {
    n: bday,
    ts: ts,
    te: te,
    json: true,
    lx: birthLocation.latitude,
    ly: birthLocation.longitude,
    svgstring: svgStringOption,
  };

  return axios.get(`${URL}${queryString(params)}`);
};

export const setEvents = async (formData) => {
  let { data } = await getGlobalEvents(formData);
  updatedEventList = [];

  let eventList = eval(data);
  // console.log(`Non filtered count :=:=: ${eventList.length} `);

  // Filter duplicate event by second
  // Duplicated event will be removed and save to Firebase
  const filteredEvents = eventList.filter(
    (e, index, self) =>
      index ===
      self.findIndex(
        (t) =>
          Math.floor(t.date.second) === Math.floor(e.date.second) &&
          t.date.year === e.date.year &&
          t.date.month === e.date.month &&
          t.date.day === e.date.day &&
          t.date.hour === e.date.hour &&
          t.date.minute === e.date.minute
      )
  );

  // console.log(`filtered count :=:=: ${filteredEvents.length} `);

  let update = {};

  let existingEventList = await getFirebaseGlobalEvents();

  filteredEvents.map((event) => {
    let {
      d,
      date: { day, month, year, hour, minute, second },
    } = event;
    let child = d.toString().replace(".", "");

    // let eventTime = `${year}-${month.toString().padStart(2, "0")}-${day
    //   .toString()
    //   .padStart(2, "0")}`;
    // event.eventTime = eventTime;
    const eventDate = new Date(
      Date.UTC(year, month - 1, day, hour, minute, second)
    );

    event.eventTime = eventDate.getTime();

    if (existingEventList && existingEventList[child]) return;
    updatedEventList.push(event);
    update[`/${child}`] = event;
  });

  return new Promise((resolve, reject) => {
    firebase
      .database()
      .ref(refEvent)
      .update(update)
      .then((data) => {
        alert("Transit imported successfully!");
        resolve(updatedEventList);
      });
  });
};

export const setUserEvents = async (user, svgStringOption = false) => {
  if (!user) return;
  if (!user.birthLocation || !user.date) return;

  let { data } = await getUserEvents(user, svgStringOption);
  if (!data) return;

  let eventList = null;
  let svgList = null;
  let svgDict = null;
  let split = null;

  // let data1 = data[0] + "}"
  let data2 = null;
  let svgData = null;

  //SVG string required
  if (svgStringOption) {
    data = data.split("}\n[");
    split = data[1].split("]\n<");

    data2 = "[" + split[0] + "]";
    svgData = "<" + split[1];

    // let userInfo = cleanApiData(data1)
    eventList = cleanApiData(data2);
    svgList = cleanSvgData(svgData);
    svgDict = buildSvgDict(svgList);
  } else {
    //SVG string not required
    data = data.split("}\n[");
    data2 = "[" + data[1];
    // let userInfo = cleanApiData(data1)
    eventList = cleanApiData(data2);
  }

  //////

  // Filter duplicate event by second
  // Duplicated event will be removed and save to Firebase
  const filteredEvents = eventList.filter(
    (e, index, self) =>
      index ===
      self.findIndex(
        (t) =>
          Math.floor(t.date.second) === Math.floor(e.date.second) &&
          t.date.year === e.date.year &&
          t.date.month === e.date.month &&
          t.date.day === e.date.day &&
          t.date.hour === e.date.hour &&
          t.date.minute === e.date.minute
      )
  );

  // console.log(`filtered count :=:=: ${filteredEvents.length} `);

  //////
  // let data1 = data[0] + "}"

  let updatedEventList = [];

  let update = {};
  let existingEventList = await getFirebaseUserEvents(user);
  let blacklist = [];

  filteredEvents.map((event) => {
    let {
      d,
      date: { day, month, year, hour, minute, second },
    } = event;

    let child = d.toString().replace(".", "");

    try {
      // note: we are only using the first 4 decimal places of `d`
      let split = d.toString().split(".");
      let eventId = split[0] + split[1].slice(0, 4);
      if (blacklist.indexOf(eventId) >= 0) {
        return; // item is a duplicate
      }
      blacklist.push(eventId);
    } catch (e) {
      // console.log(e);
      return;
    }

    // let eventTime = `${year}-${month.toString().padStart(2, "0")}-${day
    //   .toString()
    //   .padStart(2, "0")}`;
    const eventDate = new Date(
      Date.UTC(year, month - 1, day, hour, minute, second)
    );
    event.eventTime = eventDate.getTime();

    // attach matching svg object to event
    try {
      // NOTE: id is composed this way: date.day-date.month-date.year-date.hour-date.minute-date.second
      let eventId = [
        day,
        month,
        year,
        hour,
        minute,
        second.toString().split(".")[0],
      ].join("-");
      if (svgStringOption) {
        event.svg = svgDict[eventId];
      }
    } catch (e) {
      // console.log(e);
    }

    if (existingEventList && existingEventList[child]) {
      return;
    }

    updatedEventList.push(event);
    update[`/${child}`] = event;
  });

  return new Promise((resolve, reject) => {
    firebase
      .database()
      .ref(`/users/${user.uid}/events`)
      .update(update)
      .then((data) => {
        // console.log('updated');
        resolve(updatedEventList);
      });
  });
};

const getFirebaseGlobalEvents = () => {
  return new Promise((resolve, reject) => {
    firebase
      .database()
      .ref(refEvent)
      .once("value", (snap) => {
        resolve(snap.val());
      });
  });
};

export const deleteUserEvents = (user) => {
  return new Promise((resolve, reject) => {
    firebase
      .database()
      .ref(`/users/${user.uid}/events`)
      .remove((e) => {
        // console.log(e);
      });
  });
};

export const getMoreOnePriorEvent = (data, callback, lastKey, offset = 0) => {
  let newLastKey;
  let { ts, te } = data;
  // console.log("lastKey => ", lastKey);
  // console.log("ts => ", ts);
  // console.log(offsetCalculatedTime(ts, offset));
  firebase
    .database()
    .ref(refEvent)
    .orderByChild("eventTime")
    .endAt(offsetCalculatedTime(ts, offset))
    .limitToLast(3)
    .on("value", (snap) => {
      // console.log(snap);
      let value = [];
      let i = 0;
      snap.forEach((e) => {
        // console.log(e.val());
        if (i === 0) {
          value.push(e.val());
          newLastKey = e.key;
        }
        i++;
      });
      // console.log("newLastKey => ", newLastKey);
      // console.log("oldLastKey => ", lastKey);
      if (newLastKey !== lastKey) {
        callback(value, newLastKey);
      } else {
        callback(null, null);
      }
    });
};
export const _getMoreOnePriorEvent = (data, callback, lastObj, offset = 0) => {
  let newLastKey;
  let { ts, te } = data;
  // console.log("lastObj => ", lastObj);
  // console.log("ts => ", ts);
  if (lastObj)
    firebase
      .database()
      .ref(refEvent)
      .orderByChild("eventTime")
      .endAt(lastObj.eventTime)
      .limitToLast(2)
      .on("value", (snap) => {
        // console.log(snap);
        let value = [];
        let i = 0;
        snap.forEach((e) => {
          // console.log(e.val());
          if (i === 0) {
            value.push(e.val());
            newLastKey = e.key;
          }
          i++;
        });
        // console.log("newLastKey => ", newLastKey);
        // console.log("oldLastKey => ", lastObj.d.toString().replace(".", ""));
        if (newLastKey !== lastObj.d.toString().replace(".", "")) {
          callback(value, newLastKey);
        } else {
          callback(null, null);
        }
      });
};

export const getDateRangeEvents = (
  data,
  callback,
  lastKey,
  offset = 0,
  firstLoadingTime = 0
) => {
  let { ts, te } = data;
  let newLastKey;
  // console.log("lastKey => ", lastKey);
  // console.log("ts => ", ts);
  // console.log("te => ", te);
  // console.log("first => ", firstLoadingTime);

  if (firstLoadingTime) {
    ts = new Date();
  }
  // console.log(offsetCalculatedTime(ts, offset));
  // console.log(offsetCalculatedTime(te, offset));
  firebase
    .database()
    .ref(refEvent)
    .orderByChild("eventTime")
    .startAt(offsetCalculatedTime(ts, offset))
    .endAt(offsetCalculatedTime(te, offset))
    .on("value", (snap) => {
      let value = [];
      let i = 0;
      snap.forEach((e) => {
        value.push(e.val());
        if (i === 0) newLastKey = e.key;
        i++;
      });
      callback(value, newLastKey);
    });
};

export const getFirebaseUserEvents = async (user) => {
  if (!user) return;

  return new Promise((resolve, reject) => {
    firebase
      .database()
      .ref(`/users/${user.uid}/events`)
      .once("value", (snap) => {
        let value = [];
        snap.forEach((e) => {
          value.push(e.val());
        });
        resolve(value);
      });
  });
};

export const getSingleFirebaseEvent = async (id) => {
  return new Promise((resolve, reject) => {
    firebase
      .database()
      .ref(refEvent)
      .child(id)
      .once("value", (snap) => {
        resolve(snap.val());
      });
  });
};

export const updateFirebaseEvent = async (data) => {
  let { d } = data;
  let id = d.toString().replace(".", "");
  data["top"] = false;
  data["offset"] = 0;
  data["info_id"] = await updateInfoObject(data);

  return firebase.database().ref(refEvent).child(id).update(data);
};

export const updateInfoObject = async (data) => {
  let { d } = data;
  let id = d.toString().replace(".", "");

  let obj = {
    planet1: data.planet1,
    position1: { sign: data.position1.sign },
    aspect: data.aspect,
    planet2: data.planet2,
    position2: { sign: data.position2.sign },
    ritual: data.ritual,
    meaning: data.meaning,
    imgRitual: data.imgRitual,
    imgMeaning: data.imgMeaning,
    // element?
  };

  await firebase
    .database()
    .ref("/global/")
    .child("info")
    .set({
      [id]: JSON.parse(JSON.stringify(obj)),
    });

  return id;
};

export const getTimezone = async (lat, lng) => {
  const curl = `${IPGEOLOCATION_API_BASE_URL}timezone?apiKey=${IPGEOLOCATION_API_KEY}&lat=${lat}&long=${lng}`;
  try {
    const res = await axios.get(curl);
    return res.data.timezone;
  } catch (error) {
    console.error(error);
    return 0;
  }
};

export const getTimezoneByLocation = async (objLocation) => {
  const strAPI = `${IPGEOLOCATION_API_BASE_URL}timezone?apiKey=${IPGEOLOCATION_API_KEY}&lat=${objLocation.valLatitude}&long=${objLocation.valLongitude}`;
  try {
    const res = (await axios.get(strAPI));
    return res.data.timezone;
  } catch (error) {
    return null;
  }
};

export const getDstTimezoneOffset = async (lat, lng) => {
  const curl = `${IPGEOLOCATION_API_BASE_URL}timezone?apiKey=${IPGEOLOCATION_API_KEY}&lat=${lat}&long=${lng}`;
  try {
    const res = await axios.get(curl);
    // console.log("timezone: ", res.data.timezone);
    if (res.data.is_dst) {
      return {
        offset: res.data.timezone_offset_with_dst,
        timezone: res.data.timezone,
      };
    }
    return {
      offset: res.data.timezone_offset,
      timezone: res.data.timezone,
    };
  } catch (error) {
    console.error(error);
    return 0;
  }
};

export const getUtcDateTime = (dateTime, offset) => {
  let date = new Date(dateTime);
  let utc = {};
  utc.year = date.getUTCFullYear();
  utc.month = date.getUTCMonth() + 1;
  utc.day = date.getUTCDate();
  const hour = date.getHours();
  if (offset > 0) {
    // UTC + timezone
    if (hour < offset) {
      utc.hour = hour + 24 - offset;
      utc.day -= 1;
    } else {
      utc.hour = hour - offset;
    }
  } else {
    utc.hour = hour - offset;
    if (hour - offset >= 24) {
      utc.hour = hour - offset - 24;
      utc.day += 1; 
    }
  }
  utc.minute = date.getUTCMinutes();
  utc.second = date.getUTCSeconds();
  return utc;
};

export const getLocalDateTime = (utcDateTime, tzOffset) => {
  let returnDate = moment(utcDateTime)
    .utcOffset(tzOffset)
    .format("MM/DD/YYYY HH:mm:ss");
  returnDate = new Date(returnDate);
  let local = {};
  local.year = returnDate.getUTCFullYear();
  local.month = returnDate.getMonth() + 1;
  local.day = returnDate.getDate();
  local.hour = returnDate.getHours();
  local.minute = returnDate.getMinutes();
  local.second = returnDate.getSeconds();
  return local;
};

export const getIpAddress = async () => {
  const res = await axios.get("https://geolocation-db.com/json/");
  return res.data.IPv4;
};

export const getLocation = async () => {
  const ipAddress = await getIpAddress();
  const curl = `${IPGEOLOCATION_API_BASE_URL}ipgeo?apiKey=${IPGEOLOCATION_API_KEY}&ip=${ipAddress}`;
  try {
    const res = await axios.get(curl);
    const addr = `${res.data.city}, ${res.data.state_prov}, ${res.data.country_name}, ${res.data.zipcode}`;
    return addr;
  } catch (error) {
    console.error(error);
    return "";
  }
};

export const updateUserProfile = async (
  formData,
  user,
  editProfileMode = 0
) => {
  let { birthDay, address, name } = formData;
  let { latitude, longitude } = formData;

  let update = {
    ...user,
  };
  //date comparision
  if (
    new Date(formData.birthDay).getTime() !== new Date(user.birthDay).getTime()
  ) {
    editProfileMode = 1;
  }
  //
  let local = {};
  let utc = {};

  if (birthDay) {
    let timezone = await getTimezone(latitude, longitude);
    // console.log("timezone => ", timezone);
    // let dt = DateTime.fromISO(birthDay).setZone(timezone)
    let dt = DateTime.fromJSDate(new Date(birthDay)).setZone(timezone);
    // console.log(`${timezone} - ${dt}`);
    let dtUTC = dt.toUTC();

    local = {
      year: dt.year,
      month: dt.month,
      day: dt.day,
      hour: dt.hour,
      minute: dt.minute,
      second: dt.second,
    };

    utc = {
      year: dtUTC.year,
      month: dtUTC.month,
      day: dtUTC.day,
      hour: dtUTC.hour,
      minute: dtUTC.minute,
      second: dtUTC.second,
    };

    update.date = { atBirthLocation: local, utc: utc };
    update.birthDay = birthDay;
  }

  if (address) {
    update.birthLocation = {
      name: address,
      latitude,
      longitude,
    };
  }

  if (name) update.name = name;
  const updateClosure = async () => {
    if (editProfileMode) {
      update.events = {};
    }
    if (user.uid) {
      return firebase
        .database()
        .ref(`/users`)
        .child(user.uid)
        .update(update)
        .then((tempData) => {
          alert("User profile updated successfully!");
          return tempData;
        })
        .catch((err) => {
          // console.log(`Err : ${err}`);
          alert(`Something wrong : ${err}`);
          throw new Error(`${err}`);
        });
    }
  };

  try {
    // load house and sign from other endpoint
    let dt = `${utc.day}/${utc.month}/${utc.year}/${utc.hour}/${utc.minute}/${utc.second}`;
    let params = {
      n: dt,
      lx: update.birthLocation.latitude,
      ly: update.birthLocation.longitude,
      json: true,
    };
    let chartUrl = `${URL}${queryString(params)}`;
    axios.get(chartUrl).then(async (res) => {
      // get the json data we want, then save to user obj
      let userInfo = cleanApiData(res.data);
      update.planets = userInfo.planets;
      update.houses = userInfo.houses;
      // update the firebase db
      await updateClosure();
    });
  } catch (e) {
    updateClosure();
  }
};

export const getLocationByPlaceId = async (placeId) => {
  const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${GOOGLEGEOCODE_API_KEY}`;
  try {
    const res = await axios.get(apiUrl);
    return res;
  } catch (error) {
    console.error(error);
    return "";
  }
};

export const getPlanetsDetailWithDate = async (date) => {
  const strDateTime = moment(date).utc().format("D/M/YYYY/H/m/s");
  const apiUrl = `https://ritualup.net/?text=false&json=true&svgString=false&n=${strDateTime}`;
  try {
    const res = await axios.get(apiUrl);
    return cleanApiData(res.data);
  } catch (error) {
    console.error(error);
    return null;
  }
};

// get user charts saved
export const getFirebaseUserCharts = (user) => {
  if (!user) return;

  return new Promise((resolve, reject) => {
    firebase
      .database()
      .ref(`/charts/${user.uid}`)
      .once("value", (snap) => {
        let value = [];
        snap.forEach((e) => {
          value.push(e.val());
        });
        resolve(value);
      });
  });
};
// get user's single chart saved
export const getSingleUserChart = (user, id) => {
  if (!user) return;

  return new Promise((resolve, reject) => {
    firebase
      .database()
      .ref(`/charts/${user.uid}`)
      .child(id)
      .once("value", (snap) => {
        resolve(snap.val());
      });
  });
};

// set chart events

export const getFirebaseChartEvents = async (chart, user) => {
  if (!chart) return;

  return new Promise((resolve, reject) => {
    firebase
      .database()
      .ref(`charts/${user.uid}/events`)
      .once("value", (snap) => {
        let value = [];
        snap.forEach((e) => {
          value.push(e.val());
        });
        resolve(value);
      });
  });
};

export const getChartEvents = async (chart, svgStringOption = false) => {
  const { dateTime, address } = chart;
  const results = await geocodeByAddress(address);
  const position = await getLatLng(results[0]);
  const { lat: latitude, lng: longitude } = position;
  const utc = new Date(dateTime);
  let bday = `${utc.getDate()}/${
    utc.getMonth() + 1
  }/${utc.getFullYear()}/${utc.getHours()}/${utc.getMinutes()}/${utc.getSeconds()}`;
  let ts = new Date();
  let te = new Date();

  ts.setDate(ts.getDate() - 30);
  te.setDate(te.getDate() + 30);

  ts = ts.toLocaleDateString("en-GB");
  te = te.toLocaleDateString("en-GB");

  let params = {
    n: bday,
    ts: ts,
    te: te,
    json: true,
    lx: latitude,
    ly: longitude,
    svgstring: svgStringOption,
  };
  return axios.get(`${URL}${queryString(params)}`);
};

export const setChartEvents = async (user, chart, svgStringOption = false) => {
  if (!chart) return;
  if (!chart.address || !chart.dateTime) return;

  let { data } = await getChartEvents(chart, svgStringOption);
  if (!data) return;

  let eventList = null;
  let svgList = null;
  let svgDict = null;
  let split = null;

  // let data1 = data[0] + "}"
  let data2 = null;
  let svgData = null;

  //SVG string required
  if (svgStringOption) {
    data = data.split("}\n[");
    split = data[1].split("]\n<");

    data2 = "[" + split[0] + "]";
    svgData = "<" + split[1];

    // let userInfo = cleanApiData(data1)
    eventList = cleanApiData(data2);
    svgList = cleanSvgData(svgData);
    svgDict = buildSvgDict(svgList);
  } else {
    //SVG string not required
    data = data.split("}\n[");
    data2 = "[" + data[1];
    // let userInfo = cleanApiData(data1)
    eventList = cleanApiData(data2);
  }

  let updatedEventList = [];
  let update = {};
  const existingChartEventList = await getFirebaseChartEvents(chart, user);
  let blacklist = [];
  eventList.map((event) => {
    let {
      d,
      date: { day, month, year, hour, minute, second },
    } = event;

    let child = d.toString().replace(".", "");

    try {
      // note: we are only using the first 4 decimal places of `d`
      let split = d.toString().split(".");
      let eventId = split[0] + split[1].slice(0, 4);
      if (blacklist.indexOf(eventId) >= 0) {
        return; // item is a duplicate
      }
      blacklist.push(eventId);
    } catch (e) {
      // console.log(e);
      return;
    }

    let eventTime = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    const eventDate = new Date(
      Date.UTC(year, month - 1, day, hour, minute, second)
    );
    event.eventTime = eventDate.getTime();

    // attach matching svg object to event
    try {
      // NOTE: id is composed this way: date.day-date.month-date.year-date.hour-date.minute-date.second
      let eventId = [
        day,
        month,
        year,
        hour,
        minute,
        second.toString().split(".")[0],
      ].join("-");
      if (svgStringOption) {
        event.svg = svgDict[eventId];
      }
    } catch (e) {
      // console.log(e);
    }

    if (existingChartEventList && existingChartEventList[child]) {
      return;
    }

    updatedEventList.push(event);
    update[`/${child}`] = event;
  });

  return new Promise((resolve, reject) => {
    firebase
      .database()
      .ref(`/charts/${user.uid}/${chart.cid}/events`)
      .update(update)
      .then((data) => {
        resolve(updatedEventList);
      });
  });
};
// save chart

export const saveUserChart = async (user, data) => {
  if (!user) return;

  let chartData = {};
  // let existingEventList = await getFirebaseUserEvents(user);
  // console.log(data);
  // const results = await geocodeByAddress(address);
  // const position = await getLatLng(results[0]);
  // const { lat: latitude, lng: longitude } = position;

  const cid = toTimestamp(new Date());
  data[`cid`] = cid;
  chartData[`/${cid}`] = data;
  //

  //
  return new Promise((resolve, reject) => {
    firebase
      .database()
      .ref(`/charts/${user.uid}/`)
      .update(chartData)
      .then((res) => {
        resolve(res);
        setChartEvents(user, data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//edit chart

export const editUserChart = async (user, data) => {
  if (!user) return;
  let chartData = {};
  chartData[`/${data[`cid`]}`] = data;
  return new Promise((resolve, reject) => {
    firebase
      .database()
      .ref(`/charts/${user.uid}`)
      .update(chartData)
      .then((res) => {
        // console.log(res);
        resolve(res);
        setChartEvents(user, data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// create chart and chart events

export function toTimestamp(strDate) {
  var datum = Date.parse(strDate);
  return datum / 1000;
}

export function offsetCalculatedTime(date, offset) {
  return new Date(date).getTime() - offset * 3600 * 1000;
}
