import Chart from "./chart";
import ChartSVG from "./chart.svg";
import Main from "./main";

const Views = {
  Chart,
  ChartSVG,
  Main
}; 

export default Views;