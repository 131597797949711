import React, { useState, useEffect } from "react";
import { TextField, Button } from "@material-ui/core";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import GoogleMaps from "./GoogleLocation";
import { updateUserProfile } from "services/dataService";
import useApp from "hooks/useApp";

const ProfileEditor = () => {
  const { user } = useApp();
  const [formValue, setFormValue] = useState({});
  const [birthValueChanged, setBirthValueChanged] = useState(0);
  useEffect(() => {
    if (formValue.address) {
      const getLocation = async () => {
        const results = await geocodeByAddress(formValue.address);
        const position = await getLatLng(results[0]);
        const { lat, lng } = position;
        setFormValue({
          ...formValue,
          latitude: lat,
          longitude: lng
        });
      }
      getLocation();
    }
  }, [formValue, formValue.address]);

  useEffect(() => {
    if (user) setFormValue(user);
  }, [user]);

  const handleChange = ({ target }) => {
    setFormValue({ ...formValue, [target.name]: target.value });
  };

  const handleSubmit = async () => {
    await updateUserProfile(formValue, user, birthValueChanged);
  };

  return (
    <div className="login-form p-16">
      <TextField
        name="name"
        label="Name"
        className="mb-16"
        variant="outlined"
        fullWidth
        value={formValue.name ? formValue.name : ""}
        onChange={handleChange}
      />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DateTimePicker
          className="mb-16"
          margin="none"
          label="Birth Day"
          inputVariant="outlined"
          type="text"
          fullWidth
          value={formValue.birthDay}
          format="MMMM dd, yyyy :-: HH:mm:ss aa"
          onChange={date => {
            if (date.getTime() !== new Date(formValue.birthDay).getTime()) {
              console.log('birth changed');
              setBirthValueChanged(1);
            } else {
              setBirthValueChanged(0);
            }
            handleChange({ target: { name: "birthDay", value: date.toISOString() } });
          }}
        />
      </MuiPickersUtilsProvider>

      <GoogleMaps
        onAddressChange={handleChange}
        address={formValue.birthLocation ? formValue.birthLocation.name : ""}
      ></GoogleMaps>

      <div className="mb-16"></div>
      <Button
        className="capitalize"
        variant="contained"
        color="primary"
        onClick={handleSubmit}
      >
        Save
      </Button>
    </div>
  );
};

export default ProfileEditor;
