import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Link, useLocation, useHistory } from 'react-router-dom';

function TabNavigation({ activeTab }) {
  const location = useLocation();

  const [value, setValue] = useState(activeTab);

  useEffect(() => {
    const { pathname } = location;
    const pathArr = pathname.split('/');
    let activeTab;
    if (pathArr > 3) {
      activeTab = pathArr.pop();
    } else {
      activeTab = pathArr[2];
    }
    setValue(activeTab);
  }, [location]);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper square>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="Sub navigation"
      >
        <Tab
          component={Link}
          to="/admin/info-objects/astro-parts"
          value="info-objects"
          label="info objects"
        />
        <Tab
          component={Link}
          to="/admin/values"
          value="values"
          label="values"
        />
        <Tab
          component={Link}
          to="/admin/import-transits"
          value="import-transits"
          label="import transits"
        />
        <Tab component={Link} to="/admin/users" value="users" label="users" />
      </Tabs>
    </Paper>
  );
}

function SubTabNavigation({ activeTab }) {
  const location = useLocation();
  const [value, setValue] = React.useState(activeTab);

  React.useEffect(() => {
    const { pathname } = location;
    const pathArr = pathname.split('/');
    let activeTab = pathArr.pop();
    setValue(activeTab);
  }, [location]);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper square>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="Sub navigation"
      >
        <Tab
          component={Link}
          to="/admin/info-objects/astro-parts"
          value="astro-parts"
          label="astro parts"
        />
        <Tab
          component={Link}
          to="/admin/info-objects/aspects"
          value="aspects"
          label="aspects"
        />
        <Tab
          component={Link}
          to="/admin/info-objects/Compatibility"
          value="Compatibility"
          label="Compatibility"
        />
      </Tabs>
    </Paper>
  );
}

function TotalNavigation() {
  const { pathname } = useLocation();

  const pathArr = pathname.split('/');
  const parentTab = pathArr[pathArr.indexOf('admin') + 1];
  const childTab =
    parentTab === 'info-objects'
      ? pathArr[pathArr.indexOf('info-objects') + 1]
      : '';

  return (
    <>
      <TabNavigation activeTab={parentTab} />
      {pathname.includes('info-object') && (
        <SubTabNavigation activeTab={childTab} />
      )}
    </>
  );
}

function AdminNavbar({ children }) {
  const { pathname } = useLocation();
  const history = useHistory();

  if (pathname === '/admin') {
    history.push('/admin/info-object');
  }

  return (
    <>
      <TotalNavigation />
      {children}
    </>
  );
}

export default AdminNavbar;
