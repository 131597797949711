import React, { useState, useEffect, useRef, useContext } from "react";
import { TextField, Grid } from "@material-ui/core";
import {
  getDateRangeEvents,
  _getMoreOnePriorEvent,
} from "services/dataService";
// import { AppContext } from 'AppContext';
// import GoogleMaps from "./GoogleLocation";
import TransitEditor from "./TransitEditor";
import moment from "moment";
import { getLocation } from "services/dataService";
// import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  CircularProgress,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { EventChangeContext } from "./main";
import { currentTimezone, getOffset, sortForUpcoming } from "services/utils";
import TimezoneList from "components/TimezoneList";
import { getTimezoneOffset } from "date-fns-tz";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: "14px !important",
    "& div": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px",
      },
      fontSize: "14px",
    },
  },
  label: {
    "& span": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px",
      },
      fontSize: "14px",
    },
  },
}));
const TransitViewer = () => {
  const classes = useStyles();
  const { eventState, handleEventChange } = useContext(EventChangeContext);
  const [eventList, setEventList] = useState([]);
  const [fullEventList, setFullEventList] = useState([]);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [currentOffset, setCurrentOffset] = useState(undefined);
  const [lastKey, setLastKey] = useState();
  const [formValue, setFormValue] = useState({
    ts: moment().add(0, "days").format("yyyy-MM-DD"),
    te: moment().add(21, "days").format("yyyy-MM-DD"),
    address: "",
    timezone: {
      value: currentTimezone(),
      label: currentTimezone(),
    },
  });
  const previousDateRangeForUpcomingTransit = useRef({
    ts: formValue.ts,
    te: formValue.te,
  });
  const previousDateRangeForLastTransit = useRef({
    ts: formValue.ts,
    te: formValue.te,
  });

  const [firstTime, setFirstTime] = useState(true);
  const [loadingMore, setLoadingMore] = useState(true);
  const [open, setOpen] = useState(false);
  const [showMoonEvents, setShowMoonEvents] = useState(true);

  // useEffect(() => {
  //   let isApiSubscribed = true;
  //   const getAddress = async () => {
  //     try {
  //       const address = await getLocation();
  //       if (isApiSubscribed) {
  //         setFormValue((previousValue) => {
  //           return {
  //             ...previousValue,
  //             address: address,
  //           };
  //         });
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       if (isApiSubscribed) setOpen(true);
  //     }
  //   };
  //   getAddress();
  //   return () => {
  //     isApiSubscribed = false;
  //   };
  // }, []);

  useEffect(() => {
    let isApiSubscribed = true;
    const _getOffset = async () => {
      if (formValue.timezone) {
        // console.log("offset => ", getOffset(formValue.timezone.value));
        // const _offset = getTimezoneOffset(formValue.timezone.value);
        const _offset = getOffset(formValue.timezone.value);
        // console.log(_offset);
        if (isApiSubscribed) {
          setCurrentOffset(_offset);
          await handleSubmit(_offset);
        }
      }
    };
    _getOffset();
    return () => {
      // cancel the request before component unmounts
      isApiSubscribed = false;
    };
  }, [formValue.timezone]);

  useEffect(() => {
    let isApiSubscribed = true;
    const getTransits = async () => {
      if (currentOffset && isApiSubscribed) {
        console.log("date changed");
        await handleSubmit(currentOffset);
        if (firstTime) setFirstTime(false);
      }
    };

    getTransits();
    return () => {
      isApiSubscribed = false;
    };
  }, [formValue.ts, formValue.te]);

  /**
   * change event list when switch control changed for Moon events
   */
  useEffect(() => {
    let isApiSubscribed = true;
    const filteredEventList = fullEventList.filter((e) => {
      if (showMoonEvents) {
        return true;
      } else {
        return e.planet1.toLowerCase() !== "moon";
      }
    });
    if (isApiSubscribed) {
      setEventList([...sortForUpcoming(filteredEventList)]);
    }

    return () => {
      isApiSubscribed = false;
    };
  }, [showMoonEvents]);

  /**
   * register window event listner for handle scroll
   */
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, {
      passive: true,
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [formValue.ts, formValue.te, currentOffset, eventList]);
  //handle change timezone list
  const handleChangeTimezoneList = (option) => {
    console.log(option);

    setFormValue((prev) => ({
      ...prev,
      timezone: option,
    }));
  };
  // handle alert
  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };

  /**
   * Handle Scroll to detect reach bottom of the page
   */
  const handleScroll = () => {
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight;

    if (bottom) {
      if (eventList.length > 0) loadMoreTransits();
    }
  };

  // handle event change selected
  const handleSelectedEventChange = (e) => {
    console.log(e);
    handleEventChange(e);
  };

  const loadPreviousTransits = () => {
    if (loadingMore) return;
    const currentTS = previousDateRangeForLastTransit.current.ts;
    const upcomingTS = moment(currentTS)
      .clone()
      .add(-1, "days")
      .format("yyyy-MM-DD");
    const upcomingTE = moment(currentTS)
      .clone()
      .add(0, "d")
      .format("yyyy-MM-DD");
    _getMoreOnePriorEvent(
      {
        ...previousDateRangeForLastTransit.current,
      },
      (data, newLastKey) => {
        setLoadingMore(false);
        if (data && data.length > 0) {
          let temp = [];
          data.forEach((e) => {
            e["offset"] = currentOffset;
            if (showMoonEvents) {
              temp.push(e);
            } else {
              if (e.planet1.toLowerCase() !== "moon") {
                temp.push(e);
              }
            }
          });
          if (temp.length) {
            const newE = temp[0];
            const newFullEventList = [...fullEventList];
            const newEventList = [...eventList];
            newFullEventList.splice(0, 0, newE);
            newEventList.splice(0, 0, newE);
            setFullEventList(newFullEventList);
            setEventList(newEventList);
            setLastKey(newLastKey);
          }
        }
      },
      fullEventList[0]
    );
    // getDateRangeEvents(
    //   {
    //     ts: upcomingTS,
    //     te: upcomingTE,
    //   },
    //   (data, newLastKey) => {
    //     setLoadingMore(false);
    //     if (data.length > 0)
    //       previousDateRangeForLastTransit.current = {
    //         ts: upcomingTS,
    //         te: upcomingTE,
    //       };
    //     let temp = [];
    //     data.forEach((e) => {
    //       e["offset"] = currentOffset;
    //       if (showMoonEvents) {
    //         temp.push(e);
    //       } else {
    //         if (e.planet1.toLowerCase() !== "moon") {
    //           temp.push(e);
    //         }
    //       }
    //     });
    //     const newE = temp.filter(
    //       (e, index, self) =>
    //         index ===
    //         self.findIndex(
    //           (t) => Math.floor(t.date.second) === Math.floor(e.date.second)
    //         )
    //     );
    //     setFullEventList(newE.concat(fullEventList));
    //     setEventList(sortForUpcoming(newE.concat(eventList)));
    //     setLastKey(newLastKey);
    //   },
    //   lastKey
    // );
  };

  const loadMoreTransits = () => {
    const currentTE = previousDateRangeForUpcomingTransit.current.te;
    const upcomingTE = moment(currentTE)
      .clone()
      .add(21, "days")
      .format("yyyy-MM-DD");
    const upcomingTS = moment(currentTE)
      .clone()
      .add(1, "d")
      .format("yyyy-MM-DD");
    setLoadingMore(true);
    // setFormValue((previousValue) => {
    //   return {
    //     ...previousValue,
    //     te: upcomingTE,
    //   };
    // });
    getDateRangeEvents(
      {
        ts: upcomingTS,
        te: upcomingTE,
      },
      (data, newLastKey) => {
        setLoadingMore(false);
        if (data.length > 0)
          previousDateRangeForUpcomingTransit.current = {
            ts: upcomingTS,
            te: upcomingTE,
          };
        let temp = [];
        data.forEach((e) => {
          e["offset"] = currentOffset;
          if (showMoonEvents) {
            temp.push(e);
          } else {
            if (e.planet1.toLowerCase() !== "moon") {
              temp.push(e);
            }
          }
        });

        const newE = temp.filter(
          (e, index, self) =>
            index ===
            self.findIndex(
              (t) => Math.floor(t.date.second) === Math.floor(e.date.second)
            )
        );
        if (newE.length) {
          setFullEventList(fullEventList.concat(newE));
          // setEventList(sortForUpcoming(eventList.concat(newE)));
          setEventList(eventList.concat(newE));
          setLastKey(newLastKey);
        }
      },
      lastKey,
      currentOffset
    );
  };

  const handleChange = ({ target }) => {
    if (target.name === "ts") {
      previousDateRangeForLastTransit.current = {
        ...previousDateRangeForLastTransit.current,
        ts: target.value,
      };
    } else if (target.name === "te") {
      previousDateRangeForUpcomingTransit.current = {
        ...previousDateRangeForUpcomingTransit,
        te: target.value,
      };
    }
    setFormValue({ ...formValue, [target.name]: target.value });
  };

  const handleSwitchChange = (event) => {
    setShowMoonEvents(event.target.checked);
  };

  const handleBlur = ({ target }) => {
    if (target.value !== formValue.address) {
      setFormValue({ ...formValue, [target.name]: target.value });
    }
  };

  const handleSubmit = async (offset = 0) => {
    getDateRangeEvents(
      formValue,
      (data, newLastKey) => {
        let temp = [];
        data.forEach((e, index) => {
          e["offset"] = offset;
          e.top = false;
          // if (firstTime && index === 0) e.top = true;
          if (showMoonEvents) {
            temp.push(e);
          } else {
            if (e.planet1 !== "moon") {
              temp.push(e);
            }
          }
        });
        const newE = temp.filter(
          (e, index, self) =>
            index ===
            self.findIndex(
              (t) => Math.floor(t.date.second) === Math.floor(e.date.second)
            )
        );
        setLoadingMore(false);
        setEventList(sortForUpcoming(newE));
        setFullEventList(newE);
        setLastKey(newLastKey);
      },
      lastKey,
      currentOffset,
      firstTime
    );
  };

  return (
    <>
      <form className="h-100">
        <div className="draw-form">
          <Grid container spacing={1}>
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              {/* <Grid item xs={4} sm={4} md={4} style={{ textAlign: "right" }}>
                Timezone:
              </Grid> */}
              <Grid item xs={5} sm={4} md={4} style={{ textAlign: "left" }}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        checked={showMoonEvents}
                        onChange={handleSwitchChange}
                      />
                    }
                    className={classes.label}
                    label="Moon events"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={7} md={4}>
                {/* <GoogleMaps
                  label=""
                  onAddressChange={handleBlur}
                  address={formValue.address}
                  onAutocompleteChange={() => {}}
                /> */}
                <TimezoneList
                  onAddressChange={() => {}}
                  selectedTimezone={formValue.timezone}
                  label=""
                  onAutocompleteChange={handleChangeTimezoneList}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              spacing={1}
              direction="row"
              alignItems="center"
            >
              <Grid item xs={8} sm={9}>
                <Grid container alignItems="center" direction="row" spacing={1}>
                  <Grid item xs={5} sm={4} md={4}>
                    <TextField
                      className={`${classes.root}`}
                      size="small"
                      name="ts"
                      fullWidth={false}
                      type="date"
                      value={formValue.ts}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={1}
                    sm={2}
                    md={2}
                    alignItems="center"
                    justifyContent="center"
                  >
                    to
                  </Grid>
                  <Grid item xs={5} sm={4} md={4}>
                    <TextField
                      className={`${classes.root}`}
                      size="small"
                      name="te"
                      type="date"
                      value={formValue.te}
                      fullWidth={false}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4} sm={3} style={{ textAlign: `right` }}>
                <div
                  style={{
                    cursor: "pointer",
                    textAlign: "center",
                    textDecoration: "underline",
                    fontSize: "12px",
                  }}
                  onClick={() => loadPreviousTransits()}
                >
                  +1 last transit
                </div>
              </Grid>
            </Grid>
          </Grid>
          <div className="mb-16"></div>
          {/* <Button
          className="mb-32"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          Display
        </Button> */}
        </div>
      </form>
      <Box
        component="div"
        className={isMobile ? "" : "event-scroll-container"}
        height={eventList.length ? `77vh` : `0`}
      >
        {eventList.map((e, i) => (
          <TransitEditor
            key={i}
            index={i}
            event={e}
            selectedEvent={eventState.selectedEvent}
            handleSelectedEventChange={handleSelectedEventChange}
          />
        ))}
        {loadingMore && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        )}
      </Box>
      {/* TODO:  change open value */}
      <Dialog
        open={false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            We can’t get location. Please try again!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TransitViewer;
