import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import AstroPartsCard from './AstroPartsCard';

const styles = (theme) => ({
  root: {
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
});

const useStyles = makeStyles(styles);

function AstroPartsCards({ infoObjects }) {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      {infoObjects.map((infoObject) => (
        <AstroPartsCard key={infoObject.id} infoObject={infoObject} />
      ))}
    </Grid>
  );
}

export default AstroPartsCards;
