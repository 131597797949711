import React, { useState, useEffect } from "react";
import axios from "axios";
import { URL } from "services/constants";
import { getUtcDateTime, getDstTimezoneOffset } from "services/dataService";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import "../chartstyle.css";
import { Backdrop, CircularProgress } from "@material-ui/core";
import useApp from "hooks/useApp";

const ChartUser = () => {
  const { user } = useApp();
  const [chartImg, setChartImg] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getBirthDay = async () => {
      if (user && user.birthLocation.name && window.google) {
        setLoading(true);
        console.log(user.birthLocation.name);
        const results = await geocodeByAddress(user.birthLocation.name);
        const position = await getLatLng(results[0]);
        const { lat, lng } = position;
        const dateTime = getUtcDateTime(
          user.birthDay,
          (await getDstTimezoneOffset(lat, lng)).offset
        );
        let dt = `${dateTime.day}/${dateTime.month}/${dateTime.year}/${dateTime.hour}/${dateTime.minute}/${dateTime.second}`;
        let chartUrl = `${URL}n=${dt}&lx=${lat}&ly=${lng}&svgstring=true`;
        axios
          .get(chartUrl)
          .then((res) => {
            setChartImg(res.data);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    };
    getBirthDay();

    return () => {
      setLoading(false);
    };
  }, [user]);

  return (
    <div className="login-form p-16">
      <h1>Chart User</h1>
      {loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: chartImg }} />
      )}
    </div>
  );
};

export default ChartUser;
