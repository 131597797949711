// import react modules
import Geocode from "react-geocode";

// import base modules
import CONSTS from "consts";

const getAddressByLocation = async argLocation => {
  Geocode.setApiKey(CONSTS.APIKEYS.GOOGLEGEOCODE);
  Geocode.setLanguage("en");
  Geocode.setLocationType("ROOFTOP");  
  const objGoogleCode = await Geocode.fromLatLng(argLocation.valLatitude, argLocation.valLongitude);  
  const strResults = ['', '', ''];
  for (const objAddressComponent of objGoogleCode.results[0].address_components) {
    for (const strType of objAddressComponent.types) {
      switch (strType) {
        case "locality":
          strResults[0] = objAddressComponent.long_name;
          break;
        case "administrative_area_level_1":
          strResults[1] = objAddressComponent.short_name;
          break;
        case "country":
          strResults[2] = objAddressComponent.short_name;
          break;
      }
    }
  }
  if (strResults[0] === '')
    strResults.shift();
  return strResults.join(", ");
}

const getLocationByGEO = objGeoLocationPostion => {
  const objGeoLocationCoordinates = objGeoLocationPostion.coords;
  return {
    valLatitude: objGeoLocationCoordinates.latitude,
    valLongitude: objGeoLocationCoordinates.longitude
  };
};

const GEO = {
  getAddressByLocation,
  getLocationByGEO
};

export default GEO;