import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { aspectsArt, planetsArt, signs, signsArt } from "services/constants";
import { getPlanetsDetailWithDate } from "services/dataService";
import { EventChangeContext } from "./main";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const PlanetsDetail = () => {
  const { eventState } = useContext(EventChangeContext);
  const classes = useStyles();
  const [planetData, setPlanetData] = useState([]);
  useEffect(() => {
    console.log('V', 'useEffect PlanetDetails', eventState);
    if (eventState.selectedDate) {
      const getPlanetsDetail = async () => {
        const data = await getPlanetsDetailWithDate(eventState.selectedDate);
        setPlanetData(data.planets);
      };
      getPlanetsDetail();
    }
  }, [eventState.selectedDate]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Planet</TableCell>
              <TableCell>Sign</TableCell>
              <TableCell align="right">Degree</TableCell>
              <TableCell align="right">Speed</TableCell>
              <TableCell align="right">Longitude</TableCell>
              <TableCell align="right">Latitude</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {planetData.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  <i className={planetsArt[row.name] + " mr-12"}></i>
                  {row.name}
                </TableCell>
                <TableCell>
                  <i
                    className={signsArt[signs[row.sign]] + " text-muted mr-8"}
                  ></i>
                  {row.calories}
                  {signs[row.sign]}
                </TableCell>
                <TableCell align="right">
                  <span>{row.degree}</span>
                  <sup className="mr-8 deg">&deg;</sup>
                  <span>{row.min}</span>
                  <sup className="deg">
                    <span>&#x2032;</span>
                  </sup>
                  <span>{row.second}</span>
                  <sup className="deg">
                    <span>&#x2033;</span>
                  </sup>
                </TableCell>
                <TableCell align="right">{row.speed.toFixed(3)}</TableCell>
                <TableCell align="right">{row.longitude.toFixed(3)}</TableCell>
                <TableCell align="right">{row.latitude.toFixed(3)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <div>
        <div className="planets-aspect">
          <div className="flex items-center mb-8">
            <i className={planetsArt[e.planet1] + " mr-12"}></i>
            <h4 className="p-0 m-0 mr-8">
              {e.planet1} <span className="text-muted">in</span>
            </h4>
            <i
              className={signsArt[signs[e.position1.sign]] + " text-muted mr-8"}
            ></i>
            <h4 className="p-0 m-0 mr-8 text-muted">
              {signs[e.position1.sign]}
            </h4>
          </div>
          <div className="flex items-center mb-4">
            <i className={aspectsArt[e.aspect] + " mr-12"}></i>
            <h4 className="p-0 m-0 mr-12">{e.aspect}</h4>

            <h4 className="m-0 text-muted">
              <span>{e.position1.degree}</span>
              <sup className="mr-8 deg">&deg;</sup>
            </h4>
            <h4 className="m-0 text-muted">
              <span>{e.position1.min}</span>
              <sup className="deg">
                <span>&#x2033;</span>
              </sup>
            </h4>
          </div>
          <div className="flex items-center mb-8">
            <i className={planetsArt[e.planet2] + " mr-12"}></i>
            <h4 className="p-0 m-0 mr-8">
              {e.planet2} <span className="text-muted">in</span>
            </h4>
            <i
              className={signsArt[signs[e.position2.sign]] + " text-muted mr-8"}
            ></i>
            <h4 className="p-0 m-0 mr-8 text-muted">
              {signs[e.position2.sign]}
            </h4>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default PlanetsDetail;
