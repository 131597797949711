// import node modules
import React from "react";

const Context = React.createContext();

const Provider = ({ children }) => {
  
  const [valMode, setMode] = React.useState(0); // 0: Global, 1: User, 2: Custom

  const objContext = {
    valMode, setMode
  };

  return (
    <Context.Provider value={objContext}>
      {children}
    </Context.Provider>
  );
}

const ChartContext = {
  Context,
  Provider
};

export default ChartContext;