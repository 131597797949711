import React, { useState, useEffect, useRef } from "react";
import TransitEvent from "./TransitEvent";
import moment from "moment";
// import GoogleMaps from "./GoogleLocation";
import { TextField, Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import { useUserEvents } from "./useUserEvents";
import {
  setUserEvents,
  // deleteUserEvents,
} from "services/dataService";
// import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { isWithinInterval, parseISO, isBefore } from "date-fns";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useContext } from "react";
import { EventChangeContext } from "./main";
import { currentTimezone, getOffset, sortForUpcoming } from "services/utils";
import Card from "@material-ui/core/Card";
// import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import PreviewGif from "../assets/svg/preview.gif";
import PaypalSVG from "../assets/svg/PayPal.svg";
import useApp from "hooks/useApp";
import TimezoneList from "components/TimezoneList";

// import base modules
import Utils from "utils";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: "14px !important",
    "& div": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px",
      },
      fontSize: "14px",
    },
  },
  label: {
    "& span": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px",
      },
      fontSize: "14px",
    },
  },
  cardRoot: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  caption: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 20,
    lineHeight: 24,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },

  paypalBtnWrapper: {
    paddingLeft: 43,
    paddingRight: 43,
  },
}));

const UserTransit = ({ tabIndex }) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const { eventState, handleEventChange } = useContext(EventChangeContext);
  // let user = useUserEvents();
  const { user, tabState, handleProModalShow } = useApp();
  const classes = useStyles();
  const [fullEventList, setFullEventList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [formValue, setFormValue] = useState({
    ts: moment().add(0, "days").format("yyyy-MM-DD"),
    te: moment().add(21, "days").format("yyyy-MM-DD"),
    address: "",
    timezone: {
      value: currentTimezone(),
      label: currentTimezone(),
    },
  });
  const [offset, setOffset] = useState(undefined);
  const [firstTime, setFirstTime] = useState(true);

  const previousDateRangeForUpcomingTransit = useRef({
    ts: formValue.ts,
    te: formValue.te,
  });
  const previousDateRangeForLastTransit = useRef({
    ts: formValue.ts,
    te: formValue.te,
  });

  const [loadingMore, setLoadingMore] = useState(true);
  const [open, setOpen] = useState(false);
  const [proModalOpen, setProModalOpen] = useState(false);
  const [showMoonEvents, setShowMoonEvents] = useState(true);

  //handle change timezone list
  const handleChangeTimezoneList = (option) => {
    setFormValue((prev) => ({
      ...prev,
      timezone: option,
    }));
  };

  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };

  const handleProModalClose = () => {
    setProModalOpen(false);
    handleProModalShow(false);
  };

  // useEffect(() => {
  //   let isApiSubscribed = true;
  //   const getAddress = async () => {
  //     try {
  //       const address = await getLocation();
  //       console.log(address);
  //       if (isApiSubscribed)
  //         setFormValue((previousValue) => {
  //           return {
  //             ...previousValue,
  //             address: address,
  //           };
  //         });
  //     } catch (error) {
  //       console.log(error);
  //       // alert("We can’t get location. Please try again!");
  //       if (isApiSubscribed) setOpen(true);
  //     }
  //   };

  //   // TODO: check if this is needed to run
  //   getAddress();
  //   //

  //   return () => {
  //     // cancel the request before component unmounts
  //     isApiSubscribed = false;
  //   };
  // }, []);

  useEffect(() => {
    let isSubscribed = true;
    if (user && tabIndex === 1) {
      if (isSubscribed) setUserEvents(user, false);
      // if (isSubscribed) deleteUserEvents(user);
      const loadUserEvents = () => {
        // console.log("offset :", offset);
        if (offset && isSubscribed) {
          // console.log("date changed");
          loadEvents(offset);
        }
      };
      if (tabIndex === 1) loadUserEvents();
    }

    return () => {
      isSubscribed = false;
    };
  }, [user, tabIndex]);

  useEffect(() => {
    let isSubscribed = true;

    const loadUserEvents = () => {
      // console.log("offset :", offset);
      if (offset && isSubscribed) {
        // console.log("date changed");
        loadEvents(offset);
        if (firstTime) setFirstTime(false);
      }
    };
    if (tabIndex === 1) loadUserEvents();
    return () => {
      // cancel the request before component unmounts
      isSubscribed = false;
    };
  }, [formValue.ts, formValue.te]);

  useEffect(() => {
    let isSubscribed = true;
    const _getOffset = async () => {
      if (formValue.timezone) {
        // console.log("offset => ", getOffset(formValue.timezone.value));
        // const _offset = getTimezoneOffset(formValue.timezone.value);
        const _offset = getOffset(formValue.timezone.value);
        // console.log(_offset);
        if (isSubscribed) {
          setOffset(_offset);
          loadEvents(_offset);
        }
      }
    };
    _getOffset();
    return () => {
      // cancel the request before component unmounts
      isSubscribed = false;
    };
  }, [formValue.timezone]);

  useEffect(() => {
    let isSubscribed = true;

    const loadSelectedChartEvents = () => {
      if (offset === undefined)
        return;
      // console.log("offset - chart events:", offset);
      if (tabState.selectedChart && tabState.selectedChart.events) {
        let temp = [];
        for (const key in tabState.selectedChart.events) {
          if (tabState.selectedChart.events.hasOwnProperty(key)) {
            const element = tabState.selectedChart.events[key];
            element["offset"] = offset;
            element.top = false;
            if (
              isBefore(parseISO(formValue.ts), parseISO(formValue.te)) &&
              isWithinInterval(new Date(element.eventTime), {
                start: parseISO(formValue.ts),
                end: parseISO(formValue.te),
              })
            ) {
              if (showMoonEvents) {
                temp.push(element);
              } else {
                if (element.planet2.toLowerCase() !== "moon") {
                  temp.push(element);
                }
              }
            }
          }
        }
        const newE = temp.filter(
          (e, index, self) =>
            index ===
            self.findIndex(
              (t) => Math.floor(t.date.second) === Math.floor(e.date.second)
            )
        );
        if (isSubscribed) {
          setLoadingMore(false);
          setFullEventList([...newE]);
          setEventList([...sortForUpcoming(newE)]);
        }
      }
    };

    if (tabIndex === 2) loadSelectedChartEvents();

    return () => {
      isSubscribed = false;
    };
  }, [tabState.selectedChart, offset, formValue.ts, formValue.te]);

  // console.log('tabState', tabState);

  /**
   * change event list when switch control changed for Moon events
   */
  useEffect(() => {
    let isSubscribed = true;
    const filteredEventList = fullEventList.filter((e) => {
      if (showMoonEvents) {
        return e;
      } else {
        return e.planet2 && (e.planet2.toLowerCase() !== "moon");
      }
    });
    if (isSubscribed && filteredEventList.length > 0) {
      setEventList([...sortForUpcoming(filteredEventList)]);
    }

    return () => {
      isSubscribed = false;
    };
  }, [showMoonEvents]);
  /**
   * register window event listner for handle scroll
   */
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, {
      passive: true,
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [formValue.ts, formValue.te, eventList]);

  //
  const loadEvents = (_offset = 0) => {
    if (user && user.events) {
      let temp = [];
      for (const key in user.events) {
        if (user.events.hasOwnProperty(key)) {
          const element = user.events[key];
          element["offset"] = _offset;
          element.top = false;
          if (
            isBefore(parseISO(formValue.ts), parseISO(formValue.te)) &&
            isWithinInterval(new Date(element.eventTime), {
              // start: parseISO(formValue.ts),
              start: firstTime ? new Date() : parseISO(formValue.ts),
              end: parseISO(formValue.te),
            })
          ) {
            if (showMoonEvents) {
              temp.push(element);
            } else {
              if (element.planet2.toLowerCase() !== "moon") {
                temp.push(element);
              }
            }
          }
        }
      }
      const newE = temp.filter(
        (e, index, self) =>
          index ===
          self.findIndex(
            (t) => Math.floor(t.date.second) === Math.floor(e.date.second)
          )
      );
      setLoadingMore(false);
      setFullEventList([...newE]);
      setEventList([...sortForUpcoming(newE)]);
    }

    // console.log(firstTime);
  };
  /**
   * Handle Scroll to detect reach bottom of the page
   */
  const handleScroll = () => {
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight;

    if (bottom) {
      // console.log("at the bottom");
      loadMoreTransits();
    }
  };

  const handleSwitchChange = (event) => {
    setShowMoonEvents(event.target.checked);
  };

  const loadPreviousTransits = () => {
    if (loadingMore) return;
    const currentTS = previousDateRangeForLastTransit.current.ts;
    const upcomingTS = moment(currentTS)
      .clone()
      .add(-1, "days")
      .format("yyyy-MM-DD");
    const upcomingTE = moment(currentTS)
      .clone()
      .add(0, "d")
      .format("yyyy-MM-DD");

    // console.log(upcomingTE, upcomingTS);
    let events = null;
    if (tabIndex === 1 && user && user.events) {
      events = user.events;
    } else if (
      tabIndex === 2 &&
      tabState.selectedChart &&
      tabState.selectedChart.events
    ) {
      events = tabState.selectedChart.events;
    }
    if (events) {
      let temp = [];
      let _events = [];
      for (const key in events) {
        if (events.hasOwnProperty(key)) {
          const element = events[key];
          element["offset"] = offset;
          // element.top = false;
          _events.push(element);
        }
      }
      const firstEvent = fullEventList[0];
      const idx = _events.indexOf(firstEvent);
      // console.log(`idx => ${idx}`);
      const previousEvent = _events[idx - 1];
      // console.log(`previous =>`, previousEvent);
      previousEvent.offset = offset;
      if (showMoonEvents) {
        temp.push(previousEvent);
      } else {
        if (previousEvent.planet2.toLowerCase() !== "moon") {
          // console.log(`moon : ${previousEvent.planet2}`);
          temp.push(previousEvent);
        }
      }

      const newE = temp.filter(
        (e, index, self) =>
          index ===
          self.findIndex(
            (t) => Math.floor(t.date.second) === Math.floor(e.date.second)
          )
      );
      setFullEventList([...[newE[0]].concat(fullEventList)]);
      setEventList([...[newE[0]].concat(eventList)]);
    }
  };

  const loadMoreTransits = () => {
    const currentTE = previousDateRangeForUpcomingTransit.current.te;
    const upcomingTE = moment(currentTE)
      .clone()
      .add(21, "days")
      .format("yyyy-MM-DD");
    const upcomingTS = moment(currentTE)
      .clone()
      .add(1, "d")
      .format("yyyy-MM-DD");
    setLoadingMore(true);
    // setFormValue((previousValue) => {
    //   return {
    //     ...previousValue,
    //     te: upcomingTE,
    //   };
    // });

    let events = null;
    if (tabIndex === 1 && user && user.events) {
      events = user.events;
    } else if (
      tabIndex === 2 &&
      tabState.selectedChart &&
      tabState.selectedChart.events
    ) {
      events = tabState.selectedChart.events;
    }

    if (events) {
      let temp = [];
      for (const key in events) {
        if (events.hasOwnProperty(key)) {
          const element = events[key];
          element["offset"] = offset;
          // element.top = false;
          if (
            isBefore(parseISO(upcomingTS), parseISO(upcomingTE)) &&
            isWithinInterval(new Date(element.eventTime), {
              start: parseISO(upcomingTS),
              end: parseISO(upcomingTE),
            })
          ) {
            if (showMoonEvents) {
              temp.push(element);
            } else {
              if (element.planet2.toLowerCase() !== "moon") {
                temp.push(element);
              }
            }
          }
        }
      }
      previousDateRangeForUpcomingTransit.current = {
        ts: upcomingTS,
        te: upcomingTE,
      };
      setLoadingMore(false);
      const newE = temp.filter(
        (e, index, self) =>
          index ===
          self.findIndex(
            (t) => Math.floor(t.date.second) === Math.floor(e.date.second)
          )
      );
      setFullEventList([...fullEventList.concat(newE)]);
      setEventList([...eventList.concat(newE)]);
      // setEventList([...sortForUpcoming(eventList.concat(newE))]);
    }
  };

  const handleChange = ({ target }) => {
    if (target.name === "ts") {
      previousDateRangeForLastTransit.current = {
        ...previousDateRangeForLastTransit.current,
        ts: target.value,
      };
    } else if (target.name === "te") {
      previousDateRangeForUpcomingTransit.current = {
        ...previousDateRangeForUpcomingTransit,
        te: target.value,
      };
    }
    setFormValue({ ...formValue, [target.name]: target.value });
  };

//   const handleBlur = ({ target }) => {
//     setFormValue({ ...formValue, [target.name]: target.value });
//   };

  return (
    <>
      <form className="">
        <div className="draw-form">
          <Grid container spacing={0}>
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={0}
            >
              <Grid item xs={5} sm={4} md={4} style={{ textAlign: "left" }}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        checked={showMoonEvents}
                        onChange={handleSwitchChange}
                      />
                    }
                    className={classes.label}
                    label="Moon events"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={7} md={4}>
                {/* <GoogleMaps
                  label=""
                  onAddressChange={handleBlur}
                  address={formValue.address}
                  onAutocompleteChange={() => {}}
                /> */}
                <TimezoneList
                  onAddressChange={() => {}}
                  selectedTimezone={formValue.timezone}
                  label=""
                  onAutocompleteChange={handleChangeTimezoneList}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              spacing={1}
              direction="row"
              alignItems="center"
            >
              <Grid item xs={8} sm={9}>
                <Grid container alignItems="center" direction="row" spacing={1}>
                  <Grid item xs={5} sm={4} md={4}>
                    <TextField
                      className={`${classes.root}`}
                      name="ts"
                      type="date"
                      value={formValue.ts}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={1}
                    sm={2}
                    md={2}
                    alignItems="center"
                    justifyContent="center"
                  >
                    to
                  </Grid>
                  <Grid item xs={5} sm={4} md={4}>
                    <TextField
                      className={`${classes.root}`}
                      name="te"
                      type="date"
                      value={formValue.te}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4} sm={3} style={{ textAlign: `right` }}>
                <div
                  style={{
                    cursor: "pointer",
                    textAlign: "center",
                    textDecoration: "underline",
                    fontSize: "12px",
                  }}
                  onClick={() => loadPreviousTransits()}
                >
                  +1 last transit
                </div>
              </Grid>
            </Grid>
          </Grid>

          <div className="mb-16"></div>
        </div>
      </form>
      {/* {!loadingMore && (
        <div
          style={{ cursor: "pointer", textAlign: "center" }}
          onClick={() => loadPreviousTransits()}
        >
          Load more previous transits
        </div>
      )} */}
      {user && user.userType === "pro" ? (
        <div
          className={
            eventList.length > 0
              ? isMobile
                ? ""
                : "event-scroll-container h-75v"
              : ""
          }
        >
          {eventList.map((e, i) => (
            <Box className="p-16" key={i}>
              <TransitEvent
                event={{ ...e }}
                index={i}
                selectedEvent={eventState.selectedEvent}
                handleSelectedEventChange={handleEventChange}
              />
            </Box>
          ))}
        </div>
      ) : (
        <Card className={classes.root}>
          {/* <CardActionArea> */}
          <CardMedia
            className={classes.media}
            image={PreviewGif}
            title="Contemplative Reptile"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2"></Typography>
            {user ? (
              <Button
                color={"primary"}
                variant="contained"
                // className="capitalize"
                onClick={() => {
                  handleProModalShow(true);
                  // setProModalOpen(true);
                }}
              >
                Get personal transit events
              </Button>
            ) : (
              <p className="gif-caption" classes={classes.caption}>
                View your personal transits
              </p>
            )}
          </CardContent>
          {/* </CardActionArea> */}
          <CardActions>
            {/* <Button size="small" color="primary">
              Share
            </Button>
            <Button size="small" color="primary">
              Learn More
            </Button> */}
          </CardActions>
        </Card>
      )}

      {/* {loadingMore && (
        <Box sx={{ display: "flex" }}>
          <CircularProgress
            size={24}
            sx={{
              color: green[500],
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "20px",
              marginLeft: "400px",
            }}
          />
        </Box>
      )} */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            We can’t get location. Please try again!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
      {/** !-----! */}
      {/** Go Pro Dialog */}
      {isMobile && (
        <Dialog
          open={tabState.proUserModalShow}
          onClose={handleProModalClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{ textAlign: "center", color: "#6202EE" }}
          >
            {"PRO"}
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleProModalClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <img
              alt={"preview gif"}
              src={PreviewGif}
              style={{ width: "100%" }}
            />
            <DialogContentText
              id="alert-dialog-description"
              className="pro-dialog-price-label-wrapper"
            >
              <span className="pro-dialog-price-label">$4</span>/month
            </DialogContentText>
            <p className="pro-dialog-description-label">
              Personal Transit Events
            </p>
            <p className="pro-dialog-description-label">
              Transit Notifications
            </p>
            <p className="pro-dialog-description-label">
              Personal Ritual Timing
            </p>
          </DialogContent>
          <DialogActions className={classes.paypalBtnWrapper}>
            <Button onClick={handleProModalClose} autoFocus>
              <img alt="subscribe" src={PaypalSVG} style={{ width: "100%" }} />
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default UserTransit;
