import React, { useState, useEffect, useContext } from "react";
// import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Button, Paper, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import useApp from "hooks/useApp";
import { EventChangeContext } from "views/main";
import { aspectsArt, planetsArt, signs, signsArt } from "services/constants";
import { getLocalDateTime } from "services/dataService";
import Countdown from "react-countdown";
import CountdownRenderer from "components/CountdownRenderer";
import ArtWork1 from "../assets/artwork_1.png";
import { URL } from "../services/constants";
import axios from "axios";
import { ThemeContext } from "themes/ThemeProvider";
import clsx from "clsx";

import * as MaterialUI from "@material-ui/core";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

import * as Components from "./../components";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    zIndex: 5,
  },
}));

const EventModal = () => {
  var valueEditing = null;
  const [localDateTime, setLocalDateTime] = useState(null);
  const { themeName } = useContext(ThemeContext);
  const { eventState, handleEventChange } = useContext(EventChangeContext);
  // const isMobile = useMediaQuery("(max-width:600px)");
  const classes = useStyles();
  const [chartImage, setChartImage] = useState(null);
  const [showArtWorkImage, setShowArtWorkImage] = useState(true);
  const { handleEventModalShow } = useApp();
  const [ strEditingItem, setEditingItem ] = useState('');
  console.log(eventState.selectedEvent.artwork);
  useEffect(() => {
    const utcDate = eventState.selectedEvent.date;
    const _localDateTime = getLocalDateTime(
      new Date(
        Date.UTC(
          utcDate.year,
          utcDate.month - 1,
          utcDate.day,
          utcDate.hour,
          utcDate.minute,
          utcDate.second
        )
      ),
      eventState.selectedEvent.offset
    );
    setLocalDateTime(_localDateTime);
  }, [eventState.selectedEvent]);

  useEffect(() => {
    // event changed on global transit or my transit view
    const displayEventChart = async () => {
      if (eventState.selectedEvent) {
        const { date/*, offset*/ } = eventState.selectedEvent;
        // const eventDate = new Date(
        //   date.year,
        //   date.month - 1,
        //   date.day,
        //   offset ? date.hour + offset : date.hour,
        //   date.minute,
        //   date.second
        // );

        let dt = `${date.day}/${date.month}/${date.year}/${date.hour}/${date.minute}/${date.second}`;
        let chartUrl = "";

        chartUrl = `${URL}n=${dt}&svgstring=true`;

        axios.get(chartUrl).then((res) => {
          setChartImage(res.data);
        });
      } else {
      }
    };
    displayEventChart().catch(console.error);
  }, [eventState.selectedEvent]);

  const handleClickViewChart = () => {
    setShowArtWorkImage(!showArtWorkImage);
  };

  const handleEventModalClose = () => {
    handleEventChange(null);
    handleEventModalShow(false);
  };

  const getThemeName = (property = "text-muted") => {
    return themeName === "lightTheme" ? property : `${property}-dark`;
  };

  const handleChangeValue = arg => {
    valueEditing = arg;
  }

  const handleClickButtonEdit = (strItem) => {
    setEditingItem(strItem);    
  }

  const handleClickButtonCancel = () => {
    setEditingItem('');
  }
  
  const handleClickButtonSave = () => {
    switch(strEditingItem) {
      case 'artwork':
        eventState.selectedEvent.artwork = valueEditing;
        break;
      case 'description1':
        eventState.selectedEvent.ritual = valueEditing;
        break;
      case 'description2':
        eventState.selectedEvent.meaning = valueEditing;
        break;
      case 'description3':
        eventState.selectedEvent.description3 = valueEditing;
        break;
      case 'yogadescription':
        eventState.selectedEvent.yogaDescription = valueEditing;
        break;
      case 'yogaimage':
        eventState.selectedEvent.yogaImg = valueEditing;
        break;
    }
    setEditingItem('');
  }

  return (
    <Paper>
      <div
        className={
          themeName === "lightTheme"
            ? "desktop-event-modal-dialog-wrapper"
            : "desktop-event-modal-dialog-wrapper-dark"
        }
      >
        <div className="desktop-event-modal-dialog-title-wrapper">
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleEventModalClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className="event-dialog-chart-header">
          <div
            className="event-dialog-chart-wrapper"
            style={{ width: showArtWorkImage ? "auto" : "100%" }}
          >
            <div
              className="event-dialog-chart-image"
              style={{ width: showArtWorkImage ? "auto" : "100%" }}
              dangerouslySetInnerHTML={{ __html: chartImage }}
              onClick={handleClickViewChart}
            />
            <div>
              {showArtWorkImage && (
                <Button
                  variant="outlined"
                  className="event-dialog-view-chart-button"
                  onClick={handleClickViewChart}
                >
                  View Chart
                </Button>
              )}
            </div>
          </div>
          <div
            style={{
              display: showArtWorkImage ? "block" : "none",
              width: "40%",
            }}
          >
            {eventState.selectedEvent.artwork ? (
              <img
                alt="artwork"
                src={eventState.selectedEvent.artwork}
                style={{ width: "100%" }}
              />
            ) : (
              <img alt="art work" src={ArtWork1} style={{ width: "100%" }} />
            )}
            {strEditingItem === '' && (
              <MaterialUI.Grid container justifyContent="flex-end">
                <Button variant="contained" color="primary" onClick={() => handleClickButtonEdit('artwork')}>EDIT</Button>
              </MaterialUI.Grid>
            )}
          </div>
        </div>
        {strEditingItem === 'artwork' && (
          <MaterialUI.Box>
            <MaterialUI.Box display="block" style={{padding: '5px'}}>
              <TextField variant="outlined" size="small" fullWidth onChange={event => handleChangeValue(event.target.value)} />
            </MaterialUI.Box>
            <MaterialUI.Box display="flex" justifyContent="flex-end">
              <MaterialUI.Box style={{margin: '5px'}}>
                <Button variant="contained" color="secondary" onClick={handleClickButtonCancel}>CANCEL</Button>
              </MaterialUI.Box>
              <MaterialUI.Box style={{margin: '5px'}}>
                <Button variant="contained" color="primary" onClick={handleClickButtonSave}>SAVE</Button>
              </MaterialUI.Box>
            </MaterialUI.Box>
          </MaterialUI.Box>
        )}
        <div>
          <div>
            <div className="event-date-time">
              <h4
                className={
                  eventState.selectedEvent.top
                    ? getThemeName("count-down-label")
                    : ""
                }
              >
                <Countdown
                  date={
                    new Date(
                      `${localDateTime?.year}/${localDateTime?.month}/${localDateTime?.day}
               ${localDateTime?.hour}:${localDateTime?.minute}:${localDateTime?.second}`
                    )
                  }
                  renderer={CountdownRenderer}
                />
              </h4>
              <h4 className="p-0 m-0 mb-8">
                {eventState.selectedEvent.date.utc
                  ? new Date(
                      `${eventState.selectedEvent.date.utc.year}/${eventState.selectedEvent.date.utc.month}/${eventState.selectedEvent.date.utc.day}
               ${eventState.selectedEvent.date.utc.hour}:${eventState.selectedEvent.date.utc.minute}:${eventState.selectedEvent.date.utc.second}`
                    ).toLocaleString("en-US", { hour12: false })
                  : new Date(
                      `${localDateTime?.year}/${localDateTime?.month}/${localDateTime?.day}
               ${localDateTime?.hour}:${localDateTime?.minute}:${localDateTime?.second}`
                    ).toLocaleString("en-US", { hour12: false })}
                {}
              </h4>
            </div>
          </div>
          <div className="planets-wrapper">
            <div className="planets-aspect">
              <div className="flex items-center mb-8">
                <i
                  className={
                    planetsArt[eventState.selectedEvent.planet1] + " mr-12"
                  }
                ></i>
                <h4 className="p-0 m-0 mr-8">
                  {eventState.selectedEvent.planet1}{" "}
                  <span className={clsx(getThemeName())}>in</span>
                </h4>
                <i
                  className={clsx(
                    getThemeName(),
                    signsArt[signs[eventState.selectedEvent.position1.sign]],
                    "mr-8"
                  )}
                ></i>
                <h4 className={clsx(getThemeName(), "p-0 m-0 mr-8")}>
                  {signs[eventState.selectedEvent.position1.sign]}
                </h4>
              </div>
              <div className="flex items-center mb-4">
                <i
                  className={
                    aspectsArt[eventState.selectedEvent.aspect] + " mr-12"
                  }
                ></i>
                <h4 className="p-0 m-0 mr-12">
                  {eventState.selectedEvent.aspect}
                </h4>

                <h4 className={clsx(getThemeName(), "m-0")}>
                  <span>{eventState.selectedEvent.position1.degree}</span>
                  <sup className="mr-8 deg">&deg;</sup>
                </h4>
                <h4 className={clsx(getThemeName(), "m-0")}>
                  <span>{eventState.selectedEvent.position1.min}</span>
                  <sup className="deg">
                    <span>&#x2033;</span>
                  </sup>
                </h4>
              </div>
              <div className="flex items-center mb-8">
                <i
                  className={
                    planetsArt[eventState.selectedEvent.planet2] + " mr-12"
                  }
                ></i>
                <h4 className="p-0 m-0 mr-8">
                  {eventState.selectedEvent.planet2}{" "}
                  <span className={clsx(getThemeName())}>in</span>
                </h4>
                <i
                  className={clsx(
                    getThemeName(),
                    signsArt[signs[eventState.selectedEvent.position2.sign]],
                    "mr-8"
                  )}
                ></i>
                <h4 className={clsx(getThemeName(), "p-0 m-0 mr-8")}>
                  {signs[eventState.selectedEvent.position2.sign]}
                </h4>
              </div>
            </div>
          </div>

          {strEditingItem === 'description1' ? (
            <MaterialUI.Box>
              <Components.Editor theme="snow" value={eventState.selectedEvent.ritual} onChange={handleChangeValue} />
              <MaterialUI.Box display="flex" justifyContent="flex-end">
                <MaterialUI.Box style={{margin: '5px'}}>
                  <Button variant="contained" color="secondary" onClick={handleClickButtonCancel}>CANCEL</Button>
                </MaterialUI.Box>
                <MaterialUI.Box style={{margin: '5px'}}>
                  <Button variant="contained" color="primary" onClick={handleClickButtonSave}>SAVE</Button>
                </MaterialUI.Box>
              </MaterialUI.Box>
            </MaterialUI.Box>
          ) : (eventState.selectedEvent.ritual && (
            <p className="event-dialog-description-one">
              <div dangerouslySetInnerHTML={{__html: eventState.selectedEvent.ritual}}></div>
            </p>
          ))}
          {strEditingItem === '' && (
            <MaterialUI.Box display="flex" justifyContent="flex-end">
              <Button variant="contained" color="primary" onClick={() => handleClickButtonEdit('description1')}>EDIT</Button>
            </MaterialUI.Box>
          )}
        </div>
        <div className="event-dialog-description-wrapper">
          <p className="event-dialog-description-title">Ritual</p>
          {strEditingItem === 'description2' ? (
            <MaterialUI.Box>
              <ReactQuill theme="snow" value={eventState.selectedEvent.meaning} onChange={handleChangeValue} />
              <MaterialUI.Box display="flex" justifyContent="flex-end">
                <MaterialUI.Box style={{margin: '5px'}}>
                  <Button variant="contained" color="secondary" onClick={handleClickButtonCancel}>CANCEL</Button>
                </MaterialUI.Box>
                <MaterialUI.Box style={{margin: '5px'}}>
                  <Button variant="contained" color="primary" onClick={handleClickButtonSave}>SAVE</Button>
                </MaterialUI.Box>
              </MaterialUI.Box>
            </MaterialUI.Box>
          ) : (eventState.selectedEvent.meaning && (
            <p className="event-dialog-description-two">
              <div dangerouslySetInnerHTML={{__html: eventState.selectedEvent.meaning}}></div>
            </p>
          ))}
          {strEditingItem === '' && (
            <MaterialUI.Box display="flex" justifyContent="flex-end">
              <Button variant="contained" color="primary" onClick={() => handleClickButtonEdit('description2')}>EDIT</Button>
            </MaterialUI.Box>
          )}
          {strEditingItem === 'description3' ? (
            <MaterialUI.Box>
              <ReactQuill theme="snow" value={eventState.selectedEvent.description3} onChange={handleChangeValue} />
              <MaterialUI.Box display="flex" justifyContent="flex-end">
                <MaterialUI.Box style={{margin: '5px'}}>
                  <Button variant="contained" color="secondary" onClick={handleClickButtonCancel}>CANCEL</Button>
                </MaterialUI.Box>
                <MaterialUI.Box style={{margin: '5px'}}>
                  <Button variant="contained" color="primary" onClick={handleClickButtonSave}>SAVE</Button>
                </MaterialUI.Box>
              </MaterialUI.Box>
            </MaterialUI.Box>
          ) : (eventState.selectedEvent.description3 && (
            <p className="event-dialog-description-two">
              <div dangerouslySetInnerHTML={{__html: eventState.selectedEvent.description3}}></div>
            </p>
          ))}
          {strEditingItem === '' && (
            <MaterialUI.Box display="flex" justifyContent="flex-end">
              <Button variant="contained" color="primary" onClick={() => handleClickButtonEdit('description3')}>EDIT</Button>
            </MaterialUI.Box>
          )}
        </div>
        <div>
          <p className="event-dialog-recommend-label">Recommended Mudra</p>
          {strEditingItem === 'yogadescription' ? (
            <MaterialUI.Box>
              <ReactQuill theme="snow" value={eventState.selectedEvent.yogaDescription} onChange={handleChangeValue} />
              <MaterialUI.Box display="flex" justifyContent="flex-end">
                <MaterialUI.Box style={{margin: '5px'}}>
                  <Button variant="contained" color="secondary" onClick={handleClickButtonCancel}>CANCEL</Button>
                </MaterialUI.Box>
                <MaterialUI.Box style={{margin: '5px'}}>
                  <Button variant="contained" color="primary" onClick={handleClickButtonSave}>SAVE</Button>
                </MaterialUI.Box>
              </MaterialUI.Box>
            </MaterialUI.Box>
          ) : (eventState.selectedEvent.yogaDescription && (
            <p className="event-dialog-description-two">
              <div dangerouslySetInnerHTML={{__html: eventState.selectedEvent.yogaDescription}}></div>
            </p>
          ))}
          {strEditingItem === '' && (
            <MaterialUI.Box display="flex" justifyContent="flex-end">
              <Button variant="contained" color="primary" onClick={() => handleClickButtonEdit('yogadescription')}>EDIT</Button>
            </MaterialUI.Box>
          )}
          {eventState.selectedEvent.yogaImg ? (
            <img
              alt="artwork"
              src={eventState.selectedEvent.yogaImg}
              style={{ width: "100%" }}
            />
          ) : (
            <img alt="art work" src={ArtWork1} style={{ width: "100%" }} />
          )}
          {strEditingItem === '' && (
            <MaterialUI.Box display="flex" justifyContent="flex-end">
              <Button variant="contained" color="primary" onClick={() => handleClickButtonEdit('yogaimage')}>EDIT</Button>
            </MaterialUI.Box>
          )}
          {strEditingItem === 'yogaimage' && (
            <MaterialUI.Box>
              <MaterialUI.Box display="block" style={{padding: '5px'}}>
                <TextField variant="outlined" size="small" fullWidth onChange={event => handleChangeValue(event.target.value)} />
              </MaterialUI.Box>
              <MaterialUI.Box display="flex" justifyContent="flex-end">
                <MaterialUI.Box style={{margin: '5px'}}>
                  <Button variant="contained" color="secondary" onClick={handleClickButtonCancel}>CANCEL</Button>
                </MaterialUI.Box>
                <MaterialUI.Box style={{margin: '5px'}}>
                  <Button variant="contained" color="primary" onClick={handleClickButtonSave}>SAVE</Button>
                </MaterialUI.Box>
              </MaterialUI.Box>
            </MaterialUI.Box>
          )}
        </div>
      </div>
    </Paper>
  );
};

export default EventModal;
