import React from 'react'
import AdminNavbar from './AdminNavbar'

function Compatibility() {
  return (
    <AdminNavbar>
      Compatibility
    </AdminNavbar>
  )
}

export default Compatibility
