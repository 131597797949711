// import node modules
import axios from "axios";

// import base modules
import CONSTS from "consts";

const getUTCTimeNow = () => {
  const objDateNow = new Date();
  return new Date(Date.UTC(
    objDateNow.getUTCFullYear(),
    objDateNow.getUTCMonth(),
    objDateNow.getUTCDate(),
    objDateNow.getUTCHours(),
    objDateNow.getUTCMinutes(),
    objDateNow.getUTCSeconds()
  ));  
};

const getTimezoneByLocation = async objLocation => {
  const strURL = `${CONSTS.URLS.API_IPGEOLOCATION}timezone?apiKey=${CONSTS.APIKEYS.IPGEOLOCATION}&lat=${objLocation.valLatitude}&long=${objLocation.valLongitude}`;
  const objTimezone = await axios.get(strURL);
  return objTimezone.data.timezone;
};

const Time = {
  getTimezoneByLocation,
  getUTCTimeNow
};

export default Time;