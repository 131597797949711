import firebase from "./firebaseConfig";

export const loginIntoFirebase = async (data) => {
  const { email, password } = data;
  return new Promise((resolve, reject) => {
    firebase.auth().signInWithEmailAndPassword(email, password).then((user) => {
      resolve(user);
    }).catch((error) => {
      reject(error);
    });
  })
};

export const registerIntoFirebase = async (data) => {
  let { email, password } = data;

  let { user } = await firebase
    .auth()
    .createUserWithEmailAndPassword(email, password);

  return user;
};

export const logout = async () => {
  return firebase.auth().signOut();
};

export const forgotPassword = async (email) => {
  firebase.auth().sendPasswordResetEmail(email)
      .then(function (user) {
        alert('Please check your email...')
      }).catch(function (e) {
        console.log(e)
        alert(e)
      })
}

export const getLoginStatus = async (callback) => {
  return await firebase.auth().onAuthStateChanged(callback);
};

export const getUserData = async (callback) => {
  firebase.auth().onAuthStateChanged(async (user) => {
    if (user && user.uid) {
      firebase.database().ref(`/users`).child(user.uid).on("value", callback);
    } else callback({ val: () => null });
  });
};
