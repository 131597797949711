import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";

const autocompleteService = { current: null };

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2)
  },
  root: {
    fontSize: "14px !important",
    "& div": {
      [theme.breakpoints.down('xs')]: {
        fontSize: '10px',
      },
      fontSize: "14px",
    },
  },
}));

export default function GoogleMaps({ onAddressChange, address, label = 'Add a location', onAutocompleteChange = null, required = false, className = '', outlined = false,}) {
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState("");

  const [options, setOptions] = React.useState([]);

  const handleChange = event => {
    let value = event.target.value;
    setInputValue(value);
  };

  const fetch = React.useMemo(
    () =>
      throttle((input, callback) => {
        autocompleteService.current.getPlacePredictions(input, callback);
      }, 250),
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions([]);
      return undefined;
    }

    fetch({ input: inputValue }, results => {
      if (active) {
        setOptions(results || []);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  return (
    <Autocomplete
      getOptionLabel={option =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={x => x}
      options={options}
      autoComplete
      value={address}
      freeSolo
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        onAutocompleteChange(newValue);
      }}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          name="address"
          variant={outlined ? "outlined" : "standard"}
          fullWidth
          placeholder="Add location"
          className={`${classes.root} ${className}`}
          required={required}
          color="secondary"
          onSelect={onAddressChange}
          onBlur={onAddressChange}
          onChange={handleChange}
        />
      )}
      renderOption={option => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map(match => [match.offset, match.offset + match.length])
        );

        return (
          <Grid container alignItems="center" justifyContent="flex-start" spacing={3}>
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
