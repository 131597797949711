import React, { useState } from "react";
import { loginIntoFirebase } from "../firebase/firebaseAuthService";
import { Link } from "react-router-dom";
import {
  TextField,
  Button,
  Grid,
  CssBaseline,
  Box,
  Container,
  ThemeProvider,
} from "@material-ui/core";
import { LockOutlined } from "@material-ui/icons";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import { deepOrange, deepPurple } from "@material-ui/core/colors";
import history from "../history";
import { Copyright } from "./Register";
import useApp from "hooks/useApp";
import LogoSVG from "../assets/svg/logo.svg";

const theme = createTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#1554F6",
      dark: "#002884",
      contrastText: "#fff",
    },
    secondary: {
      light: "#757575",
      main: "#757575",
      dark: "#757575",
      contrastText: "#757575",
    },
  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    "& > svg": {
      marginTop: "0 !important",
    },
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
}));

const Login = () => {
  const classes = useStyles();
  const [formValue, setFormValue] = useState({});
  const { handleSlideMenuOpen } = useApp();
  const handleChange = ({ target }) => {
    setFormValue({ ...formValue, [target.name]: target.value });
  };

  const handleSubmit = async (e) => {
    handleSlideMenuOpen(false);
    e.preventDefault();
    try {
      const user = await loginIntoFirebase(formValue);
      history.push("/");
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  const handleClickForgotPassword = async () => {
    history.push("/forgot-password");
  };

  return (
    <>
      {/* <ThemeProvider theme={theme}> */}
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "20vh",
              alignItems: "center",
            }}
          >
            {/* <Avatar className={classes.orange}> */}
            <Box sx={{marginBottom : "30px"}}>
              <img alt="logo" src={LogoSVG} />
            </Box>
            {/* </Avatar> */}
            {/* <Typography component="h1" variant="h5">
              Sign in
            </Typography> */}
            <div className="mb-16"></div>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                type="email"
                label="Email"
                variant="outlined"
                placeholder="john@smith.com"
                className="mb-16"
                name="email"
                fullWidth
                autoComplete="email"
                autoFocus
                color="secondary"
                onChange={handleChange}
              />
              <TextField
                type="password"
                label="Password"
                variant="outlined"
                placeholder="••••••••"
                className="mb-16"
                name="password"
                color="secondary"
                fullWidth
                onChange={handleChange}
              />
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <Button
                type="submit"
                fullWidth
                color="primary"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid
                container
                className="my-8"
                alignItems="center"
                justifyContent="center"
              >
                {/* <Grid item xs>
                  <Link to="/login" style={{ textDecoration: "underline" }}>
                    Forgot password?
                  </Link>
                </Grid> */}
                <Grid item>
                  <p>
                    New to RitualUp?{" "}
                    <Link
                      to="/register"
                      style={{ textDecoration: "underline" }}
                    >
                      Create an account.
                    </Link>
                  </p>
                </Grid>
              </Grid>
              <Grid
                container
                className="my-8"
                alignItems="center"
                justifyContent="center"
              >
                {/* <Grid item xs>
                  <Link to="/login" style={{ textDecoration: "underline" }}>
                    Forgot password?
                  </Link>
                </Grid> */}
                <Grid item>
                  <Button
                    style={{
                      textDecoration: "underline",
                      textTransform: "none",
                    }}
                    onClick={handleClickForgotPassword}
                  >
                    Forgot your password?
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      {/* </ThemeProvider> */}
    </>
  );
};

export default Login;
