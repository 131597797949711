import { createTheme } from "@material-ui/core";

export const lightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#1554F6",
    },
    secondary: {
      main: "#F71515",
    },
    common: {
      black: "#000",
      white: "#fff",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(255, 255, 255, 0.5)",
    },
    divider: "rgba(0, 0, 0, 0.12)",    
    background: {
      paper: "#fff",
      default: "#fafafa",
      black: "#fff",
      darkGrey: "#fff"
    },
    indicator: {
      default: "#1e1f1f"
    },
    tab: {
      selected: "#1e1f1f",
      hover: "#1f1e1e",
      focus: "#1f1e1e"
    },
    toolbar: {
      default: "#f5f5f5"
    },
    filterIcon: {
      default: "rgba(0, 0, 0, 0.2)",
      selected: "#323232"
    },
    chartSearch: {
      background: "#EFEFEF"
    }
  },
});
