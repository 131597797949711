import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
// import LocationOnIcon from "@material-ui/icons/LocationOn";
import { getTimezones } from "services/utils";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  root: {
    fontSize: "14px !important",
    "& div": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px",
      },
      fontSize: "14px",
    },
  },
}));

const TimezoneList = ({
  onAddressChange,
  selectedTimezone,
  label = "Add a location",
  onAutocompleteChange = null,
  required = false,
  className = "",
  outlined = false,
}) => {
  const classes = useStyles();

  const timezones = React.useMemo(() => getTimezones(), []);
  return (
    <Autocomplete
      getOptionLabel={(option) => option.label}
      // filterOptions={(x) => x}
      options={timezones}
      // autoComplete
      value={selectedTimezone}
      freeSolo
      onChange={(event, newValue) => {
        onAutocompleteChange(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          name="address"
          variant={outlined ? "outlined" : "standard"}
          fullWidth
          placeholder="Add location"
          className={`${classes.root} ${className}`}
          required={required}
          color="secondary"
          // onSelect={onAddressChange}
          // onBlur={onAddressChange}
        />
      )}
      // renderOption={(option) => {
      //   return (
      //     <Grid
      //       container
      //       alignItems="center"
      //       justifyContent="flex-start"
      //       spacing={3}
      //     >
      //       <Grid item>
      //         <LocationOnIcon className={classes.icon} />
      //       </Grid>
      //       <Grid item xs>
      //         <span style={{ fontWeight: 700 }}>
      //           {option.label}
      //         </span>
      //       </Grid>
      //     </Grid>
      //   );
      // }}
    />
  );
};

export default TimezoneList;
