import React from 'react'
import AdminNavbar from './AdminNavbar'

function Aspects() {
  return (
    <AdminNavbar>
      Aspects
    </AdminNavbar>
  )
}

export default Aspects
