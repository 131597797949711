import React, { useReducer, Suspense, useContext } from "react";
import { Box, AppBar, Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles, withStyles } from "@material-ui/core/styles";
// import Drawer from "@material-ui/core/Drawer";
import PropTypes from "prop-types";
import TransitViewer from "./TransitViewer";
import UserTransit from "./UserTransit";
import { createContext } from "react";
import PlanetsDetail from "./PlanetsDetail";
import useApp from "hooks/useApp";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import history from "../history";
import ProUser from "components/ProUser";
import EventModal from "components/EventModal";
import { ThemeContext } from "themes/ThemeProvider";
import { Helmet } from "react-helmet";

import clsx from "clsx";

// import react modules
import * as ReactRouterDom from "react-router-dom";

// import base modules
import Contexts from "contexts";
import Chart from "./chart";
import Utils from "utils";

// const DrawChart = React.lazy(() => import("./DrawChart"));
// const TransitViewer = React.lazy(() => import("./TransitViewer"));
// const UserTransit = React.lazy(() => import("./UserTransit"));

const drawerWidth = 250;

const initialState = {
  selectedEvent: null,
  selectedDate: new Date(),
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SELECTED": {
      return {
        ...state,
        selectedEvent: action.event,
      };
    }
    case "DESELECTED": {
      return {
        ...state,
        selectedEvent: null,
      };
    }
    case "DATE_CHANGED": {
      return { ...state, selectedDate: action.date };
    }
    default:
      return state;
  }
};

//context

export const EventChangeContext = createContext();

export const TAB_TITLE = {
  global: "Global Transits",
  myTransit: "My Transits",
  details: "Details",
};
const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginRight: drawerWidth,
    },
  },
  containerShift: {
    [theme.breakpoints.up("sm")]: {
      width: `100%`,
      marginRight: 0,
    },
  },
  drawChart: {
    background: theme.palette.background.default,
  },
  tabContainer: {
    background: theme.palette.background.default,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

//

const AntTabs = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid #e8e8e8",
    height: "48px",
    minHeight: "48px",
    [theme.breakpoints.down("xs")]: {
      height: "30px",
      minHeight: "30px",
    },
  },
  indicator: {
    backgroundColor: theme.palette.indicator.default,
  },
}))(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      minHeight: "30px",
    },
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: theme.palette.tab.hover,
      opacity: 1,
    },
    "&$selected": {
      color: theme.palette.tab.selected,
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: theme.palette.tab.focus,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

//

const Main = props => {
  Utils.Log('[Main]');

  const {
    valMode, setMode
  } = React.useContext(Contexts.Chart);

  const objRouterLocation = ReactRouterDom.useLocation();
  switch(objRouterLocation.pathname) {
    case '/':
      setMode(0);
      break;
    case '/me':
      setMode(1);
      break;
    case '/user-chart':
      setMode(2);
      break;
  }

  const [value, setValue] = React.useState(0);
  const { window } = props;
  const tabIndex = valMode;
  const { tabState, handleSlideMenuOpen, handleSlideDesktopMenuOpen } =
    useApp();
  const classes = useStyles();
  const { themeName } = useContext(ThemeContext);
  const [eventState, dispatch] = useReducer(reducer, initialState);
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleEventChange = (event) => {
    if (event) dispatch({ type: "SELECTED", event });
    else dispatch({ type: "DESELECTED", event });
  };

  const handleDateChange = (date) => {
    if (date) dispatch({ type: "DATE_CHANGED", date });
  };

  const toggleSlideMenu = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (isMobile) {
      handleSlideMenuOpen(open);
    } else {
      handleSlideDesktopMenuOpen(open);
    }
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <EventChangeContext.Provider
        value={{ eventState, handleEventChange, handleDateChange }}
      >
        <Helmet bodyAttributes={{style: themeName === "lightTheme" ? 'background-color : #fff' : 'background-color : #232323'}}>
          
        </Helmet>
        <Suspense fallback={<div>Loading...</div>}>
          <Grid
            container
            className={
              isMobile
                ? classes.containerShift
                : tabState.slideDesktopMenuOpen
                ? classes.container
                : classes.containerShift
            }
          >
            <Grid item xs={12} md={6} lg={7}>
              <Box
                height={`100%`}
                borderRight="1px solid #dcd9d9"
                className={classes.drawChart}
              >
                {/*<DrawChart tabIndex={tabIndex} mode={props.mode}/>*/}
                <Chart />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={5} style={{ position: "relative" }}>
              {isMobile ? (
                <Box
                  bgcolor=""
                  fontSize={14}
                  className={clsx(classes.tabContainer, "tabContainer")}
                >
                  <Paper square style={{ boxShadow: "none" }}>
                    <AppBar position="static" color="default">
                      <AntTabs
                        value={value}
                        centered
                        variant="fullWidth"
                        onChange={handleChange}
                        aria-label="ant example"
                      >
                        {tabIndex === 0 && <AntTab label={TAB_TITLE.global} />}
                        {tabIndex === 1 && (
                          <AntTab label={TAB_TITLE.myTransit} />
                        )}
                        {tabIndex === 2 && (
                          <AntTab
                            label={`${tabState.selectedChart.name}'s Transits`}
                          />
                        )}
                        {/* {tabState.selectedTabIndex === 0 && (
                        <AntTab label={TAB_TITLE.global} />
                      )}
                      {tabState.selectedTabIndex === 1 && (
                        <AntTab label={TAB_TITLE.myTransit} />
                      )} */}

                        <AntTab label={TAB_TITLE.details} />
                      </AntTabs>
                      {/* <Tabs
                      value={value}
                      centered
                      variant="fullWidth"
                      indicatorColor="primary"
                      textColor="primary"
                      onChange={handleChange}
                      aria-label="disabled tabs example"
                    >
                      <Tab label={TAB_TITLE.global} style={tabCss}/>
                      <Tab label={TAB_TITLE.myTransit} />
                      <Tab label={TAB_TITLE.details} />
                    </Tabs> */}
                    </AppBar>
                    {tabIndex === 0 && (
                      <TabPanel value={value} index={0}>
                        <TransitViewer />
                      </TabPanel>
                    )}
                    {tabIndex === 1 && (
                      <TabPanel value={value} index={0}>
                        <UserTransit tabIndex={1} />
                      </TabPanel>
                    )}
                    {tabIndex === 2 && (
                      <TabPanel value={value} index={0}>
                        <UserTransit tabIndex={2} />
                      </TabPanel>
                    )}
                    <TabPanel value={value} index={1}>
                      <PlanetsDetail />
                    </TabPanel>
                  </Paper>
                </Box>
              ) : (
                <>
                  {tabState.proUserModalShow && <ProUser />}
                  {tabState.selectedEventModalShow &&
                    eventState.selectedEvent && <EventModal />}
                  <Box
                    bgcolor=""
                    fontSize={14}
                    className={clsx(classes.tabContainer, "tabContainer")}
                  >
                    <Paper square style={{ boxShadow: "none" }}>
                      <AppBar position="static" color="default">
                        <AntTabs
                          value={value}
                          centered
                          variant="fullWidth"
                          onChange={handleChange}
                          aria-label="ant example"
                        >
                          {tabIndex === 0 && (
                            <AntTab label={TAB_TITLE.global} />
                          )}
                          {tabIndex === 1 && (
                            <AntTab label={TAB_TITLE.myTransit} />
                          )}
                          {tabIndex === 2 && (
                            <AntTab
                              label={`${tabState.selectedChart.name}'s Transits`}
                            />
                          )}
                          {/* {tabState.selectedTabIndex === 0 && (
                          <AntTab label={TAB_TITLE.global} />
                        )}
                        {tabState.selectedTabIndex === 1 && (
                          <AntTab label={TAB_TITLE.myTransit} />
                        )} */}

                          <AntTab label={TAB_TITLE.details} />
                        </AntTabs>
                        {/* <Tabs
                        value={value}
                        centered
                        variant="fullWidth"
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChange}
                        aria-label="disabled tabs example"
                      >
                        <Tab label={TAB_TITLE.global} style={tabCss}/>
                        <Tab label={TAB_TITLE.myTransit} />
                        <Tab label={TAB_TITLE.details} />
                      </Tabs> */}
                      </AppBar>
                      {tabIndex === 0 && (
                        <TabPanel value={value} index={0}>
                          <TransitViewer />
                        </TabPanel>
                      )}
                      {tabIndex === 1 && (
                        <TabPanel value={value} index={0}>
                          <UserTransit tabIndex={1} />
                        </TabPanel>
                      )}
                      {tabIndex === 2 && (
                        <TabPanel value={value} index={0}>
                          <UserTransit tabIndex={2} />
                        </TabPanel>
                      )}
                      <TabPanel value={value} index={1}>
                        <PlanetsDetail />
                      </TabPanel>
                    </Paper>
                  </Box>
                </>
              )}
            </Grid>
          </Grid>
          {/* <nav className={classes.drawer} aria-label="mailbox folders">
            <Hidden mdUp implementation="css">
              <Drawer
                container={container}
                variant="temporary"
                anchor={"right"}
                open={tabState.slideMenuOpen && isMobile}
                onClose={toggleSlideMenu("right", false)}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                <SlideMenu />
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                anchor="right"
                variant="persistent"
                open={!isMobile && tabState.slideDesktopMenuOpen}
              >
                <SlideMenu />
              </Drawer>
            </Hidden>
          </nav> */}
        </Suspense>
      </EventChangeContext.Provider>
    </>
    // <Box display="flex" flexWrap="wrap" height={`100vh`}>

    // </Box>
  );
};

export default Main;
