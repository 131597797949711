const APIKEYS = {
  GOOGLEGEOCODE: 'AIzaSyCfC1I-h9CHPL4N5TzLRqgzreIaNu7Gxs0',
  IPGEOLOCATION: '307352210abf41d29ac8c0a2425f4ecd'
};

const URLS = {
  API_IPGEOLOCATION: 'https://api.ipgeolocation.io/',
  SERVER: (process.env.NODE_ENV === 'development' ? 'http://localhost:8080/' : 'https://ritualup.net/')
};

const CONSTS = {
  APIKEYS,
  URLS
};

export default CONSTS;