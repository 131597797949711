import React, { useEffect, useState } from "react";
import AdminNavbar from "../AdminNavbar";
import SelectionMenu from "./SelectionMenu";
import AstroPartsCards from "./AstroPartsCards";
import { Container, Box, Button } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import firebase from "firebase/firebaseConfig";
import usePrevious from "services/usePrevious";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useApp from "hooks/useApp";

const drawerWidth = 250;
const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginRight: drawerWidth,
    },
  },
  containerShift: {
    [theme.breakpoints.up("sm")]: {
      width: `100%`,
      marginRight: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
}));

const useInfoObjects = ({
  // Search options
  sign = "",
  planet = "",
  element = "",
  house = "",
  back = 0,
  next = 0,
}) => {
  const [infoObjects, setInfoObjects] = useState([]);

  const pageSize = 12;

  const signOrPlanetOrNotHouse = Boolean(sign || planet || !house);

  const elementOrSignOrPlanetOrNotHouse = signOrPlanetOrNotHouse || !!element;

  const planetIsAllWithOther = Boolean(
    planet === "all" && (sign || element || house)
  );
  const signIsAllWithOther = Boolean(
    sign === "all" && (planet || element || house)
  );
  const elementIsAllWithOther = Boolean(
    element === "all" && (sign || element || house)
  );
  const houseIsAllWithOther = Boolean(
    house === "all" && (sign || element || house)
  );

  useEffect(() => {
    let field = firebase.firestore.FieldPath.documentId();
    let query = firebase.firestore().collection("info-objects");
    let label = "id";
    let allCounter = 0;

    if (planet === "all") allCounter++;
    if (sign === "all") allCounter++;
    if (element === "all") allCounter++;
    if (house === "all") allCounter++;

    const planetElementOneHouse = allCounter >= 1 && !!planet && !!element;

    if (allCounter !== 3 && !planetElementOneHouse) {
      if (!sign && !planet && !element && signOrPlanetOrNotHouse) return;

      if (sign !== "all" && !element && signOrPlanetOrNotHouse) {
        query = query.where("sign", "==", sign);
      }
      if (planet !== "all" && elementOrSignOrPlanetOrNotHouse) {
        query = query.where("planet", "==", planet);
      }
      if (house !== "all") {
        query = query.where("house", "==", house.substr(0, house.length - 2));
      }
      if (element !== "all" && !sign && elementOrSignOrPlanetOrNotHouse) {
        query = query.where("element", "==", element);
      }

      if (planetIsAllWithOther && sign !== "all" && house !== "all") {
        query = query.where("planet", ">", "");
        field = "planet";
      }

      if (signIsAllWithOther && planet !== "all" && house !== "all") {
        query = query.where("sign", ">", "");
        field = "sign";
      }

      if (elementIsAllWithOther && planet !== "all" && house !== "all") {
        query = query.where("element", ">", "");
        field = "element";
      }

      if (
        houseIsAllWithOther &&
        element !== "all" &&
        planet !== "all" &&
        sign !== "all"
      ) {
        query = query.where("house", ">", "");
        field = "house";
      }
    } else if (planetElementOneHouse) {
      if (house === "all") {
        query = query
          .where("planet", "==", planet)
          .where("element", "==", element);
      } else {
        query = query.where("house", "==", house.substr(0, house.length - 2));
      }
    } else {
      field = "title";
      label = "title";
    }

    if (next > 0) {
      if (infoObjects.length < 12) {
        alert("No next page available");
        return;
      }
      query = query
        .orderBy(field)
        .startAfter(infoObjects[pageSize - 1][label])
        .limit(pageSize);
    } else if (back > 0 && infoObjects.length !== 0) {
      query = query
        .orderBy(field)
        .endBefore(infoObjects[0][label])
        .limitToLast(pageSize);
    } else {
      query = query.orderBy(field).limit(pageSize);
    }

    const unsubscribe = query.onSnapshot((snapshot) => {
      const newInfoObjects = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setInfoObjects(newInfoObjects);
    });

    // When the react component is unmonted, then unsubscribe from the Firestore web socket
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sign, planet, element, house, back, next]);

  return infoObjects;
};

function AstroParts() {
  const [isFirstLoad, setFirstLoad] = useState(true);
  const { tabState, handleSlideMenuOpen, handleSlideDesktopMenuOpen } =
    useApp();
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [searchOptions, setSearchOptions] = useState({});
  let [next, setNext] = useState(0);
  let [back, setBack] = useState(0);
  const infoObjects = useInfoObjects(searchOptions);
  const prevSearchOption = usePrevious(searchOptions);

  const handleSearchOptions = (searchOptions) => () => {
    const { sign, planet, element, house, next, back } = searchOptions;
    if (!sign && !planet && !element && !house) {
      alert("Need search options");
      return;
    }
    // If it's the initial load, don't show the spinner.
    setFirstLoad(false);

    // Duplicate entry
    if (JSON.stringify(prevSearchOption) === JSON.stringify(searchOptions))
      return;

    if (back === 0 && next === 0) {
      setBack(0);
      setNext(0);
    }

    if (searchOptions?.next > 0) setNext(next);
    if (searchOptions?.back > 0) setBack(back);

    setSearchOptions(searchOptions);
  };

  return (
    <div
      className={
        isMobile
          ? classes.containerShift
          : tabState.slideDesktopMenuOpen
          ? classes.container
          : classes.containerShift
      }
    >
      {/* <div>
        <Button
          onClick={() => {
            history.push("/");
          }}
        >
          Back
        </Button>
      </div> */}
      <AdminNavbar>
        <Container>
          <SelectionMenu onClick={handleSearchOptions} />
          <Box
            mt={3}
            display="flex"
            justifyContent="space-between"
            maxWidth={600}
            marginRight="auto"
            marginLeft="auto"
          >
            <Button
              disabled={
                Boolean(!searchOptions.next && !searchOptions.back) ||
                back - next === 0
              }
              onClick={handleSearchOptions({
                ...searchOptions,
                next: -1,
                back: ++back,
              })}
              color="secondary"
              variant="contained"
            >
              Back
            </Button>
            <Button
              disabled={infoObjects.length < 12 && (back !== 0 || next !== 0)}
              onClick={handleSearchOptions({
                ...searchOptions,
                next: ++next,
                back: -1,
              })}
              color="secondary"
              variant="contained"
            >
              Next
            </Button>
          </Box>
          {infoObjects.length > 0 && (back !== 0 || next !== 0) ? (
            <AstroPartsCards infoObjects={infoObjects} />
          ) : isFirstLoad ? (
            ""
          ) : infoObjects.length === 0 && next < 1 ? (
            <Box mt={3} justifyContent="center" display="flex">
              <CircularProgress />
            </Box>
          ) : (
            ""
          )}
        </Container>
      </AdminNavbar>
    </div>
  );
}

export default AstroParts;
