import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import {
  SettingsOutlined,
  AddCircle,
  AccountCircle,
  SortByAlpha,
  Schedule,
  Search,
  Edit,
} from "@material-ui/icons";
import {
  Typography,
  Button,
  Grid,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListSubheader,
} from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import useApp from "hooks/useApp";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import history from "../history";
import GoogleMaps from "./GoogleLocation";
import {
  filterByKeyword,
  sortByAlphabetical,
  sortByNewest,
} from "services/utils";
import AccountSVG from "../assets/svg/account.svg";
import DarkAccountSVG from "../assets/svg/account-dark.svg";
import LogoutSVG from "../assets/svg/log_out.svg";
import { logout } from "firebase/firebaseAuthService";
import moment from "moment";
import {
  editUserChart,
  getFirebaseUserCharts,
  saveUserChart,
  updateUserProfile,
} from "services/dataService";
import { useEffect } from "react";
import { Fragment } from "react";
import { ThemeContext } from "themes/ThemeProvider";

import * as MaterialUI from "@material-ui/core";
import * as MaterialIcons from "@material-ui/icons";
import { AppContext } from "AppContext";

// import base modules
import Contexts from "contexts";

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: "#9c9797",
  },
  toolbar: {
    maxHeight: 54,
    height: 54,
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.toolbar.default,
  },
  list: {
    width: "100%",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    // backgroundColor: grey["300"],
    "&:hover": {
      // backgroundColor: grey["400"],
    },
    // marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(0),
      width: "auto",
    },
    display: "flex",
    alignItems: "center",
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    paddingLeft: 16,
    paddingRight: 8,
    height: "100%",
    // position: "absolute",
    // pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  inputWrapper: {
    background: theme.palette.chartSearch.background,
    borderRadius: 4,
    paddingLeft: 12,
    marginRight: 8,
  },
  inputRoot: {},
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    // paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  toggleButton: {
    height: 36,
    fontSize: "0.6rem",
    paddingLeft: 8,
    paddingRight: 7,
  },
  filterIconSelected: {
    color: theme.palette.filterIcon.selected
  },
  filterIconDeselected: {
    color: theme.palette.filterIcon.default
  },
  newChartIcon: {
    color: "#00ADF5",
  },
  zoomIcon: {
    width: 20,
  },
  paperScrollPaper: {
    marginTop: -300,
  },
  listSubheader: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '28px'
  },
  textField: {
    width: '44px'
  }
}));

const AppSettings = () => {
  const styles = useStyles();
  const { themeName, setThemeName } = useContext(ThemeContext);
  const { objSettings, setSettings } = useContext(AppContext);

  const handleChangeTheme = () => {
    if (themeName === "lightTheme") {
      setThemeName("darkTheme");
    } else {
      setThemeName("lightTheme");
    }
  };

  const [strHouseSystem, setHouseSystem] = useState("P");

  const handleChangeSwitch = event => {
    setSettings({
      ...objSettings,
      [event.target.name]: event.target.checked
    });
  }

  const handleChangeEdit = event => {
    setSettings({
      ...objSettings,
      [event.target.name]: Math.min(event.target.value, 30)
    })
  }

  return (
    <>
      <ListItem button onClick={handleChangeTheme}>
        <ListItemIcon> <MaterialIcons.InvertColors /> </ListItemIcon>
        <ListItemText primary="Change Theme" />
      </ListItem>
      <ListSubheader classes={{root: styles.listSubheader}} disableSticky>House System</ListSubheader>
      <ListItem>
        <ListItemText>
          <MaterialUI.Select variant="outlined" fullWidth value={objSettings.strHouseSystem} onChange={event => setSettings({
            ...objSettings,
            strHouseSystem: event.target.value
          })}>
            <MaterialUI.MenuItem value={"P"}>Placidus</MaterialUI.MenuItem>
            <MaterialUI.MenuItem value={"W"}>Whole Sign</MaterialUI.MenuItem>
            <MaterialUI.MenuItem value={"E"}>Equal Sign</MaterialUI.MenuItem>
            <MaterialUI.MenuItem value={"K"}>Koch</MaterialUI.MenuItem>
            <MaterialUI.MenuItem value={"O"}>Porphyrius</MaterialUI.MenuItem>
            <MaterialUI.MenuItem value={"R"}>Regiomontanus</MaterialUI.MenuItem>
            <MaterialUI.MenuItem value={"C"}>Campanus</MaterialUI.MenuItem>
          </MaterialUI.Select>
        </ListItemText>
      </ListItem>
      <ListSubheader classes={{root: styles.listSubheader}} disableSticky>Chart Details</ListSubheader>
      <ListItem>
        <ListItemIcon>
          <MaterialUI.Checkbox name="isShowMinutes" color="primary" checked={objSettings.isShowMinutes} onChange={handleChangeSwitch} />
        </ListItemIcon>
        <ListItemText primary="Show Minutes" />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <MaterialUI.Checkbox name="isShowRetrograde" color="primary" checked={objSettings.isShowRetrograde} onChange={handleChangeSwitch} />
        </ListItemIcon>
        <ListItemText primary="Show Retrograde" />
      </ListItem>
      <ListSubheader classes={{root: styles.listSubheader}} disableSticky>Planets</ListSubheader>
      <ListItem>
        <ListItemText primary="Sun" />
        <ListItemSecondaryAction>
          <MaterialUI.Switch color="primary" name="planetsSun" checked={objSettings.planetsSun} onChange={handleChangeSwitch}/>
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemText primary="Moon" />
        <ListItemSecondaryAction>
          <MaterialUI.Switch color="primary" name="planetsMoon" checked={objSettings.planetsMoon} onChange={handleChangeSwitch}/>
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemText primary="Mercury" />
        <ListItemSecondaryAction>
          <MaterialUI.Switch color="primary" name="planetsMercury" checked={objSettings.planetsMercury} onChange={handleChangeSwitch} />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemText primary="Venus" />
        <ListItemSecondaryAction>
          <MaterialUI.Switch color="primary" name="planetsVenus" checked={objSettings.planetsVenus} onChange={handleChangeSwitch} />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemText primary="Mars" />
        <ListItemSecondaryAction>
          <MaterialUI.Switch color="primary" name="planetsMars" checked={objSettings.planetsMars} onChange={handleChangeSwitch} />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemText primary="Jupiter" />
        <ListItemSecondaryAction>
          <MaterialUI.Switch color="primary" name="planetsJupiter" checked={objSettings.planetsJupiter} onChange={handleChangeSwitch} />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemText primary="Saturn" />
        <ListItemSecondaryAction>
          <MaterialUI.Switch color="primary" name="planetsSaturn" checked={objSettings.planetsSaturn} onChange={handleChangeSwitch} />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemText primary="Uranus" />
        <ListItemSecondaryAction>
          <MaterialUI.Switch color="primary" name="planetsUranus" checked={objSettings.planetsUranus} onChange={handleChangeSwitch} />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemText primary="Neptune" />
        <ListItemSecondaryAction>
          <MaterialUI.Switch color="primary" name="planetsNeptune" checked={objSettings.planetsNeptune} onChange={handleChangeSwitch} />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemText primary="Pluto" />
        <ListItemSecondaryAction>
          <MaterialUI.Switch color="primary" name="planetsPluto" checked={objSettings.planetsPluto} onChange={handleChangeSwitch} />
        </ListItemSecondaryAction>
      </ListItem>
      <ListSubheader classes={{root: styles.listSubheader}} disableSticky>Nodes</ListSubheader>
      <ListItem>
        <ListItemText primary="North Node (True)" />
        <ListItemSecondaryAction>
          <MaterialUI.Switch color="primary" name="nodesNorthNode" checked={objSettings.nodesNorthNode} onChange={handleChangeSwitch} />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemText primary="North Node (Median)" />
        <ListItemSecondaryAction>
          <MaterialUI.Switch color="primary" name="nodesNorthNodeMedian" checked={objSettings.nodesNorthNodeMedian} onChange={handleChangeSwitch} />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemText primary="South Node (True)" />
        <ListItemSecondaryAction>
          <MaterialUI.Switch color="primary" name="nodesSouthNode" checked={objSettings.nodesSouthNode} onChange={handleChangeSwitch} />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemText primary="South Node (Median)" />
        <ListItemSecondaryAction>
          <MaterialUI.Switch color="primary" name="nodesSouthNodeMedian" checked={objSettings.nodesSouthNodeMedian} onChange={handleChangeSwitch} />
        </ListItemSecondaryAction>
      </ListItem>
      <ListSubheader classes={{root: styles.listSubheader}} disableSticky>Points</ListSubheader>
      <ListItem>
        <ListItemText primary="Ascendant" />
        <ListItemSecondaryAction>
          <MaterialUI.Switch color="primary" name="pointsAscendant" checked={objSettings.pointsAscendant} onChange={handleChangeSwitch} />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemText primary="Midheaven" />
        <ListItemSecondaryAction>
          <MaterialUI.Switch color="primary" name="pointsMidheaven" checked={objSettings.pointsMidheaven} onChange={handleChangeSwitch} />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemText primary="Decendant" />
        <ListItemSecondaryAction>
          <MaterialUI.Switch color="primary" name="pointsDecendant" checked={objSettings.pointsDecendant} onChange={handleChangeSwitch} />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemText primary="Illiumcolli" />
        <ListItemSecondaryAction>
          <MaterialUI.Switch color="primary" name="pointsIlliumcolli" checked={objSettings.pointsIlliumcolli} onChange={handleChangeSwitch} />
        </ListItemSecondaryAction>
      </ListItem>
      <ListSubheader classes={{root: styles.listSubheader}} disableSticky>Chart Aspect Orders</ListSubheader>
      <ListItem>
        <ListItemText primary="Conjunction" />
        <ListItemSecondaryAction>
          <MaterialUI.TextField variant="outlined" size="small" classes={ {root: styles.textField }} name="orbsConjunction" value={objSettings.orbsConjunction} onChange={handleChangeEdit} />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemText primary="Opposition" />
        <ListItemSecondaryAction>
          <MaterialUI.TextField variant="outlined" size="small" classes={ {root: styles.textField }} name="orbsOpposition" value={objSettings.orbsOpposition} onChange={handleChangeEdit} />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemText primary="Square" />
        <ListItemSecondaryAction>
          <MaterialUI.TextField variant="outlined" size="small"  classes={ {root: styles.textField }} name="orbsSquare" value={objSettings.orbsSquare} onChange={handleChangeEdit} />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemText primary="Trine" />
        <ListItemSecondaryAction>
          <MaterialUI.TextField variant="outlined" size="small" classes={ {root: styles.textField }} name="orbsTrine" value={objSettings.orbsTrine} onChange={handleChangeEdit} />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemText primary="Sextile" />
        <ListItemSecondaryAction>
          <MaterialUI.TextField variant="outlined" size="small" classes={ {root: styles.textField }} name="orbsSextile" value={objSettings.orbsSextile} onChange={handleChangeEdit} />
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
}

const SlideMenu = () => {
  
  const {
    setMode
  } = React.useContext(Contexts.Chart);

  const classes = useStyles();
  const { themeName, setThemeName } = useContext(ThemeContext);
  const {
    user,
    tabState,
    handleTabDeselect,
    handleNewChartDialog,
    handleSlideMenuOpen,
    handleActiveChartChange,
    handleFilteredUserChartChange,
    handleOriginalUserChartChange,
    handleSwitchNewChartAndEditProfile,
    handleChartEdited,
    handleSlideDesktopMenuOpen,
  } = useApp();
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("alpha"); // 0: alpha, 1: newest
  const [editChartDialogOpen, setEditChartDialogOpen] = useState(false);
  const [editChartFormValue, setEditChartFormValue] = useState({
    name: "",
    address: "",
    dateTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
    cid: "",
  });
  const isMobile = useMediaQuery("(max-width:600px)");

  const LoggedInMenuList = [
    {
      label: "Account",
      icon: themeName === "lightTheme" ? AccountSVG : DarkAccountSVG,
      hasSubItem: true,
      subItems: [
        {
          label: "Admin",
          icon: themeName === "lightTheme" ? AccountSVG : DarkAccountSVG,
          disabled: user && user.isAdmin ? true : false,
          show: user && user.isAdmin ? true : false,
          router: "/admin/info-objects/astro-parts",
          themeProperty: false,
        },
        {
          label: "Logout",
          icon: LogoutSVG,
          router: "/logout",
          show: true,
          themeProperty: false,
        },
      ],
    },
    {
      label: "App Settings",
      hasSubItem: true,
      subItems: [
        {
          label: "Change Theme",
          disabled: false,
          show: true,
          icon: themeName === "lightTheme" ? AccountSVG : DarkAccountSVG,
          themeProperty: true,
        },
      ],
    },
  ];
  useEffect(() => {
    handleChartEdited(!tabState.chartEdited);
  }, [editChartDialogOpen]);

  useEffect(() => {
    if (user) setUserFormValue(user);
  }, [user]);

  useEffect(() => {
    const loadUserCharts = async () => {
      const _userCharts = await getFirebaseUserCharts(user);
      if (Array.isArray(_userCharts)) {
        handleFilteredUserChartChange(_userCharts);
        handleOriginalUserChartChange(_userCharts);
      }
    };

    loadUserCharts();
  }, [user, tabState.chartEdited, tabState.newChartDialogOpen]);
  // change filter

  const handleFilterCriteria = (event, sortType) => {
    if (!sortType) return;
    setSelectedFilter(sortType);
    const userChart = [...tabState.filteredUserCharts];
    if (sortType === "alpha") {
      handleFilteredUserChartChange(sortByAlphabetical(userChart));
    } else if (sortType === "newest") {
      handleFilteredUserChartChange(sortByNewest(userChart));
    }
  };
  // --- new chart --- //
  const handleClickNewChart = () => {
    // if (isMobile) handleSlideMenuOpen(false);
    handleNewChartDialog(true);
    handleSwitchNewChartAndEditProfile(true);
  };
  // --- new chart --- //
  const handleClickLogin = () => {
    history.push("/login");
  };

  // --- close edit chart dialog
  const handleEditChartDialog = () => {
    setEditChartDialogOpen(false);
  };

  const handleChangeEditChartFormValue = ({ target }) => {
    setEditChartFormValue((prev) => ({
      ...prev,
      [target.name]: target.value,
    }));
  };

  const handleCloseEditChartDialog = () => {
    setEditChartDialogOpen(false);
  };

  const handleSaveEditChartForm = async () => {
    if (!editChartFormValue.name.length) return;

    try {
      await editUserChart(user, editChartFormValue);
      handleCloseEditChartDialog();
      alert("Successfully updated the chart");
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickEditChartButton = (chart) => {
    setEditChartDialogOpen(true);
    setEditChartFormValue(chart);
  };
  // ------
  const handleDrawerClose = () => {
    if (activeMenuItem) {
      setActiveMenuItem(null);
    } else {
      if (isMobile) {
        handleSlideMenuOpen(false);
      } else {
        handleSlideDesktopMenuOpen(false);
      }
    }
  };

  const handleClickMenuItem = (item) => {
    if (item.hasSubItem) {
      setActiveMenuItem(item);
    }
  };

  const handleClickSubMenuItem = (subItem) => {
    if (subItem.label === "Admin") {
      if (user && user.isAdmin) {
        history.push(subItem.router);
      }
    } else {
      if (subItem.themeProperty) {
        handleChangeTheme();
      } else if (!subItem.disabled) {
        handleFilteredUserChartChange([]);
        handleOriginalUserChartChange([]);
        handleLogout();
      }
    }
  };

  const handleLogout = () => {
    logout();
  };

  const handleClickChart = (chart) => {
    handleSlideMenuOpen(false);
    handleActiveChartChange(chart);
    handleTabDeselect();
    handleFilteredUserChartChange(tabState.originalUserCharts);
    history.push("/user-chart");
  };

  const handleSearchInputChange = (e) => {
    const val = e.target.value;
    if (!val.length) {
      handleFilteredUserChartChange(tabState.originalUserCharts);
      return;
    }
    const _userCharts = [...tabState.filteredUserCharts];
    handleFilteredUserChartChange(filterByKeyword(_userCharts, e.target.value));
  };

  /**
   * Handle Profile Edit button click event
   */
  const handleClickProfileEdit = () => {
    handleSlideMenuOpen(false);
    handleNewChartDialog(true);
    handleSwitchNewChartAndEditProfile(false);
  };

  /**
   *
   */
  const [userFormValue, setUserFormValue] = useState({});

  const [newChartFormValue, setNewChartFormValue] = useState({
    dateTime: moment().format("YYYY-MM-DDTHH:mm:ss"), //new Date(),
    address: "",
    name: "",
  });
  const handleCloseNewChartDialog = () => {
    handleNewChartDialog(false);
  };

  const handleChangeNewChartFormValue = ({ target }) => {
    setNewChartFormValue({ ...newChartFormValue, [target.name]: target.value });
  };

  const handleChangeUserFormValue = ({ target }) => {
    setUserFormValue({ ...userFormValue, [target.name]: target.value });
  };

  const handleSaveNewChart = async () => {
    if (tabState.isNewChartDialog) {
      if (!newChartFormValue.name.length) return;
      try {
        await saveUserChart(user, { ...newChartFormValue });
        handleCloseNewChartDialog();
        setNewChartFormValue({
          name: "",
          address: "",
          dateTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
        });
        // handleDrawerClose();
        // handleSlideMenuOpen(false);
        // handleAlertOpen(true, "Successfully saved the chart.", "success");
      } catch (error) {
        console.log(error);
        // handleAlertOpen(true, "Error occurred in saving chart.", "error");
      }
    } else {
      if (!userFormValue.name.length) return;
      await updateUserProfile(userFormValue, user);
      handleCloseNewChartDialog();
      setEditChartFormValue({
        name: "",
        address: "",
        dateTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
        cid: "",
      });
      // handleDrawerClose();
      // handleSlideMenuOpen(false);
      // handleAlertOpen(true, "Successfully updated your profile.", "success");
    }
  };

  const handleChangeTheme = () => {
    if (themeName === "lightTheme") {
      setThemeName("darkTheme");
    } else {
      setThemeName("lightTheme");
    }    
  };

  const getThemeName = (property = "text-muted") => {
    return themeName === "lightTheme" ? property : `${property}-dark`;
  };
  
  return (
    <>
      <div className={classes.list} role="presentation">
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronRightIcon />
          </IconButton>
          {activeMenuItem && <Typography component={"p"}>{activeMenuItem.label}</Typography>}
        </div>
        <Divider
          classes={{
            root: classes.divider,
          }}
        />
        <List disablePadding>
          {user ? ( activeMenuItem ? ( (activeMenuItem.label === 'App Settings') ? (<AppSettings />) :
              (activeMenuItem.subItems.map((subItem, index) => (
                <Fragment key={index}>
                  {subItem.show && (
                    <ListItem
                      button
                      onClick={() => handleClickSubMenuItem(subItem)}
                    >
                      <ListItemIcon>
                        <img
                          src={subItem.icon}
                          alt={subItem.label}
                          className={`${classes.zoomIcon}`}
                        />
                      </ListItemIcon>
                      <ListItemText primary={subItem.label} />
                    </ListItem>
                  )}
                </Fragment>
              ))
            )) : (
              LoggedInMenuList.map((item, index) => (
                <ListItem
                  button
                  key={item.label}
                  onClick={() => handleClickMenuItem(item)}
                >
                  <ListItemIcon>
                    {index % 2 === 0 ? (
                      <img
                        src={item.icon}
                        alt={item.label}
                        className={`${classes.zoomIcon}`}
                      />
                    ) : (
                      <SettingsOutlined />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={item.label} />
                </ListItem>
              ))
            )
          ) : (
            <ListItem button onClick={handleClickLogin}>
              <ListItemIcon>
                <AccountCircle />
              </ListItemIcon>
              <ListItemText primary={`Login`} />
            </ListItem>
          )}

          <Divider
            classes={{
              root: classes.divider,
            }}
          />
          {!activeMenuItem && (
            <ListItem button onClick={handleClickNewChart}>
              <ListItemIcon>
                <AddCircle className={classes.newChartIcon} />
              </ListItemIcon>
              <ListItemText primary={`New Chart`} />
            </ListItem>
          )}
        </List>
        <Divider
          classes={{
            root: classes.divider,
          }}
        />
        {!activeMenuItem && (
          <>
            {user && (
              <>
                <div className={getThemeName("me-title")}>
                  <span>Me</span>
                </div>
                <List>
                  <ListItem
                    button
                    onClick={(event) => {
                      history.push("/me");
                    }}
                  >
                    <ListItemIcon>
                      <AccountCircle />
                    </ListItemIcon>
                    <ListItemText primary={user.name} />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        onClick={handleClickProfileEdit}
                      >
                        <Edit />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </>
            )}
            <div className={getThemeName("chartlist-title")}>
              <span>My Charts</span>
            </div>
            <div className={classes.search}>
              <div
                className={classes.searchIcon}
                // onClick={handleClickShowFilterModal}
              >
                <ToggleButtonGroup
                  value={selectedFilter}
                  exclusive
                  onChange={handleFilterCriteria}
                  aria-label="text alignment"
                >
                  <ToggleButton
                    value="alpha"
                    aria-label="left aligned"
                    classes={{
                      root: classes.toggleButton,
                    }}
                  >
                    <SortByAlpha
                      className={
                        selectedFilter === "alpha"
                          ? classes.filterIconSelected
                          : classes.filterIconDeselected
                      }
                    />
                  </ToggleButton>
                  <ToggleButton
                    value="newest"
                    aria-label="centered"
                    classes={{
                      root: classes.toggleButton,
                    }}
                  >
                    <Schedule
                      className={
                        selectedFilter === "newest"
                          ? classes.filterIconSelected
                          : classes.filterIconDeselected
                      }
                    />
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>

              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                wrap="nowrap"
                className={classes.inputWrapper}
              >
                <Grid item>
                  <Search />
                </Grid>
                <Grid item>
                  <InputBase
                    placeholder="Search…"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ "aria-label": "search" }}
                    onChange={handleSearchInputChange}
                  />
                </Grid>
              </Grid>
            </div>
            {/* <Divider
      classes={{
        root: classes.divider,
      }}
    /> */}
            <List>
              {tabState.filteredUserCharts.map((chart, index) => (
                <ListItem
                  button
                  key={index}
                  onClick={(event) => {
                    handleClickChart(chart);
                  }}
                >
                  <ListItemIcon>
                    <AccountCircle />
                  </ListItemIcon>
                  <ListItemText primary={chart.name} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={() => handleClickEditChartButton(chart)}
                    >
                      <Edit />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
            {!user && (
              <Link
                to="/register"
                className="signup-savechart-link"
                style={{
                  color: themeName === "lightTheme" ? "#000" : "#fff",
                  padding: "12px 16px",
                  textDecoration: "underline",
                }}
              >
                Sign up to save charts
              </Link>
            )}
          </>
        )}
      </div>
      {/** Edit Chart popup */}
      <Dialog
        open={editChartDialogOpen}
        onClose={handleEditChartDialog}
        aria-labelledby="chart-form-dialog"
        maxWidth={"sm"}
        fullWidth
        classes={{
          paper: isMobile ? classes.paperScrollPaper : "",
        }}
      >
        <DialogTitle id="edit-chart-form-dialog-title">
          {"Edit Chart"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="name"
            label=""
            placeholder="Chart name"
            type="text"
            fullWidth
            value={editChartFormValue.name}
            className={`mb-16 ${classes.root}`}
            onChange={handleChangeEditChartFormValue}
          />
          <GoogleMaps
            onAddressChange={handleChangeEditChartFormValue}
            address={editChartFormValue.address}
            className={`mb-16 ${classes.root}`}
            label=""
            onAutocompleteChange={() => {}}
          />
          <TextField
            type="datetime-local"
            size="small"
            name="dateTime"
            className={`mb-16 ${classes.root} input-datetime`}
            value={editChartFormValue.dateTime}
            onChange={({ target }) => {
              handleChangeEditChartFormValue({ target });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleCloseEditChartDialog}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSaveEditChartForm}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/** New Chart | Edit Profile */}
      <Dialog
        open={tabState.newChartDialogOpen}
        onClose={handleCloseNewChartDialog}
        aria-labelledby="form-dialog-title"
        maxWidth={"sm"}
        fullWidth
        classes={{
          paper: isMobile ? classes.paperScrollPaper : "",
        }}
      >
        <DialogTitle id="form-dialog-title">
          {tabState.isNewChartDialog ? "New Chart" : "Edit Your Profile"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="name"
            label=""
            placeholder="Chart name"
            type="text"
            fullWidth
            value={
              tabState.isNewChartDialog
                ? newChartFormValue.name
                : userFormValue.name
            }
            className={`mb-16 ${classes.root}`}
            onChange={
              tabState.isNewChartDialog
                ? handleChangeNewChartFormValue
                : handleChangeUserFormValue
            }
          />
          <GoogleMaps
            onAddressChange={
              tabState.isNewChartDialog
                ? handleChangeNewChartFormValue
                : handleChangeUserFormValue
            }
            address={
              tabState.isNewChartDialog
                ? newChartFormValue.address
                : userFormValue.birthLocation
                ? userFormValue.birthLocation.name
                : ""
            }
            className={`mb-16 ${classes.root}`}
            label=""
            onAutocompleteChange={() => {}}
          />
          <TextField
            type="datetime-local"
            size="small"
            name="dateTime"
            className={`mb-16 ${classes.root} input-datetime`}
            value={
              tabState.isNewChartDialog
                ? newChartFormValue.dateTime
                : moment(userFormValue.birthDay).format("YYYY-MM-DDTHH:mm:ss")
            }
            onChange={({ target }) => {
              if (tabState.isNewChartDialog) {
                handleChangeNewChartFormValue({
                  target: { name: target.name, value: target.value },
                });
              } else {
                handleChangeUserFormValue({
                  target: { name: "birthDay", value: target.value },
                });
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleCloseNewChartDialog}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSaveNewChart}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SlideMenu;
