import React from "react";
// import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PaypalSVG from "../assets/svg/PayPal.svg";
import PreviewGif from "../assets/svg/preview.gif";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import useApp from "hooks/useApp";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  root: {
    border: "1px solid #dcd9d9",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 2,
    background: "white",
  },
}));

const ProUser = () => {
  const classes = useStyles();
  const { /* tabState, */ handleProModalShow } = useApp();
  const handleProModalClose = () => {
    handleProModalShow(false);
  };

  return (
    <div className={classes.root}>
      <div className="pro-modal-title-wrapper">
        <div>
          <p className="pro-modal-title">PRO</p>
        </div>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleProModalClose}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div>
        <div>
          <img alt={"preview gif"} src={PreviewGif} style={{ width: "100%" }} />
        </div>
        <p className="pro-dialog-price-label-wrapper">
          <span className="pro-dialog-price-label">$4</span>/month
        </p>
        <p className="pro-dialog-description-label">Personal Transit Events</p>
        <p className="pro-dialog-description-label">Transit Notifications</p>
        <p className="pro-dialog-description-label">Personal Ritual Timing</p>
      </div>
      <div className="pro-dialog-subscribe-button-wrapper">
        <Button onClick={handleProModalClose} autoFocus>
          <img alt="subscribe" src={PaypalSVG} />
        </Button>
      </div>
    </div>
  );
};

export default ProUser;
