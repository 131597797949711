import React, { Fragment, useState, useContext } from "react";
import { useEffect } from "react";
import { aspectsArt, planetsArt, signs, signsArt } from "services/constants";
import { getLocalDateTime } from "services/dataService";
import Countdown from "react-countdown";
import CountdownRenderer from "components/CountdownRenderer";
import axios from "axios";
import { URL } from "../services/constants";
import ArtWork1 from "../assets/artwork_1.png";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import useApp from "hooks/useApp";
import { isAfter } from "date-fns";
import { ThemeContext } from "themes/ThemeProvider";
import clsx from "clsx";

import * as MaterialUI from "@material-ui/core";
import { updateFirebaseEvent } from "services/dataService";
import { AppContext } from "AppContext";

import * as Components from "./../components";
import Utils from "utils";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: "14px !important",
    "& div": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px",
      },
      fontSize: "14px",
    },
  },
  label: {
    "& span": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px",
      },
      fontSize: "14px",
    },
  },
  cardRoot: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  caption: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 20,
    lineHeight: 24,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    zIndex: 5,
  },
  dialogBackground: {
    background: theme.palette.background.black,
  },
}));

const TransitEvent = ({
  event: e,
  index,
  selectedEvent,
  handleSelectedEventChange,
}) => {
  // Utils.Log('[TransitEvent]');

  var valueEditing = null;

  const { objSettings } = useContext(AppContext);

  const [localDateTime, setLocalDateTime] = useState(null);
  const [selected, setSelected] = useState(false);
  const [chartImage, setChartImage] = useState(null);
  const [eventModalOpen, setEventModalOpen] = useState(false);
  const [showArtWorkImage, setShowArtWorkImage] = useState(true);
  const isMobile = useMediaQuery("(max-width:600px)");
  const classes = useStyles();
  const { themeName } = useContext(ThemeContext);
  const { tabState, handleEventModalShow } = useApp();
  
  const [ strEditingItem, setEditingItem ] = useState('');
  // var a = moment("2015-06-06T22:34:56");
  // var b = moment("2016-06-09T21:03:55");
  // var diff_s = a.diff(b, "seconds");
  // console.log(
  //   moment
  //     .utc(moment.duration(diff_s, "seconds").asMilliseconds())
  //     .format("DD hh:mm:ss")
  // );
  useEffect(() => {
    const utcDate = e.date;
    const _localDateTime = getLocalDateTime(
      new Date(
        Date.UTC(
          utcDate.year,
          utcDate.month - 1,
          utcDate.day,
          utcDate.hour,
          utcDate.minute,
          utcDate.second
        )
      ),
      e.offset
    );
    setLocalDateTime(_localDateTime);
  }, [e]);

  useEffect(() => {
    if (e) {
      if (
        selectedEvent &&
        e.d.toString().replace(".", "") ===
          selectedEvent.d.toString().replace(".", "")
      ) {
        setSelected(true);
      } else {
        setSelected(false);
      }
    }
  }, [e, selectedEvent]);

  useEffect(() => {
    // event changed on global transit or my transit view
    const displayEventChart = async () => {
      if (e) {
        const { date, offset } = e;
        const eventDate = new Date(
          date.year,
          date.month - 1,
          date.day,
          offset ? date.hour + offset : date.hour,
          date.minute,
          date.second
        );

        let dt = `${date.day}/${date.month}/${date.year}/${date.hour}/${date.minute}/${date.second}`;
        let chartUrl = "";

        chartUrl = `${URL}n=${dt}&svgstring=true`;
        chartUrl += getURLChart();

        axios.get(chartUrl).then((res) => {
          setChartImage(res.data);
        });
      } else {
      }
    };
    displayEventChart().catch(console.error);
  }, [e]);

  const handleOnClick = () => {
    setEventModalOpen(true);
    handleEventModalShow(true);
    if (!isMobile) handleSelectedEventChange(e);
  };

  const handleEventModalClose = () => {
    setEventModalOpen(false);
    handleEventModalShow(false);
  };

  const handleClickViewChart = () => {
    setShowArtWorkImage(!showArtWorkImage);
  };

  const getThemeName = (property = "text-muted") => {
    return themeName === "lightTheme" ? property : `${property}-dark`;
  };

  const getURLChart = () => {
    var strURLChart = '';
    var objPlanetIDs = [];
    if (objSettings.planetsSun)
      objPlanetIDs.push(0);
    if (objSettings.planetsMoon)
      objPlanetIDs.push(1);
    if (objSettings.planetsMercury)
      objPlanetIDs.push(2);
    if (objSettings.planetsVenus)
      objPlanetIDs.push(3);
    if (objSettings.planetsMars)
      objPlanetIDs.push(4);
    if (objSettings.planetsJupiter)
      objPlanetIDs.push(5);
    if (objSettings.planetsSaturn)
      objPlanetIDs.push(6);
    if (objSettings.planetsUranus)
      objPlanetIDs.push(7);
    if (objSettings.planetsNeptune)
      objPlanetIDs.push(8);
    if (objSettings.planetsPluto)
      objPlanetIDs.push(9);
    if (objSettings.nodesNorthNode)
      objPlanetIDs.push(10);
    if (objSettings.nodesSouthNode)
      objPlanetIDs.push(11);
    if (objSettings.nodesNorthNodeMedian)
      objPlanetIDs.push(30);
    if (objSettings.nodesSouthNodeMedian)
      objPlanetIDs.push(31);
    if (objSettings.pointsAscendant)
      objPlanetIDs.push('ac');
    if (objSettings.pointsMidheaven)
      objPlanetIDs.push('mc');
    if (objSettings.pointsDecendant)
      objPlanetIDs.push('dc');
    if (objSettings.pointsIlliumcolli)
      objPlanetIDs.push('ic');
    if (objPlanetIDs.length)
      strURLChart += '&planets=' + objPlanetIDs.join(',');
    strURLChart += '&houses=' + objSettings.strHouseSystem;    
    var strOrbs = [];
    if (objSettings.orbsConjunction)
      strOrbs.push(`conjunction/${objSettings.orbsConjunction}`);
    if (objSettings.orbsOpposition)
      strOrbs.push(`opposition/${objSettings.orbsOpposition}`);
    if (objSettings.orbsSquare)
      strOrbs.push(`square/${objSettings.orbsSquare}`);
    if (objSettings.orbsTrine)
      strOrbs.push(`trine/${objSettings.orbsTrine}`);
    if (objSettings.orbsSextile)
      strOrbs.push(`sextile/${objSettings.orbsSextile}`);
    if (strOrbs.length)
      strURLChart += '&chartorbs=' + strOrbs.join(',');
    strURLChart += '&showminutes=' + objSettings.isShowMinutes;
    strURLChart += '&showretrograde=' + objSettings.isShowRetrograde;
    strURLChart += '&svgstyle=' + (themeName === 'darkTheme' ? 'dark' : 'default');
    return strURLChart;    
  }

  const handleChangeValue = arg => {
    valueEditing = arg;
  }

  const handleClickButtonEdit = (strItem) => {
    setEditingItem(strItem);    
  }

  const handleClickButtonCancel = () => {
    valueEditing = null;
    setEditingItem('');
  }
  
  const handleClickButtonSave = () => {
    if (valueEditing === null) {
      setEditingItem('');
      return;
    }

    switch(strEditingItem) {
      case 'artwork':
        e.artwork = valueEditing;
        break;
      case 'description1':
        e.ritual = valueEditing;
        break;
      case 'description2':
        e.meaning = valueEditing;
        break;
      case 'description3':
        e.description3 = valueEditing;
        break;
      case 'yogadescription':
        e.yogaDescription = valueEditing;
        break;
      case 'yogaimage':
        e.yogaImg = valueEditing;
        break;
    }
    updateFirebaseEvent(e).then(() => {
      valueEditing = null;
      setEditingItem('');
    });
  }

  return (
    <Fragment>
      <Box
        className={
          selected
            ? clsx(
                getThemeName("transitCard"),
                `transitEvent max-w-450 mx-auto active`
              )
            : clsx(
                getThemeName("transitCard"),
                `transitEvent max-w-450 mx-auto`
              )
        }
        onClick={handleOnClick}
      >
        <div className="event-date-time">
          <h4 className={e.top ? getThemeName("count-down-label") : ""}>
            {/* <h4 className={isAfter(new Date(e.eventTime), new Date()) ? "count-down-label" : ""}> */}
            {/* <Countdown
              date={
                new Date(
                  `${localDateTime?.year}/${localDateTime?.month}/${localDateTime?.day}
                 ${localDateTime?.hour}:${localDateTime?.minute}:${localDateTime?.second}`
                )
              }
              renderer={CountdownRenderer}
            /> */}
            <Countdown
              date={new Date(e.eventTime)}
              renderer={CountdownRenderer}
            />
          </h4>
          <h4 className="p-0 m-0 mb-8">
            {e.date?.utc
              ? new Date(
                  `${e.date.utc.year}/${e.date.utc.month}/${e.date.utc.day}
                 ${e.date.utc.hour}:${e.date.utc.minute}:${e.date.utc.second}`
                ).toLocaleString("en-US", { hour12: false })
              : new Date(
                  `${localDateTime?.year}/${localDateTime?.month}/${localDateTime?.day}
                 ${localDateTime?.hour}:${localDateTime?.minute}:${localDateTime?.second}`
                ).toLocaleString("en-US", { hour12: false })}
            {}
          </h4>
        </div>
        {e.planet === undefined ? (
          <div className="planets-wrapper">
            <div className="planets-aspect">
              <div className="flex items-center mb-8">
                <i className={planetsArt[e.planet1] + " mr-12"}></i>
                <h4 className="p-0 m-0 mr-8">
                  {e.planet1} <span className={clsx(getThemeName())}>in</span>
                </h4>
                <i
                  className={clsx(
                    getThemeName(),
                    signsArt[signs[e.position1.sign]],
                    "mr-8"
                  )}
                ></i>
                <h4 className={clsx(getThemeName(), "p-0 m-0 mr-8")}>
                  {signs[e.position1.sign]}
                </h4>
              </div>
              <div className="flex items-center mb-4">
                <i className={aspectsArt[e.aspect] + " mr-12"}></i>
                <h4 className="p-0 m-0 mr-12">{e.aspect}</h4>

                <h4 className={clsx(getThemeName(), "m-0")}>
                  <span>{e.position1.degree}</span>
                  <sup className="mr-8 deg">&deg;</sup>
                </h4>
                <h4 className={clsx(getThemeName(), "m-0")}>
                  <span>{e.position1.min}</span>
                  <sup className="deg">
                    <span>&#x2033;</span>
                  </sup>
                </h4>
              </div>
              <div className="flex items-center mb-8">
                <i className={planetsArt[e.planet2] + " mr-12"}></i>
                <h4 className="p-0 m-0 mr-8">
                  {e.planet2} <span className={clsx(getThemeName())}>in</span>
                </h4>
                <i
                  className={clsx(
                    getThemeName(),
                    signsArt[signs[e.position2.sign]],
                    "mr-8"
                  )}
                ></i>
                <h4 className={clsx(getThemeName(), "p-0 m-0 mr-8")}>
                  {signs[e.position2.sign]}
                </h4>
              </div>
            </div>
            <div className="event-chart-wrapper">
              <div
                className="event-chart-image"
                dangerouslySetInnerHTML={{ __html: chartImage }}
              ></div>
            </div>
          </div>
        ) : (
          <div className="planets-wrapper">
            <div className="planets-aspect">
              <div className="flex items-center mb-8">
                <i className={planetsArt[e.planet] + " mr-12"}></i>
                <h4 className="p-0 m-0 mr-8">
                  {e.planet1} <span className={clsx(getThemeName())}>in</span>
                </h4>
                <i
                  className={clsx(
                    getThemeName(),
                    signsArt[signs[e.position.sign]],
                    "mr-8"
                  )}
                ></i>
                <h4 className={clsx(getThemeName(), "p-0 m-0 mr-8")}>
                  {signs[e.position.sign]}
                </h4>
              </div>
            </div>
            <div className="event-chart-wrapper">
              <div
                className="event-chart-image"
                dangerouslySetInnerHTML={{ __html: chartImage }}
              ></div>
            </div>
          </div>
        )}
        <div className="artwork-wrapper">
          {e.artwork ? (
            <img alt="artwork" src={e.artwork} style={{ width: "100%" }} />
          ) : (
            <img alt="art work" src={ArtWork1} style={{ width: "100%" }} />
          )}
        </div>
      </Box>
      {/** Event Dialog */}
      {isMobile && (
        <Dialog
          open={eventModalOpen}
          fullScreen
          onClose={handleEventModalClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{
            paper: classes.dialogBackground,
          }}
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{ textAlign: "center", color: "#6202EE" }}
          >
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleEventModalClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <div className="event-dialog-chart-header">
              <div
                className="event-dialog-chart-wrapper"
                style={{ width: showArtWorkImage ? "auto" : "100%" }}
              >
                <div
                  className="event-dialog-chart-image"
                  style={{ width: showArtWorkImage ? "auto" : "100%" }}
                  dangerouslySetInnerHTML={{ __html: chartImage }}
                  onClick={handleClickViewChart}
                />
                <div>
                  {showArtWorkImage && (
                    <Button
                      className="b-1 event-dialog-view-chart-button"
                      onClick={handleClickViewChart}
                    >
                      View Chart
                    </Button>
                  )}
                </div>
              </div>
              <div
                style={{
                  display: showArtWorkImage ? "block" : "none",
                  width: "50%",
                }}
              >
                {e.artwork ? (
                  <img
                    alt="artwork"
                    src={e.artwork}
                    style={{ width: "100%" }}
                  />
                ) : (
                  <img alt="art work" src={ArtWork1} style={{ width: "100%" }} />
                )}
                {strEditingItem === '' && (
                  <MaterialUI.Grid container justifyContent="flex-end">
                    <Button style={{ marginBottom: '20px' }} variant="contained" color="primary" onClick={() => handleClickButtonEdit('artwork')}>EDIT</Button>
                  </MaterialUI.Grid>
                )}
              </div>
            </div>
            {strEditingItem === 'artwork' && (
              <MaterialUI.Box>
                <MaterialUI.Box display="block" style={{padding: '5px'}}>
                  <MaterialUI.TextField variant="outlined" size="small" fullWidth onChange={event => handleChangeValue(event.target.value)} />
                </MaterialUI.Box>
                <MaterialUI.Box display="flex" justifyContent="flex-end">
                  <MaterialUI.Box style={{margin: '5px'}}>
                    <Button variant="contained" color="secondary" onClick={handleClickButtonCancel}>CANCEL</Button>
                  </MaterialUI.Box>
                  <MaterialUI.Box style={{margin: '5px'}}>
                    <Button variant="contained" color="primary" onClick={handleClickButtonSave}>SAVE</Button>
                  </MaterialUI.Box>
                </MaterialUI.Box>
              </MaterialUI.Box>
            )}
            <div>
              <div>
                <div className="event-date-time">
                  <h4 className={e.top ? getThemeName("count-down-label") : ""}>
                    <Countdown
                      date={
                        new Date(
                          `${localDateTime?.year}/${localDateTime?.month}/${localDateTime?.day}
               ${localDateTime?.hour}:${localDateTime?.minute}:${localDateTime?.second}`
                        )
                      }
                      renderer={CountdownRenderer}
                    />
                  </h4>
                  <h4 className="p-0 m-0 mb-8">
                    {e.date.utc
                      ? new Date(
                          `${e.date.utc.year}/${e.date.utc.month}/${e.date.utc.day}
               ${e.date.utc.hour}:${e.date.utc.minute}:${e.date.utc.second}`
                        ).toLocaleString("en-US", { hour12: false })
                      : new Date(
                          `${localDateTime?.year}/${localDateTime?.month}/${localDateTime?.day}
               ${localDateTime?.hour}:${localDateTime?.minute}:${localDateTime?.second}`
                        ).toLocaleString("en-US", { hour12: false })}
                    {}
                  </h4>
                </div>
              </div>
              <div className="planets-wrapper">
                <div className="planets-aspect">
                  <div className="flex items-center mb-8">
                    <i className={planetsArt[e.planet1] + " mr-12"}></i>
                    <h4 className="p-0 m-0 mr-8">
                      {e.planet1}{" "}
                      <span className={clsx(getThemeName())}>in</span>
                    </h4>
                    <i
                      className={clsx(
                        getThemeName(),
                        e.position1 ? signsArt[signs[e.position1.sign]] : '',
                        "mr-8"
                      )}
                    ></i>
                    <h4 className={clsx(getThemeName(), "p-0 m-0 mr-8")}>
                      {e.position1 && signs[e.position1.sign]}
                    </h4>
                  </div>
                  <div className="flex items-center mb-4">
                    <i className={aspectsArt[e.aspect] + " mr-12"}></i>
                    <h4 className="p-0 m-0 mr-12">{e.aspect}</h4>

                    <h4 className={clsx(getThemeName(), "m-0")}>
                      <span>{e.position1 && e.position1.degree}</span>
                      <sup className="mr-8 deg">&deg;</sup>
                    </h4>
                    <h4 className={clsx(getThemeName(), "m-0")}>
                      <span>{e.position1 && e.position1.min}</span>
                      <sup className="deg">
                        <span>&#x2033;</span>
                      </sup>
                    </h4>
                  </div>
                  <div className="flex items-center mb-8">
                    <i className={planetsArt[e.planet2] + " mr-12"}></i>
                    <h4 className="p-0 m-0 mr-8">
                      {e.planet2}{" "}
                      <span className={clsx(getThemeName())}>in</span>
                    </h4>
                    <i
                      className={clsx(
                        getThemeName(),
                        e.position2 ? signsArt[signs[e.position2.sign]] : '',
                        "mr-8"
                      )}
                    ></i>
                    <h4 className={clsx(getThemeName(), "p-0 m-0 mr-8")}>
                      {e.position2 && signs[e.position2.sign]}
                    </h4>
                  </div>
                </div>
              </div>
              {strEditingItem === 'description1' ? (
                <MaterialUI.Box>
                  <Components.Editor value={e.ritual} onChange={handleChangeValue} />
                  <MaterialUI.Box display="flex" justifyContent="flex-end">
                    <MaterialUI.Box style={{margin: '5px'}}>
                      <Button variant="contained" color="secondary" onClick={handleClickButtonCancel}>CANCEL</Button>
                    </MaterialUI.Box>
                    <MaterialUI.Box style={{margin: '5px'}}>
                      <Button variant="contained" color="primary" onClick={handleClickButtonSave}>SAVE</Button>
                    </MaterialUI.Box>
                  </MaterialUI.Box>
                </MaterialUI.Box>
              ) : (e.ritual && (
                <div className="event-dialog-description-one" dangerouslySetInnerHTML={{__html: e.ritual}}></div>
              ))}
          {strEditingItem === '' && (
            <MaterialUI.Box display="flex" justifyContent="flex-end">
              <Button style={{ marginBottom: '20px' }} variant="contained" color="primary" onClick={() => handleClickButtonEdit('description1')}>EDIT</Button>
            </MaterialUI.Box>
          )}
            </div>
            <div className="event-dialog-description-wrapper">
              <p className="event-dialog-description-title">Ritual</p>
          {strEditingItem === 'description2' ? (
            <MaterialUI.Box>
              <Components.Editor value={e.meaning} onChange={handleChangeValue} />
              <MaterialUI.Box display="flex" justifyContent="flex-end">
                <MaterialUI.Box style={{margin: '5px'}}>
                  <Button variant="contained" color="secondary" onClick={handleClickButtonCancel}>CANCEL</Button>
                </MaterialUI.Box>
                <MaterialUI.Box style={{margin: '5px'}}>
                  <Button variant="contained" color="primary" onClick={handleClickButtonSave}>SAVE</Button>
                </MaterialUI.Box>
              </MaterialUI.Box>
            </MaterialUI.Box>
          ) : (e.meaning && (
            <div className="event-dialog-description-two" dangerouslySetInnerHTML={{__html: e.meaning}}></div>
          ))}
          {strEditingItem === '' && (
            <MaterialUI.Box display="flex" justifyContent="flex-end">
              <Button style={{ marginBottom: '20px' }} variant="contained" color="primary" onClick={() => handleClickButtonEdit('description2')}>EDIT</Button>
            </MaterialUI.Box>
          )}
          {strEditingItem === 'description3' ? (
            <MaterialUI.Box>
              <Components.Editor value={e.description3} onChange={handleChangeValue} />
              <MaterialUI.Box display="flex" justifyContent="flex-end">
                <MaterialUI.Box style={{margin: '5px'}}>
                  <Button variant="contained" color="secondary" onClick={handleClickButtonCancel}>CANCEL</Button>
                </MaterialUI.Box>
                <MaterialUI.Box style={{margin: '5px'}}>
                  <Button variant="contained" color="primary" onClick={handleClickButtonSave}>SAVE</Button>
                </MaterialUI.Box>
              </MaterialUI.Box>
            </MaterialUI.Box>
          ) : (e.description3 && (
            <div className="event-dialog-description-two" dangerouslySetInnerHTML={{__html: e.description3}}></div>
          ))}
          {strEditingItem === '' && (
            <MaterialUI.Box display="flex" justifyContent="flex-end">
              <Button style={{ marginBottom: '20px' }} variant="contained" color="primary" onClick={() => handleClickButtonEdit('description3')}>EDIT</Button>
            </MaterialUI.Box>
          )}
            </div>
            <div>
              <p className="event-dialog-recommend-label">Recommended Mudra</p>
          {strEditingItem === 'yogadescription' ? (
            <MaterialUI.Box>
              <Components.Editor value={e.yogaDescription} onChange={handleChangeValue} />
              <MaterialUI.Box display="flex" justifyContent="flex-end">
                <MaterialUI.Box style={{margin: '5px'}}>
                  <Button variant="contained" color="secondary" onClick={handleClickButtonCancel}>CANCEL</Button>
                </MaterialUI.Box>
                <MaterialUI.Box style={{margin: '5px'}}>
                  <Button variant="contained" color="primary" onClick={handleClickButtonSave}>SAVE</Button>
                </MaterialUI.Box>
              </MaterialUI.Box>
            </MaterialUI.Box>
          ) : (e.yogaDescription && (
            <div className="event-dialog-description-two" dangerouslySetInnerHTML={{__html: e.yogaDescription}}></div>
          ))}
          {strEditingItem === '' && (
            <MaterialUI.Box display="flex" justifyContent="flex-end">
              <Button style={{ marginBottom: '20px' }} variant="contained" color="primary" onClick={() => handleClickButtonEdit('yogadescription')}>EDIT</Button>
            </MaterialUI.Box>
              )}
              {e.yogaImg ? (
                <img alt="artwork" src={e.yogaImg} style={{ width: "100%" }} />
              ) : (
                <img alt="art work" src={ArtWork1} style={{ width: "100%" }} />
              )}
              {strEditingItem === '' && (
            <MaterialUI.Box display="flex" justifyContent="flex-end">
              <Button style={{ marginBottom: '20px' }} variant="contained" color="primary" onClick={() => handleClickButtonEdit('yogaimage')}>EDIT</Button>
            </MaterialUI.Box>
          )}
            {strEditingItem === 'yogaimage' && (
              <MaterialUI.Box>
                <MaterialUI.Box display="block" style={{padding: '5px'}}>
                  <MaterialUI.TextField variant="outlined" size="small" fullWidth onChange={event => handleChangeValue(event.target.value)} />
                </MaterialUI.Box>
                <MaterialUI.Box display="flex" justifyContent="flex-end">
                  <MaterialUI.Box style={{margin: '5px'}}>
                    <Button variant="contained" color="secondary" onClick={handleClickButtonCancel}>CANCEL</Button>
                  </MaterialUI.Box>
                  <MaterialUI.Box style={{margin: '5px'}}>
                    <Button variant="contained" color="primary" onClick={handleClickButtonSave}>SAVE</Button>
                  </MaterialUI.Box>
                </MaterialUI.Box>
              </MaterialUI.Box>
            )}
            </div>
          </DialogContent>
          {/* <DialogActions>
        <Button onClick={handleEventModalClose} autoFocus>
          click
        </Button>
      </DialogActions> */}
        </Dialog>
      )}
    </Fragment>
  );
};

export default React.memo(TransitEvent);
